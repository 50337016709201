import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/feedbacks";
const FeedbackApi = {
  feedbacks(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
};

export default FeedbackApi;
