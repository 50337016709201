import { Navigate } from "react-router-dom";

//login
import ForgetPasswordPage from "../pages/Authentication/ForgetPassword";
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";

//old project
import Feed from "pages/Feed";
import Snap from "pages/Snap";
import Advertisement from "pages/Advertisement";
import User from "pages/User/User";
import UserDelete from "pages/User/Delete";
import UserLog from "pages/User/Logs";
import Group from "pages/Group";
import UserAdminList from "pages/User/Admin";
import Artist from "pages/Artists";
import Wallpaper from "pages/Wallpaper";
import AdminLog from "pages/User/AdminLog";
import PhotoNews from "pages/PhotoNews";
import DailyUserStatistics from "pages/Statistics/DailyUserStatistics";
import SummaryStatistics from "pages/Statistics/SummaryStatistics";
import HotStatistics from "pages/Statistics/HotStatistics";
import WallpaperStatistics from "pages/Statistics/WallpaperStatistics";
import PhotocardNewsStatistics from "pages/Statistics/PhotocardNewsStatistics";
import SnapStatistics from "pages/Statistics/SnapStatistics";
import LoginHistory from "pages/User/LoginHistory";
import UserFeedback from "pages/User/UserFeedback";
import GroupTDI from "pages/ArtistManagementTDI/Group";
import ArtistTDI from "pages/ArtistManagementTDI/Artists";
import PushNotification from "pages/PushNotification";
import PushStatistics from "pages/Statistics/PushStatistics";
import PushLog from "pages/PushNotificationMenu/PushLog";
import ManualPush from "pages/PushNotificationMenu/ManualPush";
import NotificationPermission from "pages/User/NotificationPermission";
import CountryStatistics from "pages/Statistics/CountryStatistics";

const ROUTES = {
  FEED: "/feed",
  WALLPAPER: "/wallpaper",
  SNAP: "/snap",
  ADVERTISEMENT: "/advertisement",
  PUSH_NOTIFICATION: "/push-notification",
  USER: "/user",
  USER_DELETE: "/user-delete",
  USER_LOG: "/user-logs",
  USER_NOTIFICATION_PERMISSION: "/notification-permission",
  USER_ADMIN: "/user-admin",
  ADMIN_LOG: "/admin-log",
  GROUP: "/group",
  ARTIST: "/artists",
  PHOTO_NEWS: "/photo-news",
  WALLPAPER_DOWNLOAD: "/wallpaper-download",
  GROUP_TDI: "/group-tdi",
  ARTIST_TDI: "/artists-tdi",

  USER_LIST: "/user/list",
  USER_ROLE: "/user/role",
  USER_PROFILE: "/user/profile",
  LOGIN_HISTORY: "/user/login_history",
  USER_FEEDBACK: "/user/user_feedback",

  DASHBOARD: "/dashboard",
  PROFILE: "/profile",
  PROFILE_SETTING: "/pages-profile-settings",

  STATISTICS: "/statistics",
  STATISTICS_DAILY_USER: "/statistics/user-statistics",
  STATISTICS_SUMMARY: "/statistics/summary",
  STATISTICS_HOT: "/statistics/hot",
  STATISTICS_WALLPAPER: "/statistics/wallpaper",
  STATISTICS_PHOTOCARD_NEWS: "/statistics/photocard-news",
  STATISTICS_SNAP: "/statistics/snap",
  STATISTICS_PUSH: "/statistics/push",
  STATISTICS_COUNTRY: "/statistics/country",

  PUSH_NOTIFICATION_PUSH_LOG: "/push-notification/push-log",
  PUSH_NOTIFICATION_MANUAL_PUSH: "/push-notification/manual-push",
  PUSH_NOTIFICATION_USER_NOTIFICATION_CONFIG:
    "/push-notification/user-notification-config",
};

const authProtectedRoutes = [
  { path: ROUTES.FEED, component: <Feed /> },
  { path: ROUTES.WALLPAPER, component: <Wallpaper /> },
  { path: ROUTES.SNAP, component: <Snap /> },
  { path: ROUTES.PHOTO_NEWS, component: <PhotoNews /> },
  { path: ROUTES.ADVERTISEMENT, component: <Advertisement /> },
  { path: ROUTES.PUSH_NOTIFICATION, component: <PushNotification /> },
  { path: ROUTES.USER, component: <User /> },
  { path: ROUTES.USER_DELETE, component: <UserDelete /> },
  { path: ROUTES.USER_LOG, component: <UserLog /> },
  {
    path: ROUTES.USER_NOTIFICATION_PERMISSION,
    component: <NotificationPermission />,
  },
  { path: ROUTES.LOGIN_HISTORY, component: <LoginHistory /> },
  { path: ROUTES.USER_FEEDBACK, component: <UserFeedback /> },
  { path: ROUTES.USER_ADMIN, component: <UserAdminList /> },
  { path: ROUTES.ADMIN_LOG, component: <AdminLog /> },
  { path: ROUTES.GROUP, component: <Group /> },
  { path: ROUTES.ARTIST, component: <Artist /> },
  { path: ROUTES.GROUP_TDI, component: <GroupTDI /> },
  { path: ROUTES.ARTIST_TDI, component: <ArtistTDI /> },

  { path: ROUTES.STATISTICS_DAILY_USER, component: <DailyUserStatistics /> },
  { path: ROUTES.STATISTICS_SUMMARY, component: <SummaryStatistics /> },
  { path: ROUTES.STATISTICS_HOT, component: <HotStatistics /> },
  { path: ROUTES.STATISTICS_WALLPAPER, component: <WallpaperStatistics /> },
  { path: ROUTES.STATISTICS_PHOTOCARD_NEWS, component: <PhotocardNewsStatistics />, },
  { path: ROUTES.STATISTICS_SNAP, component: <SnapStatistics /> },
  { path: ROUTES.STATISTICS_PUSH, component: <PushStatistics /> },
  { path: ROUTES.STATISTICS_COUNTRY, component: <CountryStatistics /> },


  { path: ROUTES.PUSH_NOTIFICATION_PUSH_LOG, component: <PushLog /> },
  { path: ROUTES.PUSH_NOTIFICATION_MANUAL_PUSH, component: <ManualPush /> },
  {
    path: ROUTES.PUSH_NOTIFICATION_USER_NOTIFICATION_CONFIG,
    component: <ManualPush />,
  },
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/feed" />,
  },
  { path: "*", component: <Navigate to="/feed" /> },
];

const publicRoutes = [
  // Authentication Page
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "/forgot-password", component: <ForgetPasswordPage /> },
  { path: "/register", component: <Register /> },
];

export { authProtectedRoutes, publicRoutes, ROUTES };
