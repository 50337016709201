import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/users/configuration";
const UserNotiPermissionApi = {
  userNotiPermissions(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
};

export default UserNotiPermissionApi;
