import { createAsyncThunk } from "@reduxjs/toolkit";

//Include Both Helper File with needed methods
import StatisticApi from "api/statisticApi";
import { formatQueryParams } from "helpers/format";

export const getDailyUserStatistics = createAsyncThunk("Daily User Statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.dailyUserStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getSummaryStatistics = createAsyncThunk("Summary Statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.summaryStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getHotStatistics = createAsyncThunk("Hot Statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.hotStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getWallpaperStatistics = createAsyncThunk("Wallpaper Statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.wallpaperStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getPhotocardNewsStatistics = createAsyncThunk("Photocard News Statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.photocardNewsStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getSnapStatistics = createAsyncThunk("Snap statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.snapStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getPushStatistics = createAsyncThunk("Push statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.pushStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getCountryStatistics = createAsyncThunk("Country statistics", async (params: any = {}) => {
  try {
    const response = await StatisticApi.countryStatistics(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});