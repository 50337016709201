import { createSlice } from "@reduxjs/toolkit";
import { getFeedbacks } from "./thunk";

export interface IState {
  feedbacks: any;
  isFeedbackLoading: boolean;
  isFeedbackSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  feedbacks: null,
  isFeedbackLoading: false,
  isFeedbackSuccess: false,

  error: {},
};

const FeedbackSlice = createSlice({
  name: "Feedback",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Feedbacks
    builder.addCase(getFeedbacks.pending, (state: IState, action: any) => {
      state.isFeedbackLoading = true;
    });
    builder.addCase(getFeedbacks.fulfilled, (state: IState, action: any) => {
      state.feedbacks = action.payload.data;
      state.isFeedbackSuccess = true;
      state.isFeedbackLoading = false;
    });
    builder.addCase(getFeedbacks.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isFeedbackSuccess = false;
      state.isFeedbackLoading = false;
    });
  },
});

export const FeedbackReducer = FeedbackSlice.reducer;
