import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import AdminApi from "api/adminApi";
import { formatQueryParams } from "helpers/format";

export const getUserAdmins = createAsyncThunk("Admins", async (params: any = {}) => {
  try {
    const response = await AdminApi.userAdmins(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getUserAdmin =  async(id: string | number ,  params: any = {}) => {
  try{
    const response = await AdminApi.getUserAdmin(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postUserAdmin = async (data: any = {}) => {
  try{
    const response = await AdminApi.createUserAdmin(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putUserAdmin = async (id: string | number , data: any = {}) => {
  try{
    const response = await AdminApi.updateUserAdmin(id, data);
    return response;
  }catch (error) {
    return error;
  }
};