import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Modal, ModalBody, ModalHeader, Progress, Spinner } from "reactstrap";
import * as Yup from "yup";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import AssetApi from "api/assetApi";
import SnippetApi, { uploadFiles } from "api/snippetApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownArtist from "components/Common/DropdownArtistNew";
import DropdownGroup from "components/Common/DropdownGroup";
import { getCategoryById } from "store/thunks";
import { mockAuthorId } from "utils";
import IconDeltete from "../../assets/images/icon-delete.png";
import TooltipCustom from "components/Common/TooltipCustom";
import { Option } from "api/types/_public";
import NO_IMAGE from '../../assets/images/no-image-icon.png'
import { TYPE_ARTIST_OPTION } from "helpers/constans";

export default function CreateEditSnippet({
  setIsOpen,
  dispatch,
  query,
  item,
  setItemToEdit,
  loadTableData,
  listArtist,
  listGroup,
  setLinkImage
}: any) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUploadVideo, setIsLoadingUploadVideo] = useState<boolean>(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState<boolean>(false);
  const isEdit = item;

  const props: UploadProps = {
    name: "files",
    accept: 'video/mp4,video/x-m4v,video/*',
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const propsThumbnail: UploadProps = {
    name: "files",
    accept: '.png,.jpg,.webp,.jpeg',
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const TYPE_ARTIST_OPTION_LANG = TYPE_ARTIST_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const resultArtistOptions = listArtist?.map((a: any) => ({
    value: String(a?._id),
    label: (
      <div className="d-flex align-items-center gap-2">
        <div style={{ height: '25px', width: '25px', overflow: 'hidden', borderRadius: '15px', objectFit: 'cover', backgroundColor: '#eaeaea', zIndex: 9 }} >
          <img src={a?.avatar || NO_IMAGE} alt={a?.name} height={25} width={25} className="me-2" style={{ overflow: 'hidden', borderRadius: '50%', objectFit: 'cover', backgroundColor: '#eaeaea' }} />
        </div>
        {`${a?.name?.ko} - ${a?.name?.en} (${TYPE_ARTIST_OPTION_LANG?.find((item: any) => String(item?.value) === String(a?.type))?.label || ''} - ${t(a?.gender)})`}
      </div>
    ),
    data: a,
  })) || [] as Option[];

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      // let thumbnail = values?.thumbnail_url;
      // if(!thumbnail && values?.files?.[0]?.url) {
      //   const thumbnailUrl: any  = await AssetApi.generateThumbnail({
      //     videoUrl: values?.files?.[0]?.url
      //   });
      //   if(thumbnailUrl?.success && thumbnailUrl?.data?.thumbnailUrl) {
      //     thumbnail = thumbnailUrl?.data?.thumbnailUrl;
      //   }
      // }
      var data: any = {
        title: values?.title ?? "",
        // short_description: values?.short_description ?? "",
        content: values?.content ?? "",
        artistIds: !!values?.artistId ? [values?.artistId] : [],
        categoryIds: !!values?.categoryId?.value ? [values?.categoryId?.value] : [],
        thumbnail_url: values?.thumbnail_url,
        media_url: values?.files?.[0]?.url,
        media_type: values?.files?.[0]?.media_type || "image",
        status: "activated",
        authorId: mockAuthorId,
        postedAt: new Date(),
      };

      if (values?.short_description) {
        data = { ...data, short_description: values?.short_description }
      }

      const response: any = isEdit
        ? await SnippetApi?.updateSnippet(item?._id, data)
        : await SnippetApi?.createSnippet(data);

      if (response?.data) {
        //  dispatch(onGetSnippets(query));
        loadTableData();
        setIsLoading((_prev) => false);
        setIsOpen(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading(false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const validationSchema = Yup.object({
    title: Yup.string().required(`${t("This field is required")}`),
    content: Yup.string().required(`${t("This field is required")}`),
    // thumbnail_url: Yup.string().required(`${t("Thumbnail is required")}`),
    // files: Yup.string().required(`${t("Video is required")}`),
    files: Yup.array().min(1, `${t("This field is required")}`).required(`${t("This field is required")}`),
    short_description: Yup.string().nullable(),
  });

  const formik = useFormik({
    initialValues: {
      title: "",
      content: "",
      short_description: "",
      artistId: null,
      categoryId: null,
      thumbnail_url: "",
      media_url: "",
      media_type: "",
      files: []
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (item) {
      formik.setFieldValue("title", item?.title);
      formik.setFieldValue("content", item?.content);
      formik.setFieldValue("short_description", item?.short_description);
      formik.setFieldValue("thumbnail_url", item?.thumbnail_url);
      const formatedFile = [
        {
          uid: "-1",
          name: "image.png",
          status: "done",
          url: item?.media_url,
          thumbUrl: item?.media_url,
          ...item,
        },
      ];
      formik.setFieldValue("files", formatedFile);
      formik.setFieldValue("artistId", item?.artists?.[0]?._id);

      setTimeout(() => {
        formik.setFieldValue("categoryId", { value: `${item?.categoryIds?.[0]}`, label: '' });
      }, 300);
    }
  }, [item]);

  const onCloseClick = () => {
    setIsOpen(false);
    setItemToEdit(false);
  };

  const handleOnBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    formik.setFieldTouched(e.target.name)
  }

  const getCategory = async (id: string) => {
    if (!id) return;

    const res: any = await getCategoryById(id);
    if (res?.success && res?.data) {
      formik.setFieldValue("categoryId", { value: `${res?.data?._id}`, label: '' });
    }

    return;
  }
  
  const handleChangeArtist = (e: any) => {
    formik.setFieldValue("artistId", e?.value);
    if(item) {
      formik.setFieldValue("categoryId", { value: `${item?.categoryIds?.[0]}`, label: '' });
    }else {
      formik.setFieldValue("categoryId", null);
    }

    if (e?.value && listArtist) {
      const artist = listArtist.find((i: any) => i?._id == e?.value);
      if (artist?.categoryIds?.length > 0) {
        getCategory(artist?.categoryIds?.[0]);
      }
    }
  }

  return (
    <Modal isOpen={true} centered={true} scrollable={false} size="lg">
      <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
        {isEdit ? t("Update Snap") : t("Create Snap")}
      </ModalHeader>
      <ModalBody className="py-3 px-4">
        <div className="mb-4">
          <Progress value={100 - (100 / 3) * Number(Object.keys(formik.errors).length)} color="primary" className="animated-progress progress-sm" />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row g-3">
            <Col xxl={12}>
              <label className="form-label">
                {t("Title")}
                <span className="text-danger"> *</span>
              </label>
              <input
                className="form-control"
                id="title"
                name="title"
                value={formik?.values?.title}
                placeholder={`${t("Title")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue("title", event?.target?.value || "")
                }
                onBlur={handleOnBlur}
              />
              {formik.touched.title && formik.errors.title ? (
                <div className="text-danger mt-2">{formik.errors.title}</div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Short Description")}
              </label>
              <input
                className="form-control"
                id="short_description"
                name="short_description"
                value={formik?.values?.short_description}
                placeholder={`${t("Short Description")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "short_description",
                    event?.target?.value || ""
                  )
                }
                onBlur={handleOnBlur}
              />
              {formik.touched.short_description &&
                formik.errors.short_description ? (
                <div className="text-danger mt-2">
                  {formik.errors.short_description}
                </div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Content")} <span className="text-danger"> *</span>
              </label>
              <textarea
                className="form-control"
                id="content"
                name="content"
                value={formik?.values?.content}
                placeholder={`${t("Content")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue("content", event?.target?.value || "")
                }
                onBlur={() => formik.setFieldTouched("content")}
                style={{ height: 150 }}
              />
              {formik.touched.content && formik.errors.content ? (
                <div className="text-danger mt-2">{formik.errors.content}</div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Artist")}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <DropdownArtist
                name="artist"
                dataList={resultArtistOptions || []}
                placeholder={`${t("Select Artist")}...`}
                className="dropdown-status-rounded"
                classNamePrefix="name-prefix"
                initialValue={formik?.values?.artistId || null}
                onChangeSelect={(e: any) => handleChangeArtist(e)}
                isHasOptionAll={false}
                isClearable={true}
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Group")}
              </label>
              <DropdownGroup
                name="categoryId"
                dataList={listGroup || []}
                placeholder={`${t("Select Group")}...`}
                className="dropdown-status-rounded"
                classNamePrefix="name-prefix"
                initialValue={formik?.values?.categoryId || null}
                onChangeSelect={(e: any) => formik.setFieldValue("categoryId", e)}
                isHasOptionAll={false}
                isClearable={true}
              />
            </Col>

            <Col xxl={4} className="mt-3">
              <label className="form-label">
                {t("Video")}
                <span className="text-danger"> *</span>
              </label>

              {formik.getFieldProps("files")?.value && (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                  }}
                >
                  {formik.getFieldProps("files")?.value?.map((i: any, index: number) => {
                    if (i?.media_type == "video")
                      return (
                        <div
                          key={i?._id}
                          style={{
                            position: "relative",
                          }}
                        >
                          <video
                            width="160"
                            height="120"
                            controls
                            key={i?.url}
                            onClick={() => {
                              setLinkImage({ media_url: i?.url, media_type: "video" });
                            }}
                          >
                            <source src={i?.url} type="video/mp4" />
                            Your browser does not support the video tag.
                          </video>
                          <span
                            onClick={() => formik.setFieldValue("files", [])}
                            style={{ color: "red", position: "absolute", top: "-14px", right: "-8px", cursor: "pointer" }}>
                            <img src={IconDeltete} alt="delete" width={18} height={18} />
                          </span>
                        </div>
                      );
                    else
                      return i?.url && (
                        <div
                          style={{
                            position: "relative",
                          }}>
                          <img
                            src={i?.url}
                            style={{ maxHeight: 120, maxWidth: 160 }}
                            alt="thumbnail"
                            onClick={() => {
                              setLinkImage({ thumbnail_url: i?.url });
                            }}
                          />
                          <span
                            onClick={() => {
                              const newFilesList = [...formik.getFieldProps("files")?.value];
                              newFilesList.splice(index, 1);
                              formik.setFieldValue("files", newFilesList)
                            }}
                            style={{ color: "red", position: "absolute", top: "-14px", right: "-8px", cursor: "pointer" }}>
                            <img src={IconDeltete} alt="delete" width={18} height={18} />
                          </span>
                        </div>
                      );
                  })}
                </div>
              )}

              <div>
                {(isLoadingUploadVideo) && (
                  <Spinner size="sm" className="me-2" style={{ color: "#4da3da" }}></Spinner>
                )
                }
              </div>
              <div>
                <Upload
                  {...props}
                  multiple={false}
                  beforeUpload={() => false}
                  //   listType="picture-card"
                  // fileList={(formik.getFieldProps("files")?.value as any) || []}
                  fileList={[]}
                  onChange={async (info: any) => {
                    setIsLoadingUploadVideo(true)

                    try {
                      const cloneInfo = info?.fileList?.length
                        ? [...info?.fileList]
                        : [];

                      const files = info.fileList;
                      const data = new FormData();
                      for (var i = 0; i < files?.length; i++) {
                        data.append("files", files[i]?.originFileObj);
                      }
                      const res = (await uploadFiles(data)) as any;
                      if (res?.success) {
                        setIsLoadingUploadVideo(false)
                        const fortmatedData = res?.data?.map(
                          (i: any, index: number) => {
                            return {
                              media_type: cloneInfo[index]?.type.includes("video")
                                ? "video"
                                : "image",
                              url: i?.url,
                              preview: i?.url,
                              elements: [],
                              caption: "Bonus Image",
                              mimetype: cloneInfo[index]?.type.includes("video")
                                ? "video/*"
                                : "image/*",
                              width: 1080,
                              height: 1080,
                              ratio: 1.1,
                              seq: 0,
                              ...i,
                            };
                          }
                        );
                        formik.setFieldValue("files", fortmatedData);

                        if (fortmatedData?.[0]?.url) {
                          setIsLoadingUploadImage(true);
                          const thumbnailUrl: any = await AssetApi.generateThumbnail({
                            videoUrl: fortmatedData?.[0]?.url
                          });

                          if (thumbnailUrl?.success && thumbnailUrl?.data?.thumbnailUrl) {
                            setIsLoadingUploadImage(false);
                            formik.setFieldValue("thumbnail_url", thumbnailUrl?.data?.thumbnailUrl);
                          } else {
                            setIsLoadingUploadImage(false)
                          }
                        }

                      } else {
                        setIsLoadingUploadVideo(false)
                      }
                    } catch (error: any) {
                      setIsLoadingUploadVideo(false)
                      setIsLoadingUploadImage(false)
                    }

                  }}
                  onRemove={() => {
                    //return onGalleryFileRemove();
                  }}
                >
                  <Button icon={<UploadOutlined />}>
                    {" "}
                    {t("Click to Upload")}
                  </Button>{" "}
                </Upload>
                {formik?.touched?.files && formik?.errors?.files ? (
                  <div className="text-danger mt-2">
                    {formik?.errors?.files}
                  </div>
                ) : null}
              </div>
            </Col>

            <Col lg={6} className="mt-3">
              <div className="mb-3">
                <label className="form-label d-flex align-items-center">
                  {t("Thumbnail")}
                  {
                      <TooltipCustom
                        title={t('The thumbnail is automatically generated when the video is uploaded.')}
                        className="d-inline-block vertical-align-middle ms-1 thumbnail-mt"
                        style={{ transform: 'translateY(2px)' }}
                      >
                        <i className="ri-question-line align-bottom text-secondary" ></i>
                      </TooltipCustom>
                  }
                </label>

                <div>
                  {formik.getFieldProps("thumbnail_url")?.value && (
                    <div
                      style={{
                        display: "flex",
                        gap: 10,
                        marginBottom: 10,
                        marginTop: 10,
                      }}
                    >
                      <div
                        style={{ position: "relative" }}
                      >
                        <img
                          alt="thumbnail_url"
                          src={formik.getFieldProps("thumbnail_url")?.value}
                          style={{ maxHeight: 120, maxWidth: 160 }}
                          onClick={() => {
                            setLinkImage({ thumbnail_url: formik.getFieldProps("thumbnail_url")?.value });
                          }}
                        />

                        <span
                          onClick={() => formik.setFieldValue("thumbnail_url", "")}
                          style={{ color: "red", position: "absolute", top: "-14px", right: "-8px", cursor: "pointer" }}>
                          <img src={IconDeltete} alt="delete" width={18} height={18} />
                        </span>
                      </div>
                    </div>
                  )}

                  <div>
                    {(isLoadingUploadImage) && (
                      <Spinner size="sm" className="me-2" style={{ color: "#4da3da" }}></Spinner>
                    )
                    }
                  </div>

                  <Upload
                    {...propsThumbnail}
                    multiple={false}
                    beforeUpload={() => false}
                    //   listType="picture-card"
                    // fileList={(formik.getFieldProps("files")?.value as any) || []}
                    fileList={[]}
                    onChange={async (info: any) => {
                      setIsLoadingUploadImage(true)

                      try {
                        const files = info.fileList;
                        const data = new FormData();
                        for (var i = 0; i < files?.length; i++) {
                          data.append("files", files[i]?.originFileObj);
                        }

                        const res = (await uploadFiles(data)) as any;
                        if (res?.success) {
                          setIsLoadingUploadImage(false)

                          const fortmatedData = res?.data?.map(
                            (i: any, index: number) => {
                              return {
                                type: "image",
                                url: i?.url,
                                preview: i?.url,
                                elements: [],
                                caption: "Image",
                                mimetype: "image/*",
                                width: 1080,
                                height: 1080,
                                ratio: 1.1,
                                seq: 0,
                                ...i,
                              };
                            }
                          );

                          formik.setFieldValue(
                            "thumbnail_url",
                            fortmatedData?.[0]?.url
                          );
                        } else {
                          setIsLoadingUploadImage(false)
                        }
                      } catch (error: any) {
                        setIsLoadingUploadImage(false)
                      }

                    }}
                    onRemove={() => {
                      //return onGalleryFileRemove();
                    }}
                  >
                    <Button icon={<UploadOutlined />}>
                      {t("Click to Upload")}
                    </Button>
                  </Upload>
                </div>
                {formik?.touched?.thumbnail_url && formik?.errors?.thumbnail_url ? (
                  <div className="text-danger mt-2">
                    {formik?.errors?.thumbnail_url}
                  </div>
                ) : null}
              </div>
            </Col>

            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-primary fs-14"
                  color="success"
                  type="submit"
                  style={{ width: '120px' }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) :
                    <>{isEdit ? t("Button Update") : t("Button Create")}</>
                  }
                </button>
                <button
                  className="btn btn-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={onCloseClick}
                  disabled={isLoading}
                  style={{ width: '100px' }}
                >
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
