import FeatherIcon from "feather-icons-react";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import HashtagText from './HashtagText';

interface ModalPreviewProps {
    type?: 'VIDEO' | 'IMAGE';
    isOpen: boolean;
    url: string;
    urls?: string[];
    content?: {
        title?: string,
        content?: string
    };
    onClose?: () => void;
}

const ModalPreview: React.FC<ModalPreviewProps> = ({ type = 'VIDEO', isOpen, content = null, url, onClose }) => {

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, []);

    const divRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLVideoElement | HTMLImageElement | any>(null);

    const [isShowMore, setIsShowMore] = useState<boolean>(true);
    const [position, setPosition] = useState({ left: 0, width: 0 });

    useEffect(() => {
        if (isOpen && divRef.current) {
            divRef.current.focus();
        }
    }, [isOpen, isShowMore, position]);

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            handleClose();
        }
    };

    const handleShowMore = () => {
        setIsShowMore((prev) => !prev);
    };

    const updatePosition = () => {
        if (elementRef.current && divRef.current) {
            const parent = divRef.current;
            const rect = elementRef.current;
            setPosition((_prev) => ({
                left: (parent?.clientWidth - rect?.clientWidth) / 2,
                width: rect?.clientWidth || 0
            }));
            setIsShowMore((prev) => true);
        }
    };

    const handleElementLoad = useCallback(() => {
        updatePosition();
    }, []);

    return (
        <div className="modal-preview">
            <ModalVideo
                channel="custom"
                isOpen={isOpen}
                onClose={handleClose}
                url={url}
                allowFullScreen={false}
                animationSpeed={300}
                classNames={{
                    modalVideoEffect: 'modal-video-effect',
                    modalVideo: 'modal-video',
                    modalVideoClose: 'modal-video-close',
                    modalVideoBody: 'modal-video-body',
                    modalVideoInner: 'modal-video-inner modal-video-inner-img',
                    modalVideoIframeWrap: 'modal-video-movie-wrap m-auto text-center overflow-hidden',
                    modalVideoCloseBtn: 'modal-video-close-btn  custom-video-close-btn',
                }}
                ratio="4:3"
                {...({
                    children: <div
                        ref={divRef}
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        style={{
                            outline: 'none',
                            maxWidth: '820px',
                        }}
                    >
                        {
                            type === 'VIDEO' ? <video
                                controls
                                autoPlay
                                style={{
                                    objectFit: "cover",
                                    width: "auto",
                                    height: "100%",
                                    margin: 'auto',
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    // maxWidth: '820px'
                                }}
                                ref={elementRef}
                                onLoadedData={handleElementLoad}
                                tabIndex={-1}
                            >
                                <source src={url} type="video/mp4" />
                            </video>
                                : <img
                                    className="object-fit"
                                    style={{
                                        objectFit: "cover",
                                        width: "auto",
                                        height: "100%",
                                        margin: 'auto',
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                    src={url}
                                    alt="preview"
                                    ref={elementRef}
                                    onLoad={handleElementLoad}
                                    tabIndex={-1}
                                />
                        }
                        {(position.width && !!content) && <div
                            style={{
                                position: 'absolute',
                                zIndex: '3',
                                left: position.left > 0 ? position.left : 0,
                                bottom: 0,
                                width: position.width,
                                maxWidth: '100%',
                                padding: '2px 10px',
                                backgroundColor: 'rgba(51, 51, 51, 0.6)',
                                color: '#fff',
                                backdropFilter: 'blur(2px)',
                            }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleShowMore();
                            }}
                            tabIndex={-1}
                        >
                            <div>
                                {isShowMore ? <FeatherIcon icon="chevrons-down" className="icon-dual" /> : <FeatherIcon icon="chevrons-up" className="icon-dual" />}
                            </div>
                            <div className={`hidden-scrollbar-os collapsible-show-more ${isShowMore ? 'open' : ''}`}>
                                {!!content?.title && <div className="text-start mb-2 mt-1" style={{ fontWeight: 600, fontSize: '1rem' }}>
                                    {content?.title || ''}
                                </div>}
                                <HashtagText text={content?.content || ''} />
                            </div>
                        </div>}
                    </div>
                })}
            />
        </div>
    );
};

export default React.memo(ModalPreview);
