import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Container, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import Masonry from "react-masonry-component";
import { useTranslation } from 'react-i18next';
import categoryApi from 'api/categoryApi';
import { PhotoProvider, PhotoView } from 'react-photo-view';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';


import 'react-photo-view/dist/react-photo-view.css';

// import Lightbox from "yet-another-react-lightbox";
// import "yet-another-react-lightbox/styles.css";

const Gallery = ({
  type = 'PHOTO',
  artistId = '',
  categoryId = '',
}) => {

  const { t, i18n } = useTranslation();
  const [displayCategory, setCategory] = useState<string>("All");
  const [index, setIndex] = useState<any>(-1);

  const filterGallery = ({ category }: any) => {
    return (
      (displayCategory === category || displayCategory === "All")
    );
  };

  const [previewVideo, setPreviewVideo] = useState<any | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [listGallery, setListGallery] = useState([]);

  const LIST_TYPE = (type === 'PHOTO') ? [{label: 'Feed', value: 'Feed'}, {label: 'Wallpaper', value: 'Wallpaper'}, {label: 'Photo News', value: 'PhotoNews'}] : [{label: 'Feed', value: 'Feed'}, {label: 'Snap', value: 'Snap'},]

  const [query, setQuery]: any = useState({
    page: 1,
    // limit: 50,
    type: LIST_TYPE[0]?.value,
    'condition[categoryIds]': categoryId
  });

  const changeType = (type_change:string) => {
    setQuery((_prev:any) => ({..._prev, type: type_change}));
  }


  const handleCallAllOption = async () => {
    try {
      setIsLoading((_prev) => true);
      const res: any =  type === 'PHOTO' ? await categoryApi.relatedPhotos(query) : await categoryApi.relatedVideos(query);
      setListGallery((_prev: any) => (res?.data?.items || []));
      setIsLoading((_prev) => false);
    } catch (error: any) {
      setIsLoading((_prev) => false);
      return error;
    }
  };

  useEffect(() => {
    if(query){
      handleCallAllOption();
    }
  }, [JSON.stringify(query), type, categoryId]);

  return (
    <React.Fragment>
          <Row>
            <Col lg={12}>
              <div className="" style={{minHeight: '90dvh'}}>
                <CardBody>
                <PhotoProvider>
                  <Row>
                    <Col lg={12}>
                      <div className="text-center">
                        <ul className="list-inline categories-filter animation-nav" id="filter">
                          {/* <li className="list-inline-item">
                            <Link to="#" onClick={() => setCategory("All")} className={displayCategory === "All" ? "categories active" : "categories"} data-filter="*">All</Link>
                          </li> */}
                          {LIST_TYPE?.map((item) => (
                            <li className="list-inline-item" key={item?.value}><Link to="#" onClick={() => changeType(item?.value)} className={String(query?.type) === String(item?.value) ? "categories active" : "categories"} data-filter=".project">{t(item?.label)}</Link></li>
                          ))}
                          
                          {/* <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Designing")} className={displayCategory === "Designing" ? "categories active" : "categories"} data-filter=".designing">Designing</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Photography")} className={displayCategory === "Photography" ? "categories active" : "categories"} data-filter=".photography">Photography</Link></li>
                          <li className="list-inline-item"><Link to="#" onClick={() => setCategory("Development")} className={displayCategory === "Development" ? "categories active" : "categories"} data-filter=".development">Development</Link></li>
                         */}
                        </ul>
                      </div>
                      {isLoading && <div className="text-center my-2">
                        <Link to="#" className="text-success"><i className="mdi mdi-loading mdi-spin fs-20 align-middle me-2"></i> {t('Loading')} </Link>
                      </div>}

                      <Masonry className="row gallery-wrapper">
                        {!isLoading && <>
                          {
                        (listGallery?.length > 0) ? listGallery?.map(({ media_url, url, title, auther, likes, comments, files, _id}, key) => (
                          files ? (
                            <>
                            {(files as any || [])?.map((file:any) => (
                                <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={file?._id}>
                            <Card className="gallery-box">
                              <div className="gallery-container">
                                <Link className="image-popup" to="#" onClick={(e) => e.preventDefault() }>
                                  {type === 'PHOTO' ? 
                                  <PhotoView key={file?._id} src={file?.preview}>
                                    <img className="gallery-img img-fluid mx-auto" loading="lazy" style={{minHeight: '150px', backgroundColor: '#fff'}} src={file?.preview} alt="" />
                                  </PhotoView>
                                  : 
                                  <video
                                  width="auto"
                                  height="auto"
                                  style={{maxWidth: '300px'}}
                                  controls
                                  key={file?.url}
                                  id="lesson-video"
                                  autoPlay={false}
                                  muted
                                  onClick={(e) => { e.preventDefault(); setPreviewVideo((_prev:any) => ({ id: file?._id, url: file?.url}));} }
                                >
                                  <source src={file?.url} type="video/mp4" />
                                  Your browser does not support the video tag.
                                </video>
                                  }
                                </Link>
                              </div>
                            </Card>
                          </Col>)
                              )
                            }
                            </>)
                           : (
                            <Col xxl={3} xl={4} sm={6} className="element-item project designing development" key={_id}>
                            <Card className="gallery-box">
                              <div className="gallery-container">
                                <Link className="image-popup" to="#"  onClick={(e) => e.preventDefault()}>
                                {type === 'PHOTO' ?  <PhotoView key={_id} src={url}>
                                  <img className="gallery-img img-fluid mx-auto" loading="lazy" style={{minHeight: '150px', backgroundColor: '#fff'}} src={url} alt="" /> 
                                  </PhotoView>: 
                                <video
                                width="auto"
                                height="auto"
                                style={{maxWidth: '300px'}}
                                controls
                                key={media_url}
                                id="lesson-video"
                                autoPlay={false}
                                muted
                                onClick={(e) => { e.preventDefault(); setPreviewVideo((_prev:any) => ({ id: _id, url: media_url}));} }
                              >
                                <source src={media_url} type="video/mp4" />
                                Your browser does not support the video tag.
                              </video>}
                                </Link>
                              </div>
                            </Card>
                          </Col>
                          )
                        ))  : <div className="text-center"> {t('No data')}</div>}
                        </>}
                      </Masonry>
                    
                    </Col>
                  </Row>
                  </PhotoProvider>
                </CardBody>
                
              </div>
            </Col>
          </Row>

          <ModalVideo 
        channel='custom' // Hoặc 'vimeo', 'custom'
        isOpen={!!previewVideo} 
        // videoId={previewVideo?.url} 
        onClose={() => setPreviewVideo(null)} 
        // autoplay
        // Đối với video trực tiếp, sử dụng channel='custom' và truyền url
        url={previewVideo?.url}
        allowFullScreen={true}
      />

{/* LightBox */}
{/* <Lightbox
        index={index}
        slides={slideGallery}
        open={index >= 0}
        close={() => setIndex(-1)}
      /> */}

    </React.Fragment>
  );
};

export default Gallery;