import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/advertisements";
const advertisementApi = {
  advertisements(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
  advertisement(id:string | number , params: any = {}): Promise<any> {
    const url = `${path}/${id}`;
    return api.get(url, params);
  },
  createAdvertisement(params: any) {
    return api.post(path, params);
  },

  updateAdvertisement(id: any, params: any) {
    return api.put(`${path}/${id}`, params);
  },

  deleteAdvertisement(id: any) {
    return api.delete(`${path}/${id}`);
  },
};

export default advertisementApi;
