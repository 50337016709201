export function truncate(str: string, n: number) {
  return str?.length > n ? str.slice(0, n - 1) + "..." : str;
}

export const mockAuthorId = "66b9c79a2c16413ab212cf7c";

export function capitalizeFirstLetter(string: string) {
  if (!string) {
    return "";
  }

  if (string == "firebase") {
    return "Apple Firebase";
  }

  return string.charAt(0).toUpperCase() + string.slice(1);
}

export function truncateWithExpand(str: string, n: number) {
  let res = str;
  let isMoreText = false;

  if(str?.length > n) {
    res = str.slice(0, n - 1) + "...";
    isMoreText = true;
  }

  return {
    data: res,
    isMoreText: isMoreText
  };
}