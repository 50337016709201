import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { AxiosResponse } from "axios";
import { IUser } from "./types/_user";
const api = new APIClient();

const path = "/notifications";
const pathLog = "/notification-logs";

const PushNotificationApi = {
  getPushLogs(params: any): Promise<any> {
    const url = `${pathLog}`;
    return api.get(url, params);
  },

  getManualPush(params: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/deleted-users`;
    return api.get(url, params)
  },

  createNotifications(params: any) {
    return api.post(path, params);
  },
};

export default PushNotificationApi;
