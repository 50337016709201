import { useFormik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Modal, ModalBody, ModalHeader, Progress, Spinner } from "reactstrap";
import * as Yup from "yup";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import PostApi, { uploadFiles } from "api/postApi";
import {
    CONFIG_OPTION_TOAST_ERROR,
    CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownArtist from "components/Common/DropdownArtist";
import { COLOR_POSITION_ADVERTISEMENT, COLOR_STATUS_ADVERTISEMENT, POSITION_ADVERTISEMENT_OPTION, POSITION_DESCRIPTION_ADVERTISEMENT_OPTION, STATUS_ADVERTISEMENT_OPTION, TYPE_POST } from "helpers/constans";
import { mockAuthorId } from "utils";
import DropdownStatus from "components/Common/DropdownStatus";
import advertisementApi from "api/advertisementApi";
import { formatNumberWithCommas } from "helpers/format";

export default function AdvertisementView({
    setIsOpen,
    dispatch,
    query,
    id,
    setItemToEdit,
    loadTableData,
    allArtist
}: any) {
    const { t, i18n } = useTranslation();
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const isEdit = id;

    const POSITION_ADVERTISEMENT_OPTION_LANG = POSITION_DESCRIPTION_ADVERTISEMENT_OPTION?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    })) || [];


    const STATUS_ADVERTISEMENT_LANG = STATUS_ADVERTISEMENT_OPTION?.map((item: any) => ({
        value: item?.value,
        label: t(item?.label),
    })) || [];


    const handleSubmit = async (values: any) => {
        try {
            setIsLoading((_prev) => true);
            const data = {
                adsId_android: values?.adsId_android || '',
                adsId_ios: values?.adsId_ios || '',
                show: Number(values?.show || 0),
                status: Boolean(values?.status?.value || STATUS_ADVERTISEMENT_LANG[0]?.value),
                position: values?.position?.value || POSITION_ADVERTISEMENT_OPTION_LANG[0]?.value,
            };

            const response: any = isEdit
                ? await advertisementApi?.updateAdvertisement(id, data)
                : await advertisementApi?.createAdvertisement(data);
            if (response?.data) {
                loadTableData();
                setIsLoading((_prev) => false);
                setIsOpen(false);
                toast(
                    `${t("The process has been completed.")}`,
                    CONFIG_OPTION_TOAST_NORMAL
                );
            } else {
                setIsLoading(false);
                toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
            }
        } catch (error: any) {
            setIsLoading(false);
            toast(`Please re-check your data`);
            return error;
        }
    };

    const validationSchema = Yup.object({
        adsId_android: Yup.string().required(`${t("This field is required")}`),
        adsId_ios: Yup.string().required(`${t("This field is required")}`),
        show: Yup.string().required(`${t("This field is required")}`),
        status: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
        position: Yup.object().shape({
            label: Yup.string(),
            value: Yup.string(),
        }).nullable(),
    });

    const formik = useFormik({
        initialValues: {
            adsId_android: "",
            adsId_ios: "",
            description: {
                ko: "",
                en: ""
            },
            show: "0",
            status: STATUS_ADVERTISEMENT_LANG[0],
            position_description: POSITION_ADVERTISEMENT_OPTION_LANG[0],
        },
        validationSchema,
        onSubmit: handleSubmit,
    });

    const handleSetValueForm = (valueDefault: any) => {
        const vAdsId_android = valueDefault?.adsId_android || "";
        const vAdsId_ios = valueDefault?.adsId_ios || "";
        const vDescription = valueDefault?.description || {
            ko: "",
            en: ""
        };
        const vPosition =
            POSITION_ADVERTISEMENT_OPTION_LANG?.filter(
                (item: any) => String(valueDefault?.position_description) == String(item?.value)
            )[0] || POSITION_ADVERTISEMENT_OPTION_LANG[0];
        const vStatus =
            STATUS_ADVERTISEMENT_LANG?.filter(
                (item: any) => String(valueDefault?.status) == String(item?.value)
            )[0] || STATUS_ADVERTISEMENT_LANG[0];
        const vShow = valueDefault?.show || '0';

        setTimeout(() => {
            formik.setFieldValue("adsId_android", vAdsId_android);
            formik.setFieldValue("adsId_ios", vAdsId_ios);
            formik.setFieldValue("description", vDescription);
            formik.setFieldValue("show", vShow);
            formik.setFieldValue("position", vPosition);
            formik.setFieldValue("status", vStatus);
        }, 300);
    };

    const handleCallAllOption = async (idItem: string) => {
        try {
            if (!idItem) {
                return;
            }
            const [resDetail]: any = await Promise.all([
                idItem ? advertisementApi.advertisement(idItem) : {},
            ]);
            if (resDetail?.data) {
                handleSetValueForm(resDetail?.data);
            }
        } catch (error: any) {
            return error;
        }
    };

    useEffect(() => {
        handleCallAllOption(id);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const onCloseClick = () => {
        setIsOpen(false);
        setItemToEdit(false);
    };

    return (
        <Modal isOpen={true} centered={true} scrollable={false} size="lg">
            <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
                {isEdit ? t("Advertisement") : ''}
            </ModalHeader>
            <ModalBody className="py-3 px-4">
                <div className="row g-3">
                    <Col sm={12} md={4} xl={4} xxl={4} className='mt-3 mt-md-2'>
                        <label className="form-label">
                            {t("Status")}
                        </label>
                        <DropdownStatus
                            name="status"
                            dataList={STATUS_ADVERTISEMENT_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={formik?.values?.status || STATUS_ADVERTISEMENT_LANG[0]}
                            onChangeSelect={(e: any) => formik.setFieldValue("status", e)}
                            colors={COLOR_STATUS_ADVERTISEMENT}
                            disabled={isDisabled}
                        />
                    </Col>
                    <Col sm={12} md={4} xl={4} xxl={4} className='mt-3 mt-md-2'>
                        <label className="form-label">
                            {t("Position")}
                        </label>
                        <DropdownStatus
                            name="position"
                            dataList={POSITION_ADVERTISEMENT_OPTION_LANG || []}
                            placeholder={`${t("Position")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={formik?.values?.position_description || POSITION_ADVERTISEMENT_OPTION_LANG[0]}
                            onChangeSelect={(e: any) => formik.setFieldValue("position", e)}
                            isHasOptionAll={false}
                            colors={COLOR_POSITION_ADVERTISEMENT}
                            disabled={isDisabled}
                        />
                    </Col>

                    <Col sm={12} md={4} xl={4} xxl={4} className='mt-3 mt-md-2'>
                        <label className="form-label">
                            {t("Number Show")}
                        </label>
                        <input
                            className="form-control"
                            id="show"
                            name="show"
                            value={formatNumberWithCommas(formik?.values?.show)}
                            placeholder={`${t("Number Show")}...`}
                            onChange={(event: any) => {
                                let input = event.target.value;
                                let onlyNumbers = String(input).replace(/\D/g, '');
                                formik.setFieldValue("show", onlyNumbers)
                            }}
                            disabled={isDisabled}
                        />
                    </Col>

                    <Col xxl={12}>
                        <label className="form-label">
                            {t("Android Ad")}
                        </label>
                        <textarea
                            className="form-control"
                            id="adsId_android"
                            name="adsId_android"
                            value={formik?.values?.adsId_android}
                            placeholder={`${t("Android Ad")}...`}
                            onChange={(event: any) =>
                                formik.setFieldValue("adsId_android", event?.target?.value || "")
                            }
                            style={{ height: 80 }}
                            disabled={isDisabled}
                        />
                    </Col>

                    <Col xxl={12}>
                        <label className="form-label">
                            {t("IOS Ad")}
                        </label>
                        <textarea
                            className="form-control"
                            id="adsId_ios"
                            name="adsId_ios"
                            value={formik?.values?.adsId_ios}
                            placeholder={`${t("IOS Ad")}...`}
                            onChange={(event: any) =>
                                formik.setFieldValue(
                                    "adsId_ios",
                                    event?.target?.value || ""
                                )
                            }
                            style={{ height: 80 }}
                            disabled={isDisabled}
                        />
                    </Col>

                    <Col xxl={12}>
                        <label className="form-label">
                            {t("Description")}
                        </label>
                        <textarea
                            className="form-control"
                            id="description"
                            name="description"
                            value={i18n?.language == "ko" ? formik?.values?.description?.ko : formik?.values?.description?.en}
                            placeholder={`${t("IOS Ad")}...`}
                            onChange={(event: any) =>
                                formik.setFieldValue(
                                    "description",
                                    event?.target?.value || ""
                                )
                            }
                            style={{ height: 120 }}
                            disabled={isDisabled}
                        />
                    </Col>
                </div>
            </ModalBody>
        </Modal>
    );
}
