import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import CountUp from "react-countup";
import { getSessions } from "store/session/thunk";
// import { truncate } from "utils";
import SessionApi from "api/sessionApi";
// import { Switch } from "antd";
import DropdownOption from "components/Common/DropdownOption";
import {
  COLOR_STATUS_USER,
  ENUM_STATUS_USER,
  LIST_OPTION_GENDER,
  LIST_OPTION_OS,
  LIST_OPTION_PLATFORM,
  STATUS_USER_OPTION,
} from "helpers/constans";
import DropdownStatus from "components/Common/DropdownStatus";
import avatar1 from "../../../assets/images/users/ic_defaul_avatar2.png";
import DropdownCountries from "components/Common/DropdownCountries";
import countryApi from "api/countryApi";
import { set } from "lodash";
import { getPlatFormImage } from "helpers/constans";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import DropdownPlatform from "components/Common/DropdownPlatform";
import CollapseFilter from "components/Common/CollapseFilter";
import { formatDateStringToKorea } from "helpers/format";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const SessionList = () => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const { state } = useLocation();

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    // order_by: withDefault(StringParam, `DESC`),
    // date_version: withDefault(StringParam, ''),
    email: withDefault(StringParam, state?.email),
    nickname: withDefault(StringParam, ""),
    "condition[role]": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[config][country]": withDefault(StringParam, ""),
    // platform
    "condition[providers][type]": withDefault(StringParam, state?.platform),
    "condition[ipAddress]": withDefault(StringParam, ""),
    platform: withDefault(StringParam, ""),
    flatform: withDefault(StringParam, state?.platform),
    os: withDefault(StringParam, ""),
    type: withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const LIST_OPTION_OS_LANG =
    LIST_OPTION_OS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const LIST_OPTION_PLATFORM_LANG =
    LIST_OPTION_PLATFORM?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [nicknameSearch, setNicknameSearch] = useState<string>(
    query?.nickname || ""
  );
  const [emailSearch, setEmailSearch] = useState<string>(query?.email || "");

  const [oSSearch, setOSSearch] = useState<Option | null>(
    LIST_OPTION_OS_LANG?.filter(
      (item) => String(item?.value) === String(query?.role)
    )[0]
  );

  const [platFormSearch, setPlatFormSearch] = useState<Option | null>(
    LIST_OPTION_PLATFORM_LANG?.filter(
      (item) => String(item?.value) === String(state?.platform)
    )[0]
  );

  const [ipAddressSearch, setIPAddressSearch] = useState<string>(
    query?.ipAddress || ""
  );

  const selectLayoutState = (state: any) => state.Session;

  const SessionProperties = createSelector(selectLayoutState, (state) => ({
    sessions: state.sessions,
    isSessionLoading: state.isSessionLoading,
    isSessionSuccess: state.isSessionSuccess,

    error: state.error,
  }));

  // Inside your component
  const { sessions, isSessionLoading } = useSelector(SessionProperties);

  function loadTableData() {
    dispatch(getSessions(query));
  }

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      nickname: nicknameSearch || "",
      email: emailSearch || "",
      // platform
      platform: platFormSearch?.value || "",
      flatform: platFormSearch?.value || "",
      os: oSSearch?.value || "",
      "condition[ipAddress]": ipAddressSearch || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  useEffect(() => {}, [state]);

  const resetData = () => {
    const queryNew = {
      nickname: "",
      email: "",
      // platform
      platform: "",
      os: "",
      "condition[ipAddress]": "",
      startDate: "",
      endDate: "",
      sort_by: "",
      time_request: +new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setNicknameSearch((_prev) => "");
    setEmailSearch((_prev) => "");
    setOSSearch((_prev) => null);
    setIPAddressSearch((_prev) => "");
    setPlatFormSearch((_prev) => null);
    setDateSearch(["", ""]);
  };

  function msToTime(ms: number) {
    let seconds = Number((ms / 1000).toFixed(1));
    let minutes = Number((ms / (1000 * 60)).toFixed(1));
    let hours = Number((ms / (1000 * 60 * 60)).toFixed(1));
    let days = (ms / (1000 * 60 * 60 * 24)).toFixed(1);
    if (seconds < 60) return seconds + " " + t("Seconds");
    else if (minutes < 60) return minutes + " " + t("Minutes");
    else if (hours < 24) return hours + " " + t("Hours");
    else return Number(days) + " " + t("Days");
  }

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        thClass: "whitespace-nowrap",
        Cell: ({ ...props }) => {
          if (!Number(sessions?.total)) return <></>;
          return (
            <div>
              {Number(sessions?.total) -
                ((query?.page - 1) * Number(query?.limit) + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Avatar"),
        accessor: "avatar",
        thClass: "text-center whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const user = cell?.row?.original?.user;
          return (
            <>
              <div className="w-100 text-center " style={{ minWidth: "60px" }}>
                <div
                  style={{ width: "45px" }}
                  className="m-auto cursor-pointer"
                >
                  <img
                    src={user?.avatar ?? avatar1}
                    className="rounded-circle avatar-sm"
                    alt="thumbnail"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar1;
                    }}
                  />
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Nickname"),
        accessor: "nickname",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const user = cell?.row?.original?.user;

          return (
            <>
              <div className="cursor-pointer">{user?.nickname}</div>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const user = cell?.row?.original?.user;
          return (
            <>
              <div className="cursor-pointer">{user?.email}</div>
            </>
          );
        },
      },

      {
        Header: t("Platform"),
        accessor: "providers_platform",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          let items = cell?.row?.original?.user?.providers || [];

          if (items.length > 1) {
            items = items.filter((item: any) => item?.type !== "default");
          }

          return (
            items?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {items?.map((item: any, index: number) => {
                  const type = item?.type;
                  let imgPlatform = getPlatFormImage(type);
                  return (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 align-items-center whitespace-nowrap"
                    >
                      <div style={{ width: "25px" }}>
                        {imgPlatform && (
                          <img
                            src={getPlatFormImage(type)}
                            width={25}
                            height={25}
                            alt="platform"
                          />
                        )}
                      </div>
                      <div className="cursor-pointer">
                        {t(
                          capitalizeFirstLetter(
                            type == "default" ? "Email" : type
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          );
        },
      },

      {
        Header: t("Last Login Time"),
        accessor: "lastLoginTime",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const lastLoginTime = cell?.row?.original?.lastActiveAt;
          const date = moment(lastLoginTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ width: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },

      {
        Header: t("Session End Time"),
        accessor: "session_end_time",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const lastLoginTime = cell?.row?.original?.expiresAt;
          const date = moment(lastLoginTime)
            .utc()
            .format("YYYY-MM-DD HH:mm:ss");
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ width: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },

      {
        Header: t("Duration"),
        accessor: "duration",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          return <div>{msToTime(cell?.value)}</div>;
        },
      },
      {
        Header: t("Device Model"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const model = cell?.row?.original?.deviceInfo?.deviceModel;
          return (
            model && (
              <div className="cursor-pointer" style={{ width: 80 }}>
                {t(`${model}`)}
              </div>
            )
          );
        },
      },
      {
        Header: t("OS"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const deviceOS = cell?.row?.original?.deviceInfo?.deviceOs;
          return (
            deviceOS && (
              <div className="cursor-pointer" style={{ width: 80 }}>
                {t(`${deviceOS}`)}
              </div>
            )
          );
        },
      },
      {
        Header: t("App Version"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const appVersion = cell?.row?.original?.appVersion || "";
          const appBuild = cell?.row?.original?.appBuild || "";

          return appVersion + (appBuild && ` (${appBuild})`);
        },
      },
      {
        Header: t("IP"),
        accessor: "providers_ip",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const ipAddress = cell?.row?.original?.ipAddress;
          return ipAddress && <div className="cursor-pointer">{ipAddress}</div>;
        },
      },

      {
        Header: t("Location"),
        accessor: "location",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const location = cell?.row?.original?.config?.payload?.location;
          if (!location) return <div>-</div>;

          const { latitude, longitude } = location;
          return (
            <div className="cursor-pointer">
              <a
                href={`https://www.google.com/maps/search/${latitude},${longitude}/@${latitude},${longitude},17z?entry=ttu`}
                target="_blank"
              >
                Location
              </a>
            </div>
          );
        },
      },

      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ width: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, sessions?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("Session")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Login History")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={sessions?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-0 mt-md-2">
                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Nickname")}
                          isShow={!!nicknameSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Nickname")}...`}
                            value={nicknameSearch}
                            onChange={(e) => setNicknameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Email")} isShow={!!emailSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Email")}...`}
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("IP Address")}
                          isShow={!!ipAddressSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("IP Address")}...`}
                            value={ipAddressSearch}
                            onChange={(e) => setIPAddressSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2 date-picker-wrapper-custom"
                      >
                        <LabelWrapper
                          label={t("Date")}
                          isShow={!!startDate || !!endDate}
                        >
                          <DatePickerCustom
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("OS")} isShow={true}>
                          <DropdownOption
                            name="os"
                            dataList={LIST_OPTION_OS_LANG || []}
                            placeholder={`${t("OS")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={oSSearch || null}
                            onChangeSelect={(e: any) => setOSSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("Platform")} isShow={true}>
                          <DropdownPlatform
                            name="platform"
                            placeholder={`${t("Platform")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={platFormSearch || null}
                            onChangeSelect={(e: any) => setPlatFormSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={6}
                        xl={6}
                        xxl={6}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-sm-4 mt-md-3"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={sessions?.items?.length ? sessions?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={sessions?.total}
                      customPageCount={Math.ceil(
                        Number(sessions?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isSessionLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default SessionList;
