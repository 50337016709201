import { useFormik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";

import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import PostApi, { uploadFiles } from "api/postApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { COLOR_STATUS_FEED, WALLPAPER_TYPE } from "helpers/constans";
import Select from "react-select";
import { mockAuthorId } from "utils";
import IconDeltete from "../../assets/images/icon-delete.png";
import NO_IMAGE from "../../assets/images/no-image-icon.png";
import { Option } from "api/types/_public";
import DropdownArtistNew from "components/Common/DropdownArtistNew";
import DropdownGroupNew from "components/Common/DropdownGroupNew";
import DropdownAsyncArtist from "components/Common/DropdownAsyncArtist";
import DropdownAsyncGroup from "components/Common/DropdownAsyncGroup";
import artistApi from "api/artistApi";
import categoryApi from "api/categoryApi";

export default function CreateEditWallpaper({
  setIsOpen,
  item,
  setItemToEdit,
  loadTableData,
  STATUS_FEED_OPTION_LANG,
  listArtist,
  listGroup,
  setLinkImage,
  formatOptionGroup,
  formatOptionArtist,
}: any) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] =
    useState<boolean>(false);
  const [listCheckedArtist, setListCheckedArtist] = useState<any>(null);
  const [listCheckedGroup, setListCheckedGroup] = useState<any>(null);

  const isEdit = item;

  const LIST_OPTION_WALLPAPER_TYPE_LANG =
    WALLPAPER_TYPE?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const props: UploadProps = {
    name: "files",
    accept: ".png,.jpg,.webp,.jpeg",
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        type: "card",
        artistIds: !!values?.artistId ? [values?.artistId] : [],
        categoryIds: !!values?.categoryId ? [values?.categoryId] : [],
        status: values?.status?.value || "activated",
        tagIds: [],
        files: values?.files,
        authorId: mockAuthorId,
        wallpaper_type: values?.type?.value,
      };

      const response: any = isEdit
        ? await PostApi?.updatePost(item?._id, data)
        : await PostApi?.createPost(data);
      if (response?.data) {
        //  dispatch(onGetPosts(query));
        loadTableData();
        setIsLoading((_prev) => false);
        setIsOpen(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading(false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const validationSchema = Yup.object({
    files: Yup.array()
      .min(1, `${t("This field is required")}`)
      .required(`${t("This field is required")}`),
  });

  const formik = useFormik({
    initialValues: {
      artistId: null,
      categoryId: null,
      files: [],
      status: STATUS_FEED_OPTION_LANG[0],
      type: LIST_OPTION_WALLPAPER_TYPE_LANG[1],
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    const vStatus =
      STATUS_FEED_OPTION_LANG?.filter(
        (i: any) => String(i?.value) == String(item?.status)
      )[0] || STATUS_FEED_OPTION_LANG[1];

    formik.setFieldValue("status", vStatus);

    const vType =
      LIST_OPTION_WALLPAPER_TYPE_LANG?.filter(
        (i: any) => String(i?.value) == String(item?.wallpaper_type)
      )[0] || LIST_OPTION_WALLPAPER_TYPE_LANG[1];

    formik.setFieldValue("type", vType);

    if (item) {
      const formatedFile = item?.files?.map((i: any) => {
        return {
          name: "image.png",
          url: i?.url,
          thumbUrl: i?.url,
          ...i,
        };
      });
      formik.setFieldValue("files", formatedFile);
      formik.setFieldValue("artistId", item?.artists?.[0]?._id);
      formik.setFieldValue("categoryId", item?.categories?.[0]?._id);

      if (formatOptionGroup) {
        setListCheckedGroup(formatOptionGroup(item?.categories?.[0]) || null);
      }

      if (formatOptionArtist) {
        setListCheckedArtist(formatOptionArtist(item?.artists?.[0]) || null);
      }
    }
  }, [item]);

  const onCloseClick = () => {
    setIsOpen(false);
    setItemToEdit(false);
  };

  const handleChangeArtist = async (e: any) => {
    setListCheckedArtist(e);
    formik.setFieldValue("artistId", e?.value);

    if (item) {
      formik.setFieldValue("categoryId", item?.categories?.[0]?._id);
    } else {
      formik.setFieldValue("categoryId", null);
    }

    if (e?.value) {
      const resArtist: any = await artistApi.getArtist(e?.value);
      const groupId = resArtist?.data?.categoryIds;

      if (groupId.length > 0) {
        const resGroup = await categoryApi.category(groupId?.[0]);
        // console.log(resGroup)
        if (resGroup?.data) {
          setListCheckedGroup(formatOptionGroup(resGroup?.data));
        }

        formik.setFieldValue("categoryId", groupId?.[0]);
      }
    }
  };

  return (
    <Modal isOpen={true} centered={true} scrollable={false} size="lg">
      <ModalHeader toggle={isLoading ? () => { } : onCloseClick}>
        {isEdit ? t("Update Wallpaper") : t("Create Wallpaper")}
      </ModalHeader>
      <ModalBody className="py-3 px-4">
        <div className="mb-4">
          <Progress
            value={100 - (100 / 2) * Number(Object.keys(formik.errors).length)}
            color="primary"
            className="animated-progress progress-sm"
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row g-3">
            <Col xxl={12}>
              <label className="form-label">{t("Artist")}</label>
              <DropdownAsyncArtist
                name="artist"
                onChangeSelect={handleChangeArtist}
                isMulti={false}
                placeholder={`${t("Select Artist")}...`}
                isShowIconCheck={false}
                listCheckedArtist={listCheckedArtist}
                hideSelectedOptions={true}
                isHasOptionAll={false}
              />
              {formik.touched.artistId && formik.errors.artistId ? (
                <div className="text-danger mt-2">{formik.errors.artistId}</div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">{t("Group")}</label>
              <DropdownAsyncGroup
                name="categoryId"
                onChangeSelect={(e: any) => {
                  setListCheckedGroup(e);
                  formik.setFieldValue("categoryId", e?.value);
                }}
                isMulti={false}
                placeholder={`${t("Select Group")}...`}
                isShowIconCheck={false}
                listCheckedGroup={listCheckedGroup}
                hideSelectedOptions={true}
                isHasOptionAll={false}
              />
            </Col>

            <Col lg={12}>
              <label className="form-label">
                {t("Status")}
                <span className="text-danger"> *</span>
              </label>

              <Select
                name="status"
                className="mb-0 dropdown-status-rounded"
                classNamePrefix="name-prefix"
                placeholder={t("Status")}
                value={formik?.values?.status || STATUS_FEED_OPTION_LANG[1]}
                onChange={(event: any) => formik.setFieldValue("status", event)}
                options={STATUS_FEED_OPTION_LANG}
                formatOptionLabel={(option: any) => (
                  <div className="d-flex align-items-center">
                    <span
                      className={`bg-${option?.value !== ""
                        ? COLOR_STATUS_FEED?.[option?.value]
                        : "secondary"
                        }`}
                      style={{
                        width: "15px",
                        height: "15px",
                        borderRadius: "3px",
                      }}
                    ></span>
                    <div className="ms-2">{option?.label}</div>
                  </div>
                )}
              ></Select>
              {formik?.touched?.status && formik?.errors?.status ? (
                <div className="text-danger mt-2"></div>
              ) : null}
            </Col>

            <Col lg={12}>
              <label className="form-label">
                {t("Type")}
                <span className="text-danger"> *</span>
              </label>

              <Select
                name="type"
                className="mb-0 dropdown-status-rounded"
                classNamePrefix="name-prefix"
                placeholder={t("Type")}
                value={
                  formik?.values?.type || LIST_OPTION_WALLPAPER_TYPE_LANG[1]
                }
                onChange={(event: any) => formik.setFieldValue("type", event)}
                options={LIST_OPTION_WALLPAPER_TYPE_LANG}
                formatOptionLabel={(option: any) => (
                  <div className="d-flex align-items-center">
                    <div className="ms-2">{option?.label}</div>
                  </div>
                )}
              ></Select>
              {formik?.touched?.status && formik?.errors?.status ? (
                <div className="text-danger mt-2"></div>
              ) : null}
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Photo")}
                <span className="text-danger"> *</span>
              </label>

              {formik.getFieldProps("files")?.value && (
                <div
                  style={{
                    display: "flex",
                    gap: 10,
                    marginBottom: 20,
                    marginTop: 10,
                    flexWrap: 'wrap'
                  }}
                >
                  {formik
                    .getFieldProps("files")
                    ?.value?.map((i: any, index: number) => {
                      if (i?.type == "video") {
                        return (
                          <div style={{ position: "relative" }}>
                            <video
                              width="160"
                              height="120"
                              controls
                              key={i?.url}
                              id="lesson-video"
                            >
                              <source src={i?.url} type="video/mp4" />
                              Your browser does not support the video tag.
                            </video>
                            <span
                              onClick={() =>
                                formik.setFieldValue("files", formik.values?.files?.filter((fl: any, ii: number) => index !== ii))
                              }
                              style={{
                                color: "red",
                                position: "absolute",
                                top: "-14px",
                                right: "-8px",
                                cursor: "pointer",
                              }}
                            >
                              <i className="ri-delete-bin-3-fill align-bottom"></i>
                            </span>
                          </div>
                        );
                      } else {
                        return (
                          <div style={{ position: "relative" }}>
                            <img
                              alt="wallpaper"
                              src={i?.url}
                              style={{ maxHeight: 120, maxWidth: 160 }}
                              onClick={() => {
                                setLinkImage(i?.url);
                              }}
                            />


                            <img
                              src={IconDeltete}
                              alt="delete"
                              // width={18}
                              // height={18}
                              onClick={() =>
                                formik.setFieldValue("files", formik.values?.files?.filter((fl: any, ii: number) => index !== ii))
                              }
                              style={{
                                color: 'red',
                                position: 'absolute',
                                top: '-9px',
                                right: '-7px',
                                cursor: 'pointer',
                                border: '3px solid #fff',
                                borderRadius: '14px',
                                width: '28px',
                                height: '28px',
                              }}
                            />
                          </div>
                        );
                      }
                    })}
                </div>
              )}
              <div>
                <Upload
                  {...props}
                  multiple={isEdit ? false : true}
                  beforeUpload={() => false}
                  //   listType="picture-card"
                  // fileList={(formik.getFieldProps("files")?.value as any) || []}
                  fileList={[]}
                  onChange={async (info: any) => {
                    setIsLoadingUploadImage(true);
                    try {
                      const cloneInfo = info?.fileList?.length
                        ? [...info?.fileList]
                        : [];

                      const files = info.fileList;
                      const data = new FormData();
                      for (var i = 0; i < files?.length; i++) {
                        data.append("files", files[i]?.originFileObj);
                      }

                      const res = (await uploadFiles(data)) as any;
                      if (res?.success) {
                        setIsLoadingUploadImage(false);
                        const fortmatedData = res?.data?.map(
                          (i: any, index: number) => {
                            return {
                              type: cloneInfo[index]?.type.includes("video")
                                ? "video"
                                : "image",
                              url: i?.url,
                              preview: i?.url,
                              elements: [],
                              caption: "Bonus Image",
                              mimetype: cloneInfo[index]?.type.includes("video")
                                ? "video/*"
                                : "image/*",
                              width: 1080,
                              height: 1080,
                              ratio: 1.1,
                              seq: 0,
                              // ...i,
                            };
                          }
                        );
                        const newFiles = isEdit ? fortmatedData : [...fortmatedData, ...(formik?.values?.files)];
                        formik.setFieldValue("files", newFiles);
                      } else {
                        setIsLoadingUploadImage(false);
                      }
                    } catch (error: any) {
                      setIsLoadingUploadImage(false);
                      toast(
                        `${t(
                          "Input buffer contains unsupported image format"
                        )}`,
                        CONFIG_OPTION_TOAST_ERROR
                      );
                    }
                  }}
                  onRemove={() => {
                    //return onGalleryFileRemove();
                  }}
                >
                  <Button
                    disabled={isLoadingUploadImage}
                    icon={isLoadingUploadImage ?
                      <Spinner
                        size="sm"
                        className="text-primary"
                      ></Spinner> : <UploadOutlined />}>
                    {!isEdit && (formik?.values?.files?.length > 0) ? t("Upload More") : t("Click to Upload")}
                  </Button>
                </Upload>
                {formik?.touched?.files && formik?.errors?.files ? (
                  <div className="text-danger mt-2">
                    {formik?.errors?.files}
                  </div>
                ) : null}
              </div>
            </Col>

            <div className="col-lg-12">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-primary fs-14"
                  color="success"
                  type="submit"
                  disabled={isLoading}
                  style={{ width: "120px" }}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) : (
                    <>{isEdit ? t("Button Update") : t("Button Create")}</>
                  )}
                </button>
                <button
                  className="btn btn-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={onCloseClick}
                  disabled={isLoading}
                  style={{ width: "100px" }}
                >
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody >
    </Modal >
  );
}
