import { AxiosResponse } from "axios";
import { APIClient } from "./axiosClient";
import { IUserActivityLog } from "./types/_logging";
import { PaginationResponse, ResponseData } from "./types/_public";
const api = new APIClient();

const path = 'logs';
const statisticApi = {
  userLogs(params: any):Promise<AxiosResponse<ResponseData<IUserActivityLog[]> & PaginationResponse, any>> {
    const url = `${path}`
    return api.get(url, params)
  },
}
export default statisticApi
