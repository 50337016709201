import { useRole } from 'components/Hooks/UserHooks';
import React, { useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import { Container, Row } from "reactstrap";
import BreadCrumb from "../../../components/Common/BreadCrumb";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = '';
const COLORS_METHOD: any = { GET: 'success', POST: 'primary', PUT: 'warning', DELETE: 'danger' };

const AdminLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  // const { setDurationResponses } = useDurationResponses();

  const isFirstLoadingPageRef = useRef<any>(true);


  useEffect(() => {
    document.title = `${t('Admin Log')} - ${t('User')} | Dispatch`;
    document.body.classList.remove('vertical-sidebar-enable');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t('Admin Log')} pageTitle={t('Home')} />
          <Row>
            <div className="text-center mt-5 mb-5">{t('Under development')}</div>
          </Row>
        </Container>
      </div >
    </React.Fragment >
  );
};

export default AdminLog;
