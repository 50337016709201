import { createSlice } from "@reduxjs/toolkit";
import { getPushLogs, getmanualPush } from "./thunk";

export interface IState {
  pushLogs: any;
  isPushLogLoading: boolean;
  isPushLogSuccess: boolean;

  manualPush: any,
  isDeletedPushLogLoading: boolean,
  isDeletedPushLogSuccess: boolean,

  error: any;
}

export const initialState: IState = {
  pushLogs: null,
  isPushLogLoading: false,
  isPushLogSuccess: false,

  manualPush: null,
  isDeletedPushLogLoading: false,
  isDeletedPushLogSuccess: false,

  error: {},
};

const PushNotificationSlice = createSlice({
  name: "PushNotification",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Push Logs
    builder.addCase(getPushLogs.pending, (state: IState, action: any) => {
      state.isPushLogLoading = true;
    });
    builder.addCase(getPushLogs.fulfilled, (state: IState, action: any) => {
      state.pushLogs = action.payload.data;
      state.isPushLogSuccess = true;
      state.isPushLogLoading = false;
    });
    builder.addCase(getPushLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPushLogSuccess = false;
      state.isPushLogLoading = false;
    });

    //get Manual Push
    builder.addCase(getmanualPush.pending, (state: IState, action: any) => {
      state.isDeletedPushLogLoading = true;
    });
    builder.addCase(getmanualPush.fulfilled, (state: IState, action: any) => {
      state.manualPush = action.payload.data;
      state.isDeletedPushLogSuccess = true;
      state.isDeletedPushLogLoading = false;
    });
    builder.addCase(getmanualPush.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDeletedPushLogSuccess = false;
      state.isDeletedPushLogLoading = false;
    });
  },
});

export default PushNotificationSlice.reducer;
