import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = '/analyzed';
const statisticApi = {
  dailyUserStatistics(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, {
      ...params,
      type: 'user'
    });
  },
  summaryStatistics(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, {
      ...params,
      type: 'summary'
    });
  },
  hotStatistics(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, {
      ...params,
      type: 'feed'
    });
  },
  wallpaperStatistics(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, {
      ...params,
      type: 'wallpaper'
    });
  },
  photocardNewsStatistics(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, {
      ...params,
      type: 'photo_news'
    });
  },
  snapStatistics(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, {
      ...params,
      type: 'snap'
    });
  },
  pushStatistics(params: any): Promise<any> {
    const url = `${path}/push`;
    return api.get(url, {
      ...params
    });
  },
  countryStatistics(params: any): Promise<any> {
    const url = `${path}/country`;
    return api.get(url, {
      ...params,
    });
  },
}
export default statisticApi
