import React, { useEffect } from "react";

// redux
import { useTranslation } from "react-i18next";
import { WithContext as ReactTags } from 'react-tag-input';

export interface Tag {
  id: string;
  text: string;
}
interface TypesProps {
  name?: string,
  initialValue?: Tag[];
  suggestions?: Tag[];
  placeholder?: string,
  classNameInput?: string,
  onChangeTag?: (params: Tag[]) => void;
}

const KeyCodes = {
  comma: 188,
  enter: 13
};

const delimiters = [KeyCodes.comma, KeyCodes.enter];

const Keywords = ({
  name = '',
  initialValue = [],
  suggestions = [],
  placeholder = '',
  classNameInput = '',
  onChangeTag,
}: TypesProps) => {
  const { t } = useTranslation();

  const handleDelete = (i: number) => {
    onChangeTag && onChangeTag((initialValue || []).filter((tag, index) => index !== i));
  };

  const handleAddition = (tag: any) => {
    onChangeTag && onChangeTag([...initialValue, tag]);
  };

  return (
    <React.Fragment>
      <ReactTags
        tags={initialValue}
        suggestions={suggestions || []}
        delimiters={delimiters}
        handleDelete={handleDelete}
        handleAddition={handleAddition}
        inputFieldPosition="top"
        autocomplete
        minQueryLength={1}
        allowDeleteFromEmptyInput={false}
        allowDragDrop={false}
        placeholder={placeholder}
        classNames={{
          tags: 'hashtags-custom',
          tagInputField: 'form-control',
        }}
      />
    </React.Fragment>
  );
};

export default Keywords;