import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import artistApi from "api/artistApi";
import PostApi from "api/postApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import CollapseContent from "components/Common/CollapseContent";
import CollapseFilter from "components/Common/CollapseFilter";
import ColumnStatusCheck from "components/Common/ColumnStatusCheck";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownAsyncArtist from "components/Common/DropdownAsyncArtist";
import DropdownAsyncGroup from "components/Common/DropdownAsyncGroup";
import DropdownOption from "components/Common/DropdownOption";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import ModalPreview from "components/Common/ModalPreview";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import {
  COLOR_STATUS_FEED,
  ENUM_STATUS_FEED,
  LIST_OPTION_TYPE,
  STATUS_FEED_OPTION,
  TYPE_ARTIST_OPTION,
  WALLPAPER_TYPE,
} from "helpers/constans";
import {
  formatDateStringToKorea,
  formatNumberWithCommas,
} from "helpers/format";
import CountUp from "react-countup";
import { getWallpapers } from "store/post/thunk";
import NO_IMAGE from "../../assets/images/no-image-icon.png";
import ArtistForm from "../Artists/ArtistForm";
import GroupForm from "../Group/GroupForm";
import CreateEditWallpaper from "./CreateEditWallpaper";

interface Option {
  label: string;
  value: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const WallpaperList = (activeWallpaperTab?: any) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const STATUS_FEED_OPTION_LANG =
    STATUS_FEED_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const TYPE_ARTIST_OPTION_LANG =
    TYPE_ARTIST_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const MULTIMAPPING_OPTION_LANG: any = [
    {
      value: "0",
      label: t("None"),
    },
    {
      value: "1",
      label: t("Single"),
    },
    {
      value: "2",
      label: t("Multiple"),
    },
  ];

  const MULTIMAPPING_COLOR: any = {
    ["all"]: "secondary",
    ["0"]: "warning",
    ["1"]: "success",
    ["2"]: "danger",
  };

  const LIST_OPTION_WALLPAPER_TYPE_LANG =
    WALLPAPER_TYPE?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [idPostLoading, setIdPostLoading] = useState<string | null>(null);

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "condition[title]": withDefault(StringParam, ""),
    "condition[type]": withDefault(StringParam, "card"),
    "condition[status]": withDefault(StringParam, ""),
    "condition[artistIds]": withDefault(StringParam, ""),
    "condition[wallpaper_type]": withDefault(StringParam, ""),
    categoryId: withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
    multiMapping: withDefault(StringParam, ""),

    sort_by: withDefault(StringParam, "createdAt"),
    order_by: withDefault(StringParam, "DESC"),
  });

  const [rowEditId, setRowEditId] = useState<any>(null);
  const [rowSelected, setRowSelected] = useState<any>(null);
  const [summaryWallpaper, setSummaryWallpaper] = useState<{
    activated: number;
    disabled: number;
    inReview: number;
  }>({
    activated: 0,
    disabled: 0,
    inReview: 0,
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<any>(false);

  const [postChangeArtistEdit, setPostChangeArtistEdit] = useState<any>(null);
  const [postChangeGroupEdit, setPostChangeGroupEdit] = useState<any>(null);
  const [postIdEdit, setpostIdEdit] = useState<any>(null);
  const [artistOnTable, setArtistOnTable] = useState<any>(null);
  const [groupOnTable, setGroupOnTable] = useState<any>(null);
  const [editingCell, setEditingCell] = useState(null);
  const [editingGroupCell, setEditingGroupCell] = useState(null);

  const [listCheckedGroup, setListCheckedGroup] = useState<any>({
    label: t("All Filter"),
    value: "all",
  });
  const [listCheckedArtist, setListCheckedArtist] = useState<any>({
    label: t("All Filter"),
    value: "all",
  });

  const selectAristRef = useRef<any>([]);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const [isOpenConfirmStatusNews, setIsOpenConfirmStatusNews] =
    useState<boolean>(false);
  const [typeStatusNews, setTypeStatusNews] = useState<any>(null);
  const [isOpenConfirmDeleteMultiple, setIsOpenConfirmDeleteMultiple] =
    useState<boolean>(false);

  const [isOpenCreateArtist, setIsOpenCreateArtist] = useState<boolean>(false);
  const [isActiveWallpaper, setIsActiveWallpaper] = useState<any>(null);
  const [isOpenCreateGroup, setIsOpenCreateGroup] = useState<boolean>(false);

  const [isOpenUpdateTypeWallpaper, setIsOpenConfirmUpdateTypeWallpaper] = useState<number | null>(null);

  const [listGroupCreate, setListGroupCreate] = useState<any>([]);

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query["condition[title]"] || ""
  );
  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_FEED_OPTION_LANG?.find(
      (item) => String(item?.value) === String(query["condition[status]"])
    ) || null
  );

  const [typeSearch, setTypeSearch] = useState<Option | null>(
    LIST_OPTION_WALLPAPER_TYPE_LANG?.find(
      (item) => String(item?.value) === String(query["condition[wallpaper_type]"])
    ) || null
  );

  const [multiMappingSearch, setMultiMappingSearch] = useState<any>();
  const [linkImage, setLinkImage] = useState<string>("");

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const [artistSearch, setArtistSearch] = useState<any | null>(null);
  const [groupSearch, setGroupSearch] = useState<any | null>(null);
  const [formatData, setFormatData] = useState<any>([]);

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Wallpaper;

  const PostProperties = createSelector(selectLayoutState, (state) => ({
    posts: state.posts,
    isPostLoading: state.isPostLoading,
    isPostSuccess: state.isPostSuccess,

    error: state.error,
  }));

  // Inside your component
  const { posts, isPostLoading } = useSelector(PostProperties);

  useEffect(() => {
    setFormatData(posts?.items);
  }, [posts]);

  function loadTableData() {
    dispatch(getWallpapers(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    // console.log(multiMappingSearch, Number(multiMappingSearch?.value));
    setQuery({
      ...query,
      "condition[title]": keywordSearch || "",
      "condition[status]": statusSearch?.value || "",
      "condition[artistIds]": artistSearch || "",
      "condition[wallpaper_type]": typeSearch?.value || "",
      categoryId: groupSearch || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: "createdAt",
      page: 1,
      time_request: +new Date(),
      multiMapping:
        multiMappingSearch?.value >= 0 ? Number(multiMappingSearch?.value) : "",
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[title]": "",
        "condition[status]": "",
        "condition[artist]": "",
        "condition[wallpaper_type]": "",
        categoryId: "",
        startDate: "",
        endDate: "",
        sort_by: "createdAt",
        time_request: +new Date(),
        page: 1,
        multiMapping: "",
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setStatusSearch((_prev) => null);
    setTypeSearch((_prev) => null);
    setArtistSearch((_prev: any) => null);
    setGroupSearch((_prev: any) => null);
    setDateSearch([null, null]);
    setMultiMappingSearch((_prev: any) => null);
    setListCheckedGroup(null);
    setListCheckedArtist(null);
  };

  // Begin::Change status
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);

  const handleOnConfirmUpdate = async (item: {
    id: number | string;
    status: string | boolean;
  }) => {
    try {
      if (!item) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await PostApi?.updatePost(item?.id, {
        status: item?.status,
      });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        // loadTableData();
        setIsActiveWallpaper({
          id: item?.id,
          value: item?.status,
        });

        const formatRes = [...formatData];
        const mapData = formatRes?.map((oldData: any) => {
          if (oldData?._id == item?.id) {
            return {
              ...oldData,
              status: item?.status,
            };
          } else {
            return oldData;
          }
        });
        setFormatData(mapData);

        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End::Change status

  // Begin:: handle select table
  const handleCheck = (
    valueCheck: Option,
    valueIndex: number | string,
    e: any = null
  ) => {
    const index = String(valueIndex);
    if (valueCheck?.value === "ALL") {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (e.target.checked) {
          if (prevClone[index] === undefined) {
            prevClone[index] = [];
          }
          prevClone[index] = (posts?.items || [])?.map((item: any) => ({
            value: item?._id,
            label: item?.keyword,
          }));
          return prevClone;
        } else {
          prevClone[index] = [];
          return prevClone;
        }
      });
      return;
    }
    if (!e.target.checked) {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = (prevClone[index] || [])?.filter(
          (item: any) => item?.value !== valueCheck?.value
        );
        return prevClone;
      });
    } else {
      setOptionsSelected((prev: any) => {
        const prevClone = structuredClone(prev);
        if (prevClone[index] === undefined) {
          prevClone[index] = [];
        }
        prevClone[index] = [...prevClone[index], valueCheck];
        return prevClone;
      });
    }
  };

  const isCheckedAll = (valueIndex: string) => {
    const valueIndexString = String(valueIndex);
    return (
      optionsSelected[valueIndexString]?.length > 0 &&
      optionsSelected[valueIndexString]?.length === posts?.items?.length
    );
  };

  const countRowSelected = useMemo(() => {
    return Object.entries(optionsSelected)?.reduce(
      (total: number, [key, value]: any) => {
        return Number(total + (value || [])?.length);
      },
      0
    );
  }, [optionsSelected]);
  // console.log(posts);

  const handleActiveDisableNews = (status: string) => {
    setTypeStatusNews(status);
    setIsOpenConfirmStatusNews(true);
  };

  const handleDeleteMultiple = () => {
    setIsOpenConfirmDeleteMultiple(true);
  };

  const handleConfirmUpdateTypeNews = (type: number) => {
    setIsOpenConfirmUpdateTypeWallpaper(type);
  };

  const handleActionStatusNews = async () => {
    if (typeStatusNews === null) {
      return;
    }

    const ids = Object.entries(optionsSelected)
      ?.reduce((arr: Option[], [key, value]: any) => {
        return [...arr, ...(value || [])];
      }, [])
      ?.map((item: Option) => item?.value);

    if (ids?.length < 1) return;

    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await PostApi?.updateStatusPost({
        articleIds: ids,
        status: typeStatusNews,
      });

      if (response?.success === true) {
        loadTableData();

        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmStatusNews(false);
        setTypeStatusNews(null);
        setOptionsSelected((_prev: any) => ({}));

        if (response?.data?.message) {
          toast(`${response?.data?.message}`, CONFIG_OPTION_TOAST_NORMAL);
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmStatusNews(false);
        setTypeStatusNews(null);
        toast(`${response?.message}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const onCloseConfirmActionNewsClick = () => {
    setIsOpenConfirmStatusNews(false);
    setTypeStatusNews(null);
  };

  const onCloseConfirmDeleteMultipleClick = () => {
    setIsOpenConfirmDeleteMultiple(false);
  };

  const onCloseConfirmUpdateTypeClick = () => {
    setIsOpenConfirmUpdateTypeWallpaper((_prev) => null);
  };


  const handleActionDeleteMultiple = async () => {
    const ids = Object.entries(optionsSelected)
      ?.reduce((arr: Option[], [key, value]: any) => {
        return [...arr, ...(value || [])];
      }, [])
      ?.map((item: Option) => item?.value);

    if (ids?.length < 1) return;

    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await PostApi?.deleteMultiplePost({
        data: {
          articleIds: ids,
        },
      });

      if (response?.success === true) {
        loadTableData();

        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmDeleteMultiple(false);
        setOptionsSelected((_prev: any) => ({}));

        if (response?.data?.message) {
          toast(`${response?.data?.message}`, CONFIG_OPTION_TOAST_NORMAL);
        }
      } else {
        setIsConfirmLoading((_prev) => false);
        setIsOpenConfirmDeleteMultiple(false);
        toast(`${response?.message}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const handleActionUpdateTypeMultiple = async () => {
    const ids = Object.entries(optionsSelected)
      ?.reduce((arr: Option[], [key, value]: any) => {
        return [...arr, ...(value || [])];
      }, [])
      ?.map((item: Option) => item?.value);

    if (ids?.length < 1) return;

    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await PostApi?.updateTypeMultiplePost(
        {
          articleIds: ids,
          type: isOpenUpdateTypeWallpaper
        },
      );

      if (response?.success === true) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        onCloseConfirmUpdateTypeClick();
        setOptionsSelected((_prev: any) => ({}));
        toast(`${response?.data?.message || ''}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        setIsConfirmLoading((_prev) => false);
        onCloseConfirmUpdateTypeClick();
        toast(`${response?.message}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };


  const handleUpdateArtistGroup = async (
    type = "artist",
    data?: any,
    rowEditId?: any
  ) => {
    let postID: any = null;

    if (type === "artist") {
      postID = postChangeArtistEdit ? postChangeArtistEdit : rowEditId;
    }

    if (type === "group") {
      postID = postChangeGroupEdit ? postChangeGroupEdit : rowEditId;
    }

    if (!data || !postID) return;

    setIdPostLoading((_prev) => `${postID}-${type}`);

    const listArtistIds = data?.artistIds?.length ? data?.artistIds : rowSelected?.artistIds;
    const listCategoryIds = data?.categoryIds?.length ? data?.categoryIds : rowSelected?.categoryIds;
    if (listArtistIds?.length && listCategoryIds?.length) {
      const artistId = listArtistIds[0];
      const body = {
        categoryIds: listCategoryIds,
      };
      artistApi.putArtist(artistId, body);
    }

    try {
      if (rowEditId) {
        data = { ...data, status: "in_review" };
      }
      const response: any = await PostApi?.updatePost(postID, data);

      if (response?.data) {
        // loadTableData();
        setIsOpen(false);
        const resUpdatedArticle = response?.data;
        if (resUpdatedArticle) {
          setFormatData((_prev: any) => _prev?.map((item: any) => (item?._id === postID ? resUpdatedArticle : item)));
        }

        if (type == "group") {
          setPostChangeGroupEdit(null);
        } else {
          setPostChangeArtistEdit(null);
        }
        setIdPostLoading((_prev) => null);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        if (type === "group") {
          setPostChangeGroupEdit(null);
        } else {
          setPostChangeArtistEdit(null);
        }
        setIdPostLoading((_prev) => null);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      if (type === "group") {
        setPostChangeGroupEdit(null);
      } else {
        setPostChangeArtistEdit(null);
      }
      setIdPostLoading((_prev) => null);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const handleChangeArtistTable = async (e: any, categoryIds: string[] = []) => {
    selectAristRef?.current[e?.value]?.focus();
    setArtistOnTable(e?.value);
    // const resArtist: any = await artistApi.getArtist(e?.value);
    // const artist = listArtist.find((i: any) => i?.value == e?.value);
    let data = null;

    if (categoryIds?.length > 0) {
      setEditingGroupCell(null);
      data = {
        artistIds: !!e?.value ? [e?.value] : [],
        categoryIds: categoryIds,
      };
    } else {
      data = {
        artistIds: !!e?.value ? [e?.value] : [],
      };
    }

    handleUpdateArtistGroup("artist", data);
  };

  const handleChangeGroupTable = (e: any) => {
    setGroupOnTable(e?.value);
    const data = {
      categoryIds: e?.value ? [e?.value] : [],
    };
    handleUpdateArtistGroup("group", data);
  };

  const handleCellClick = (cellId: any) => {
    setEditingCell(cellId);
  };
  const handleCellGroupClick = (cellId: any) => {
    setEditingGroupCell(cellId);
  };

  const handleClickArtistOutside = (e: any) => {
    if (editingCell && !e.target.closest(`.editable-cell-${editingCell}`)) {
      setArtistOnTable(null);
      setPostChangeArtistEdit(null);
    }
  };

  const handleClickGroupOutside = (e: any) => {
    if (
      editingGroupCell &&
      !e.target.closest(`.editable-group-cell-${editingGroupCell}`)
    ) {
      setGroupOnTable(null);
      setPostChangeGroupEdit(null);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickArtistOutside);
    return () => {
      document.removeEventListener("click", handleClickArtistOutside);
    };
  }, [editingCell]);

  useEffect(() => {
    document.addEventListener("click", handleClickGroupOutside);
    return () => {
      document.removeEventListener("click", handleClickGroupOutside);
    };
  }, [editingGroupCell]);

  const formatOptionGroup = (itemGroup: any) => {
    if (!itemGroup) return;

    return {
      value: String(itemGroup?._id),
      label: (
        <div className="d-flex align-items-center gap-2">
          <div
            style={{
              height: "22px",
              width: "22px",
              overflow: "hidden",
              borderRadius: "15px",
              objectFit: "cover",
              backgroundColor: "#EAEAEA",
              minWidth: "22px",
              minHeight: "22px",
            }}
          >
            <img
              src={itemGroup?.image || NO_IMAGE}
              alt={itemGroup?.title?.ko}
              height={"100%"}
              width={22}
              className="me-2"
              style={{
                overflow: "hidden",
                borderRadius: "50%",
                objectFit: "cover",
                backgroundColor: "#EAEAEA",
              }}
            />
          </div>
          {`${itemGroup?.title?.ko} - ${itemGroup?.title?.en}`}
        </div>
      ),
      data: itemGroup,
    };
  };

  const formatOptionArtist = (itemArtist: any) => {
    if (!itemArtist) return;

    return {
      value: String(itemArtist?._id),
      label: (
        <div className="d-flex align-items-center gap-2">
          <div
            style={{
              height: "22px",
              width: "22px",
              overflow: "hidden",
              borderRadius: "15px",
              objectFit: "cover",
              backgroundColor: "#eaeaea",
              zIndex: 9,
              minWidth: "22px",
              minHeight: "22px",
            }}
          >
            <img
              src={itemArtist?.avatar || NO_IMAGE}
              alt={itemArtist?.name}
              height={22}
              width={22}
              className="me-2"
              style={{
                overflow: "hidden",
                borderRadius: "50%",
                objectFit: "cover",
                backgroundColor: "#eaeaea",
              }}
            />
          </div>
          {`${itemArtist?.name?.ko} - ${itemArtist?.name?.en} (${TYPE_ARTIST_OPTION?.find(
            (item: any) => String(item?.value) === String(itemArtist?.type)
          )?.label || ""
            } - ${t(itemArtist?.gender)})`}
        </div>
      ),
      data: itemArtist,
    };
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("Key"),
        accessor: "",
        filterable: true,
        sortable: false,
        thClass: "text-start",
        thWidth: 45,
        thComponent: () => (
          <>
            <div className="form-check">
              <input
                className="form-check-input"
                type="checkbox"
                checked={isCheckedAll(query?.page)}
                onChange={(e) =>
                  handleCheck({ value: "ALL", label: "" }, query?.page, e)
                }
                value=""
              />
              <label className="form-check-label"></label>
            </div>
          </>
        ),
        Cell: (cell: any) => {
          const item = cell?.row?.original;

          return (
            <ColumnStatusCheck
              item={item}
              handleCheck={handleCheck}
              optionsSelected={optionsSelected}
              page={query?.page}
            />
          );
        },
      },
      // {
      //   Header: t("No."),
      //   thWidth: 80,
      //   Cell: ({ ...props }) => {
      //     return (
      //       <div>
      //         {posts?.total -
      //           ((query?.page - 1) * query?.limit + props?.row?.index)}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: t("No."),
        thWidth: 80,
        thClass: 'whitespace-nowrap',
        Cell: ({ ...props }) => {
          const item = props?.row?.original;
          return (
            <CopyWrapper
              contentCopy={item?._id || ""}
              style={{ minWidth: "60px" }}
            >
              {posts?.total - ((query?.page - 1) * query?.limit + props?.row?.index)}
            </CopyWrapper>
          );
        },
      },
      {
        Header: t("Active"),
        accessor: "status",
        thWidth: 70,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const isChecked =
            isActiveWallpaper && isActiveWallpaper?.id === item?._id
              ? isActiveWallpaper?.value === "disabled"
                ? false
                : true
              : String(item?.status) === ENUM_STATUS_FEED.ACTIVATED;
          return (
            <div
              className="form-check form-switch form-switch-sm text-center"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                checked={String(item?.status) === ENUM_STATUS_FEED.ACTIVATED}
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleOnConfirmUpdate({
                    id: item?._id,
                    status: !!value
                      ? ENUM_STATUS_FEED.ACTIVATED
                      : ENUM_STATUS_FEED.DISABLED,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 70,
        thClass: "text-center",
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center">
              <span
                className={`rounded-pill badge bg-${COLOR_STATUS_FEED[status] || "secondary"
                  }`}
              >
                {
                  STATUS_FEED_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(status)
                  )?.label
                }
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Photo"),
        accessor: "thumbnail_url",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const _thumbnail = item?.files?.[0]?.thumbnail || '';
          return _thumbnail ? (
            <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImage((prev) => item?.files?.[0]?.url)}
                >
                  <img
                    src={_thumbnail}
                    className="flex-shrink-0 rounded w-100"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          );
        },
      },
      {
        Header: t("Type"),
        accessor: "wallpaper_type",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const wallpaper_type = cell?.row?.original?.wallpaper_type;
          return (
            <div className="text-center">
              {
                LIST_OPTION_WALLPAPER_TYPE_LANG?.find(
                  (item: any) => String(item?.value) === String(wallpaper_type)
                )?.label
              }
            </div>
          );
        },
      },
      {
        Header: t("Connected Artist"),
        accessor: "multiMapping",
        thWidth: 140,
        thClass: "text-center",
        Cell: (cell: any) => {
          const multiMapping = cell?.row?.original?.multiMapping?.toString();

          return (
            <div className="text-center">
              <span
                className={`rounded-pill badge bg-${MULTIMAPPING_COLOR[multiMapping] || "secondary"
                  }`}
              >
                {
                  MULTIMAPPING_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(multiMapping)
                  )?.label
                }
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Artist"),
        accessor: "artist",
        filterable: true,
        sortable: false,
        thWidth: 250,
        // thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const artist = item?.artists?.[0];
          let formatArtistOption: any = null;

          if (artist) {
            formatArtistOption = formatOptionArtist(artist);
          }
          return (
            <div className="position-relative">
              {idPostLoading === `${item?._id}-artist` && <div className="position-absolute loading-full-cell">
                <Spinner size="sm" className="me-2 text-primary"></Spinner>
              </div>}
              <div
                tabIndex={item?._id}
                onClick={() => handleCellClick(item?._id)}
                className={`editable-cell-${item?._id}  d-flex justify-content-center align-items-center`}
                style={{ minWidth: "70px", width: "100%" }}
              >
                {postChangeArtistEdit === item?._id ? (
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <DropdownAsyncArtist
                      inputRefToFocus={(el: any) =>
                        (selectAristRef.current[item?._id] = el)
                      }
                      name="artistIds"
                      onChangeSelect={(e) => handleChangeArtistTable(e, (e as any)?.data?.categoryIds || [])}
                      isMulti={false}
                      placeholder={`${t("Select Artist")}...`}
                      isShowIconCheck={false}
                      listCheckedArtist={artistOnTable}
                      hideSelectedOptions={true}
                      isHasOptionAll={false}
                      width="200px"
                    />
                  </div>
                ) : artist ? (
                  <>
                    <div className="flex-grow-1">
                      {artist?.name?.ko} <br />
                      {artist?.name?.en} <br />
                      {TYPE_ARTIST_OPTION_LANG?.find(
                        (item: any) =>
                          String(item?.value) === String(artist?.type)
                      )?.label || ""}
                      <br />
                      {t(`${artist?.gender || ""}`)}
                    </div>
                  </>
                ) : (
                  <div className="flex-grow-1">-</div>
                )}
                <TooltipCustom
                  title={t("Button Create Artist")}
                  id={`tooltip-create-artist`}
                  style={{ listStyleType: "none" }}
                >
                  <div
                    className={`me-1 cursor-pointer ${postChangeArtistEdit === item?._id ? "d-none" : "d-block"
                      }`}
                    id={`tooltip-create-artist`}
                    onClick={() => {
                      setIsOpenCreateArtist(true);
                      setRowEditId(item?._id);
                      setRowSelected(item);
                    }}
                  >
                    <i
                      className="ri-add-fill text-black"
                      style={{ fontSize: "25px", fontWeight: 700 }}
                    ></i>
                  </div>
                </TooltipCustom>

                <TooltipCustom
                  title={t("Select Artist")}
                  id={`tooltip-edit-artist`}
                  style={{ listStyleType: "none" }}
                >
                  <div
                    className={`cursor-pointer ${postChangeArtistEdit === item?._id ? "d-none" : "d-block"
                      }`}
                    id={`tooltip-edit-artist`}
                    onClick={() => {
                      setPostChangeArtistEdit(item?._id);
                      setArtistOnTable(
                        formatArtistOption ? formatArtistOption : null
                      );
                      setpostIdEdit(item?._id);
                      setRowSelected(item);
                    }}
                  >
                    <i
                      className="ri-check-fill text-black"
                      style={{ fontSize: "25px", fontWeight: 700 }}
                    ></i>
                  </div>
                </TooltipCustom>

                <div
                  className={`ms-1 cursor-pointer ${postChangeArtistEdit == item?._id ? "d-block" : "d-none"
                    }`}
                  onClick={() => setPostChangeArtistEdit(false)}
                >
                  <i
                    className="ri-close-circle-fill text-black"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: t("Group"),
        accessor: "categories",
        filterable: true,
        sortable: false,
        thWidth: 250,
        //thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const category = cell?.value?.length > 0 ? cell?.value?.[0] : "";
          let formatGroupOption: any = null;

          if (category) {
            formatGroupOption = formatOptionGroup(category);
          }

          return (
            <div className="position-relative">
              {idPostLoading === `${item?._id}-group` && <div className="position-absolute loading-full-cell">
                <Spinner size="sm" className="me-2 text-primary"></Spinner>
              </div>}
              <div
                tabIndex={item?._id}
                onClick={() => handleCellGroupClick(item?._id)}
                className={`editable-group-cell-${item?._id} d-flex justify-content-center align-items-center`}
                style={{ minWidth: "140px", width: "100%" }}
              >
                {postChangeGroupEdit == item?._id ? (
                  <div
                    className="d-flex"
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      width: "100%",
                    }}
                  >
                    <DropdownAsyncGroup
                      name="categoryIds"
                      onChangeSelect={handleChangeGroupTable}
                      isMulti={false}
                      placeholder={`${t("Select Group")}...`}
                      isShowIconCheck={false}
                      listCheckedGroup={groupOnTable}
                      hideSelectedOptions={true}
                      isHasOptionAll={false}
                      width="180px"
                    />
                  </div>
                ) : category ? (
                  <>
                    <div className="flex-grow-1">
                      {category?.title?.ko} <br />
                      {category?.title?.en}
                    </div>
                  </>
                ) : (
                  <div className="flex-grow-1">-</div>
                )}

                <TooltipCustom
                  title={t("Button Create Group")}
                  id={`tooltip-create-group`}
                  style={{ listStyleType: "none" }}
                >
                  <div
                    className={`ms-1 cursor-pointer ${postChangeArtistEdit === item?._id ? "d-none" : "d-block"
                      }`}
                    id={`tooltip-create-group`}
                    onClick={() => {
                      setIsOpenCreateGroup(true);
                      setRowEditId(item?._id);
                      setRowSelected(item);
                      setItemToEdit(null);
                    }}
                  >
                    <i
                      className="ri-add-fill text-black"
                      style={{ fontSize: "25px", fontWeight: 700 }}
                    ></i>
                  </div>
                </TooltipCustom>

                <TooltipCustom
                  title={t("Select Group")}
                  id={`tooltip-edit-group`}
                  style={{ listStyleType: "none" }}
                >
                  <div
                    className={`ms-1 cursor-pointer ${postChangeGroupEdit == item?._id ? "d-none" : "d-block"
                      }`}
                    id={`tooltip-edit-group`}
                    onClick={() => {
                      setPostChangeGroupEdit(item?._id);
                      setGroupOnTable(
                        formatGroupOption ? formatGroupOption : null
                      );
                      setpostIdEdit(item?._id);
                      setRowEditId(item?._id);
                      setRowSelected(item);
                    }}
                  >
                    <i
                      className="ri-check-fill text-black"
                      style={{ fontSize: "25px", fontWeight: 700 }}
                    ></i>
                  </div>
                </TooltipCustom>

                <div
                  className={`ms-1 cursor-pointer ${postChangeGroupEdit == item?._id ? "d-block" : "d-none"
                    }`}
                  onClick={() => setPostChangeGroupEdit(false)}
                >
                  <i
                    className="ri-close-circle-fill text-black"
                    style={{ fontSize: "16px" }}
                  ></i>
                </div>
              </div>
            </div>
          );
        },
      },
      {
        Header: t("Content"),
        accessor: "content",
        filterable: true,
        sortable: false,
        thWidth: 250,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const content = `${item?.title}\n${item?.description}`;
          return (
            <>
              <CollapseContent
                style={{ minWidth: "250px", whiteSpace: "pre-line" }}
                dataOriginal={content}
                isRank={false}
                isInlineBlock={true}
                isShowModal={true}
                titleModal={t("Content")}
              />
            </>
          );
        },
      },
      {
        Header: t("Source"),
        accessor: "description",
        filterable: true,
        sortable: true,
        thWidth: 90,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          const resourceId = item?.resourceId;
          if (!resourceId) return;
          return (
            <div className="text-center">
              <a
                className="text-center"
                href={`https://www.dispatch.co.kr/${resourceId}`}
                target="_blank"
              >
                {`[${t("Link")}]`}
              </a>
            </div>
          );
        },
      },
      {
        Header: t("View"),
        accessor: "summary_view",
        filterable: true,
        sortable: true,
        thWidth: 70,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(item?.summary?.view)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Share"),
        accessor: "summary_share",
        filterable: true,
        sortable: true,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(item?.summary?.share)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Download"),
        accessor: "summary_download",
        filterable: true,
        sortable: true,
        thWidth: 110,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <>
              <div className="text-center">
                {formatNumberWithCommas(item?.summary?.download)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: true,
        thWidth: 120,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 120,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
              style={{ width: "120px", justifyContent: "center" }}
            >
              <TooltipCustom
                title={t("Button Update")}
                id={`update-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`update-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-primary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(true);
                      setItemToEdit(item);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>

              <TooltipCustom
                title={t("Button Delete")}
                id={`delete-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`delete-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-danger edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setItemToDelete(item);
                    }}
                  >
                    <i className="ri-delete-bin-3-fill align-bottom"></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, posts?.total, idPostLoading]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  // Begin::Delete

  const onCloseCreateGroupModal = () => {
    setIsOpenCreateGroup(false);
  };

  const onCloseCreateArtistModal = () => {
    setIsOpenCreateArtist(false);
  };

  const onCloseCreateArtistSubmitModal = (type = "artist") => {
    if (type == "group") {
      setIsOpenCreateGroup(false);
    } else {
      setIsOpenCreateArtist(false);
    }
  };

  const onCloseImageClick = () => {
    setLinkImage((_prev) => "");
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await PostApi?.deletePost(id);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End::Delete

  const createArtistAndAutoAssignGroup = async (
    artist: any,
    rowEditId?: any
  ) => {
    if (!artist || !rowEditId) return;
    let data = null;
    if (artist?.categoryIds.length > 0) {
      setEditingGroupCell(null);
      data = {
        artistIds: !!artist?._id ? [artist?._id] : [],
        categoryIds: !!artist?.categoryIds?.[0]
          ? [artist?.categoryIds?.[0]]
          : [],
      };
    } else {
      data = {
        artistIds: !!artist?._id ? [artist?._id] : [],
      };
    }

    await handleUpdateArtistGroup("artist", data, rowEditId);
    setRowEditId(null);
    setRowSelected(null);
  };

  const createGroupAutoAssignGroup = async (group: any, rowEditId?: any) => {
    if (!group || !rowEditId) return;
    const data = {
      categoryIds: group?._id ? [group?._id] : [],
    };
    await handleUpdateArtistGroup("group", data, rowEditId);
    setRowEditId(null);
    setRowSelected(null);
  };

  const loadSummaryWallpaper = async () => {
    const summary = await PostApi.getSummaryWallpaper({
      "condition[type]": "card",
    });
    if (summary?.data?.statusCounts) {
      setSummaryWallpaper(summary?.data?.statusCounts);
    }
  };

  useEffect(() => {
    loadSummaryWallpaper();
  }, []);


  const handleSelectAsyncGroup = (event: any) => {
    setListCheckedGroup(event);
    setGroupSearch(event?.value);
  };

  const handleSelectAsyncArtist = (event: any) => {
    setListCheckedArtist(event);
    setArtistSearch(event?.value);
  };

  return (
    <div>
      <CardHeader className="border-0">
        <div className="d-block d-xl-flex justify-content-between align-items-start">
          <div
            className="d-flex w-100 flex-wrap gap-3 gap-md-2 menu-card-statistics justify-content-start mb-3 mb-xl-2 position-relative"
            style={{ maxWidth: "100%" }}
          >
            <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
              <CardBody className="p-3">
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">
                      {t("Total")}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                  <div>
                    <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                      <span className="counter-value text-primary">
                        <CountUp
                          start={0}
                          end={posts?.total || 0}
                          duration={1}
                        />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
            <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
              <CardBody className="p-3">
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">
                      {t("Activated")}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                  <div>
                    <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                      <span className="counter-value text-primary">
                        <CountUp
                          start={0}
                          end={summaryWallpaper?.activated || 0}
                          duration={1}
                        />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
              <CardBody className="p-3">
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">
                      {t("In Review")}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                  <div>
                    <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                      <span className="counter-value text-primary">
                        <CountUp
                          start={0}
                          end={summaryWallpaper?.inReview || 0}
                          duration={1}
                        />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>

            <Card className="card-animate mb-0 me-0 me-md-2 mb-1 bg-primary-subtle text-primary border-0">
              <CardBody className="p-3">
                <div className="d-flex align-items-center ">
                  <div className="flex-grow-1 overflow-hidden">
                    <p className="text-uppercase fw-medium text-primary text-truncate fs-12 mb-0">
                      {t("Disabled")}
                    </p>
                  </div>
                </div>
                <div className="d-flex align-items-end justify-content-between mt-2 pt-0">
                  <div>
                    <h4 className="fs-20 fw-semibold ff-secondary mb-0">
                      <span className="counter-value text-primary">
                        <CountUp
                          start={0}
                          end={summaryWallpaper?.disabled || 0}
                          duration={1}
                        />
                      </span>
                    </h4>
                  </div>
                </div>
              </CardBody>
            </Card>
          </div>

          <div className="d-flex justify-content-end w-100">
            <button
              type="button"
              className="btn btn-success me-2"
              onClick={() => {
                setIsOpenCreateArtist(true);
              }}
            >
              <i className="ri-add-fill align-bottom me-1"></i>{" "}
              {t("Button Create Artist")}
            </button>

            <button
              type="button"
              className="btn btn-success me-2"
              onClick={() => {
                setIsOpenCreateGroup(true);
              }}
            >
              <i className="ri-add-fill align-bottom me-1"></i>{" "}
              {t("Button Create Group")}
            </button>

            <button
              type="button"
              className="btn btn-success"
              onClick={() => {
                setIsOpen(true);
                setItemToEdit(null);
              }}
            >
              <i className="ri-add-fill align-bottom me-1"></i>{" "}
              {t("Create Wallpaper")}
            </button>
          </div>
        </div>
      </CardHeader>
      <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-3">
        <CollapseFilter>
          <Row className="g-4 align-items-center mt-0 mt-md-2">
            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-2">
              <LabelWrapper label={t("Artist")} isShow={true}>
                <DropdownAsyncArtist
                  name="artist"
                  onChangeSelect={handleSelectAsyncArtist}
                  isMulti={false}
                  placeholder={`${t("Select Artist")}...`}
                  isShowIconCheck={false}
                  listCheckedArtist={listCheckedArtist}
                  hideSelectedOptions={true}
                  isHasOptionAll={true}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3 mt-md-2">
              <LabelWrapper label={t("Group")} isShow={true}>
                <DropdownAsyncGroup
                  name="group"
                  onChangeSelect={handleSelectAsyncGroup}
                  isMulti={false}
                  placeholder={`${t("Select Group")}...`}
                  isShowIconCheck={false}
                  listCheckedGroup={listCheckedGroup}
                  hideSelectedOptions={true}
                  isHasOptionAll={true}
                />
              </LabelWrapper>
            </Col>
            <Col
              sm={12} md={4} xl={3} xxl={3}
              className="mt-3 mt-md-2 date-picker-wrapper-custom"
            >
              <LabelWrapper label={t("Date")} isShow={!!startDate || !!endDate}>
                <DatePickerCustom
                  startDate={startDate || null}
                  endDate={endDate || null}
                  onChangePicker={handleChangePicker}
                />
              </LabelWrapper>
            </Col>
            <Col sm={12} md={3} xl={3} xxl={3} className="mt-3 d-none d-xl-block mt-md-2"></Col>
            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3">
              <LabelWrapper label={t("Status")} isShow={true}>
                <DropdownStatus
                  name="status"
                  dataList={STATUS_FEED_OPTION_LANG || []}
                  placeholder={`${t("Status")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={statusSearch || null}
                  onChangeSelect={(e: any) => setStatusSearch(e)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                  colors={COLOR_STATUS_FEED}
                />
              </LabelWrapper>
            </Col>

            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3">
              <LabelWrapper label={t("Connected Artist")} isShow={true}>
                <DropdownStatus
                  name="multiMapping"
                  dataList={MULTIMAPPING_OPTION_LANG}
                  placeholder={`${t("Connected Artist")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={multiMappingSearch || null}
                  onChangeSelect={(e: any) => setMultiMappingSearch(e)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "all" }}
                  colors={MULTIMAPPING_COLOR}
                />
              </LabelWrapper>
            </Col>
            <Col sm={12} md={4} xl={3} xxl={3} className="mt-3">
              <LabelWrapper label={t("Type")} isShow={true}>
                <DropdownOption
                  name="type"
                  dataList={LIST_OPTION_WALLPAPER_TYPE_LANG || []}
                  placeholder={`${t("Type")}...`}
                  className="dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  initialValue={typeSearch || null}
                  onChangeSelect={(e: any) => setTypeSearch(e)}
                  isHasOptionAll={true}
                  optionAll={{ label: t("All Filter"), value: "" }}
                />
              </LabelWrapper>
            </Col>

            <Col
              sm={12} md={12} xl={3} xxl={3}
              className="hstack gap-1 justify-content-center justify-content-md-end  justify-content-xl-start mt-sm-3"
            >
              <div>
                <button
                  type="button"
                  className="btn btn-primary me-1"
                  onClick={searchData}
                  disabled={isPostLoading}
                >
                  <i className="ri-search-line align-bottom me-1"></i>{" "}
                  {t("Button Search")}
                </button>
                <button
                  type="button"
                  className="btn btn-secondary fs-14"
                  onClick={resetData}
                >
                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                  {t("Button Reset")}
                </button>
              </div>
            </Col>

            <Col
              sm={12}
              md={12}
              xl={12}
              xxl={12}
              className="hstack gap-1 justify-content-center justify-content-md-start mt-4 pt-1 d-none d-md-flex"
            >
              <div>
                <button
                  type="button"
                  className="btn btn-warning mb-2 me-3 mb-sm-0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmUpdateTypeNews(LIST_OPTION_WALLPAPER_TYPE_LANG[1]?.value);
                  }}
                  disabled={isConfirmLoading || countRowSelected <= 0}
                >
                  {countRowSelected > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {formatNumberWithCommas(countRowSelected)}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  <i className="ri-checkbox-circle-line align-bottom me-1"></i>{" "}
                  {t("Type Site")}
                </button>
                <button
                  type="button"
                  className="btn btn-warning mb-2 me-3 mb-sm-0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleConfirmUpdateTypeNews(LIST_OPTION_WALLPAPER_TYPE_LANG[0]?.value);
                  }}
                  disabled={isConfirmLoading || countRowSelected <= 0}
                >
                  {countRowSelected > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {formatNumberWithCommas(countRowSelected)}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  <i className="ri-checkbox-circle-line align-bottom me-1"></i>{" "}
                  {t("Type Only")}
                </button>
                <button
                  type="button"
                  className="btn btn-success mb-2 me-3 mb-sm-0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleActiveDisableNews("activated");
                  }}
                  disabled={isConfirmLoading || countRowSelected <= 0}
                >
                  {countRowSelected > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {formatNumberWithCommas(countRowSelected)}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  <i className="ri-checkbox-circle-line align-bottom me-1"></i>{" "}
                  {t("Active")}
                </button>
                <button
                  type="button"
                  className="btn btn-primary mb-2 me-3 mb-sm-0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleActiveDisableNews("disabled");
                  }}
                  disabled={isConfirmLoading || countRowSelected <= 0}
                >
                  {countRowSelected > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {formatNumberWithCommas(countRowSelected)}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                  {t("Disable")}
                </button>

                <button
                  type="button"
                  className="btn btn-danger  mb-2 mb-sm-0"
                  onClick={(e) => {
                    e.preventDefault();
                    handleDeleteMultiple();
                  }}
                  disabled={isConfirmLoading || countRowSelected <= 0}
                >
                  {countRowSelected > 0 && (
                    <span
                      className="position-absolute topbar-badge badge rounded-pill bg-danger"
                      style={{ transform: "translate(0%, -70%)" }}
                    >
                      {formatNumberWithCommas(countRowSelected)}
                      <span className="visually-hidden">
                        total keywords selected
                      </span>
                    </span>
                  )}
                  <i className="ri-delete-bin-3-fill align-bottom me-1"></i>{" "}
                  {t("Button Delete")}
                </button>
              </div>
            </Col>
          </Row>
        </CollapseFilter>

        <Col
          sm={12}
          md={3}
          xl={3}
          xxl={3}
          className="hstack gap-1 justify-content-start justify-content-md-end mt-sm-4 mt-md-3 mt-3 d-flex d-md-none"
        >
          <div>
            <button
              type="button"
              className="btn btn-warning mb-2 me-3 mb-sm-0"
              onClick={(e) => {
                e.preventDefault();
                handleConfirmUpdateTypeNews(LIST_OPTION_WALLPAPER_TYPE_LANG[1]?.value);
              }}
              disabled={isConfirmLoading || countRowSelected <= 0}
            >
              {countRowSelected > 0 && (
                <span
                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                  style={{ transform: "translate(0%, -70%)" }}
                >
                  {formatNumberWithCommas(countRowSelected)}
                  <span className="visually-hidden">
                    total keywords selected
                  </span>
                </span>
              )}
              <i className="ri-checkbox-circle-line align-bottom me-1"></i>{" "}
              {t("Type Site")}
            </button>
            <button
              type="button"
              className="btn btn-warning mb-2 me-3 mb-sm-0"
              onClick={(e) => {
                e.preventDefault();
                handleConfirmUpdateTypeNews(LIST_OPTION_WALLPAPER_TYPE_LANG[0]?.value);
              }}
              disabled={isConfirmLoading || countRowSelected <= 0}
            >
              {countRowSelected > 0 && (
                <span
                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                  style={{ transform: "translate(0%, -70%)" }}
                >
                  {formatNumberWithCommas(countRowSelected)}
                  <span className="visually-hidden">
                    total keywords selected
                  </span>
                </span>
              )}
              <i className="ri-checkbox-circle-line align-bottom me-1"></i>{" "}
              {t("Type Only")}
            </button>
            <button
              type="button"
              className="btn btn-success mb-2 me-3 mb-sm-0"
              onClick={(e) => {
                e.preventDefault();
                handleActiveDisableNews("activated");
              }}
              disabled={isConfirmLoading || countRowSelected <= 0}
            >
              {countRowSelected > 0 && (
                <span
                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                  style={{ transform: "translate(0%, -70%)" }}
                >
                  {formatNumberWithCommas(countRowSelected)}
                  <span className="visually-hidden">
                    total keywords selected
                  </span>
                </span>
              )}
              <i className="ri-checkbox-circle-line align-bottom me-1"></i>{" "}
              {t("Active")}
            </button>
            <button
              type="button"
              className="btn btn-primary mb-2 me-3 mb-sm-0"
              onClick={(e) => {
                e.preventDefault();
                handleActiveDisableNews("disabled");
              }}
              disabled={isConfirmLoading || countRowSelected <= 0}
            >
              {countRowSelected > 0 && (
                <span
                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                  style={{ transform: "translate(0%, -70%)" }}
                >
                  {formatNumberWithCommas(countRowSelected)}
                  <span className="visually-hidden">
                    total keywords selected
                  </span>
                </span>
              )}
              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
              {t("Disable")}
            </button>

            <button
              type="button"
              className="btn btn-danger  mb-2 mb-sm-0"
              onClick={(e) => {
                e.preventDefault();
                handleDeleteMultiple();
              }}
              disabled={isConfirmLoading || countRowSelected <= 0}
            >
              {countRowSelected > 0 && (
                <span
                  className="position-absolute topbar-badge badge rounded-pill bg-danger"
                  style={{ transform: "translate(0%, -70%)" }}
                >
                  {formatNumberWithCommas(countRowSelected)}
                  <span className="visually-hidden">
                    total keywords selected
                  </span>
                </span>
              )}
              <i className="ri-delete-bin-3-fill align-bottom me-1"></i>{" "}
              {t("Button Delete")}
            </button>
          </div>
        </Col>
      </div>
      <CardBody className="pt-3">
        <div className="position-relative">
          <TableContainer
            className="custom-header-css"
            divClass="table-responsive table-card"
            tableClass="align-middle table-bordered-dashed sticky-table-feed-media-wallpaper"
            theadClass="table-light text-muted"
            columns={columns}
            data={formatData?.length ? formatData : []}
            customPageSize={query.limit}
            customPageIndex={query.page - 1}
            totalRecords={posts?.total}
            customPageCount={Math.ceil(
              Number(posts?.total) / Number(query.limit)
            )}
            handleChangePage={handleChangePage}
            manualSorting={true}
            sorting={{
              sort_by: query.sort_by,
              order_by: query.order_by,
            }}
            handleChangeSorting={handleChangeSorting}
            isLoading={isPostLoading}
            isShowHeaderTableFixedTab={activeWallpaperTab}
          />
        </div>
        <ToastContainer closeButton={false} limit={1} />
      </CardBody>

      {itemToDelete && (
        <ModalConfirm
          header={t("Delete Wallpaper")}
          isOpen={itemToDelete}
          isLoading={isConfirmLoading}
          onClose={() => setItemToDelete(false)}
          onConfirm={() => handleActionDelete(itemToDelete?._id)}
        />
      )}

      {isOpen && (
        <CreateEditWallpaper
          setIsOpen={setIsOpen}
          item={itemToEdit}
          setItemToEdit={setItemToEdit}
          loadTableData={loadTableData}
          STATUS_FEED_OPTION_LANG={STATUS_FEED_OPTION_LANG}
          setLinkImage={setLinkImage}
          formatOptionGroup={formatOptionGroup}
          formatOptionArtist={formatOptionArtist}
        />
      )}

      <ModalPreview
        type={"IMAGE"}
        isOpen={!!linkImage}
        url={linkImage}
        onClose={() => onCloseImageClick()}
      />

      <ModalConfirm
        textButtonConfirm={
          typeStatusNews === "activated" ? t("Active") : t("Disable")
        }
        classButtonConfirm={typeStatusNews === "activated" ? "btn-success" : "btn-primary"}
        classIconButtonConfirm={
          typeStatusNews === "activated"
            ? "ri-checkbox-circle-line"
            : "ri-refresh-line"
        }
        header={typeStatusNews === "activated" ? t("Active") : t("Disable")}
        title={
          typeStatusNews === "activated"
            ? t("Do you want to active all the items!")
            : t("Do you want to disable all the items!")
        }
        content={typeStatusNews === "activated" ? t("After confirming the active action, the data will be activated and cannot be recovered. Do you want to proceed with the active action.") : t("After confirming the disable action, the data will be disabled and cannot be recovered. Do you want to proceed with the disable action.")}
        isShowIcon={false}
        isOpen={isOpenConfirmStatusNews}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmActionNewsClick}
        onConfirm={handleActionStatusNews}
      />

      <ModalConfirm
        textButtonConfirm={t("Button Delete")}
        classButtonConfirm="btn-danger"
        classIconButtonConfirm={"ri-delete-bin-3-fill"}
        header={t("Button Delete")}
        title={t("Do you want to delete all the items!")}
        content={t(
          "After confirming the delete action, the data will be deleted and cannot be recovered. Do you want to proceed with the delete action."
        )}
        isShowIcon={false}
        isOpen={isOpenConfirmDeleteMultiple}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmDeleteMultipleClick}
        onConfirm={handleActionDeleteMultiple}
      />

      <ModalConfirm
        textButtonConfirm={t("Button Update")}
        classButtonConfirm="btn-warning"
        classIconButtonConfirm={"ri-checkbox-circle-line"}
        header={t("Type")}
        title={t("Do you want to update type for all the items!")}
        content={t(
          "After confirming the update action, the data will be updated and cannot be recovered. Do you want to proceed with the update action."
        )}
        isShowIcon={false}
        isOpen={!!isOpenUpdateTypeWallpaper}
        isLoading={isConfirmLoading}
        onClose={onCloseConfirmUpdateTypeClick}
        onConfirm={handleActionUpdateTypeMultiple}
      />

      <Modal
        isOpen={!!isOpenCreateGroup}
        centered={true}
        size="xl"
        scrollable={false}
      >
        <ModalHeader toggle={onCloseCreateGroupModal}>
          <>{t("Button Create Group")}</>
        </ModalHeader>
        <ModalBody>
          <GroupForm
            isModal={true}
            id={String(itemToEdit?._id || "")}
            isCopy={!!itemToEdit?.isCopy}
            // triggerRefresh={triggerRefresh}
            LIST_OPTION_TYPE={LIST_OPTION_TYPE}
            setLinkImage={setLinkImage}
            isCloseModal={() => onCloseCreateArtistSubmitModal("group")}
            createGroupAutoAssignGroup={createGroupAutoAssignGroup}
            rowEditId={rowEditId}
            rowSelected={rowSelected}
          />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={!!isOpenCreateArtist}
        centered={true}
        size="xl"
        scrollable={false}
      >
        <ModalHeader toggle={onCloseCreateArtistModal}>
          <>{t("Button Create Artist")}</>
        </ModalHeader>
        <ModalBody>
          <ArtistForm
            isModal={true}
            id={String(itemToEdit?._id || "")}
            isCopy={!!itemToEdit?.isCopy}
            listCategory={listGroupCreate}
            LIST_OPTION_TYPE={LIST_OPTION_TYPE}
            setLinkImage={setLinkImage}
            isCloseModal={onCloseCreateArtistSubmitModal}
            isGroupMultiple={false}
            createArtistAndAutoAssignGroup={createArtistAndAutoAssignGroup}
            rowEditId={rowEditId}
            rowSelected={rowSelected}
            isWallpaperPage={true}
          />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default WallpaperList;
