import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import FeedbackApi from "api/feedbackApi";
import { formatQueryParams } from "helpers/format";

export const getFeedbackApi = async (params: any = {}) => {
  try {
    console.log(params);
    const response = await FeedbackApi.feedbacks(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const getFeedbacks = createAsyncThunk("Feedbacks", getFeedbackApi);
