import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/notification-settings";
const PushNotiApi = {
  pushNotis(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

  updatePushNoti(id: any, params: any) {
    return api.put(`${path}/${id}`, params);
  },
};

export default PushNotiApi;
