import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/assets";
const AssetApi = {
  
  generateThumbnail(params: any) {
    return api.post(`${path}/generate-thumbnail`, params);
  },

};


export default AssetApi;
