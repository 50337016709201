import React, { ReactNode, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Accordion,
    AccordionItem,
    Collapse,
} from "reactstrap";
import classnames from 'classnames';

interface CollapseFilterProps {
    children: ReactNode;
    style?: any
}

const CollapseFilter: React.FC<CollapseFilterProps> = ({
    children,
    style = {},
}) => {
    const { t } = useTranslation();
    const [collapseFilterGroup, setCollapseFilterGroup] = useState<boolean>(true);
    const [collapseFilter, setCollapseFilter] = useState<any>('collapseFilter') 

    return (
        <Accordion id="default-accordion-example" open={collapseFilter} className="accordion-fill-white">
            <AccordionItem className="filter-border-lg-0">
                <h2 className="accordion-header d-block d-md-none" >
                    <button
                        className={`${classnames("accordion-button", { collapsed: !collapseFilterGroup })}`} type="button" onClick={() => setCollapseFilterGroup(!collapseFilterGroup)} style={{ cursor: "pointer" }} >
                        {t('Filter')}
                    </button>
                </h2>
                
                <Collapse isOpen={collapseFilterGroup} className="accordion-collapse px-md-0 px-2 pb-3 pb-md-0 d-md-block" id="collapseFilter" >
                    {children}
                </Collapse>
            </AccordionItem>
        </Accordion>
    );
};

export default CollapseFilter;
