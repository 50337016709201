import { createSlice } from "@reduxjs/toolkit";
import { getArtistsTDI } from "./thunk";

export interface IState {
  artistsTDI: any;
  isArtistTDILoading: boolean;
  isArtistTDISuccess: boolean;

  error: any;
}

export const initialState: IState = {
  artistsTDI: null,
  isArtistTDILoading: false,
  isArtistTDISuccess: false,

  error: {},
};

const ArtistSlice = createSlice({
  name: "ArtistTDI",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArtistsTDI.pending, (state: IState, action: any) => {
      state.isArtistTDILoading = true;
    });
    builder.addCase(getArtistsTDI.fulfilled, (state: IState, action: any) => {
      state.artistsTDI = action.payload.data;
      state.isArtistTDISuccess = true;
      state.isArtistTDILoading = false;
    });
    builder.addCase(getArtistsTDI.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isArtistTDISuccess = false;
      state.isArtistTDILoading = false;
    });
  },
});

export default ArtistSlice.reducer;
