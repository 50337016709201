import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { AxiosResponse } from "axios";
import { IUser } from "./types/_user";
const api = new APIClient();

const path = "/artists";
const artistApi = {
  artists(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

  getArtist(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
  
  deleteArtist(id: any) {
    return api.delete(`${path}/${id}`);
  },
  
  postArtist(dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },

  putArtist(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },

  relatedPhotos(params: any): Promise<any> {
    const url = `/articles/related-photos`;
    return api.get(url, params);
  },
  relatedVideos(params: any): Promise<any> {
    const url = `/articles/related-videos`;
    return api.get(url, params);
  },
};

export default artistApi;
