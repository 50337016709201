import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import ArtistApi from "api/artistApi";
import { formatQueryParams } from "helpers/format";

export const getArtists = createAsyncThunk("Artists", async (params: any = {}) => {
  try {
    const response = await ArtistApi.artists(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getOptionAllArtist = async (params: any = {}) => {
  try{
    const response = await ArtistApi.artists(params)
    return response;
  }catch (error) {
    return error;
  }
}

export const getArtist =  async(id: string | number ,  params: any = {}) => {
  try{
    const response = await ArtistApi.getArtist(id, params)
    return response;
  }catch (error) {
    return error;
  }
};

export const postArtist = async (data: any = {}) => {
  try{
    const response = await ArtistApi.postArtist(data);
    return response;
  }catch (error) {
    return error;
  }
};

export const putArtist = async (id: string | number , data: any = {}) => {
  try{
    const response = await ArtistApi.putArtist(id, data);
    return response;
  }catch (error) {
    return error;
  }
};