import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/articles";
const PostApi = {
  posts(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

  getSummaryWallpaper(params?: any) {
    const url = `${path}/summary`;
    return api.get(url, params);
  },

  createPost(params: any) {
    return api.post(path, params);
  },

  updatePost(id: any, params: any) {
    return api.put(`${path}/${id}`, params);
  },

  deletePost(id: any) {
    return api.delete(`${path}/${id}`);
  },

  updateStatusPost(params: any) {
    return api.patch(`${path}/update-status`, params);
  },

  updateTypeMultiplePost(params: any) {
    return api.patch(`${path}/update-type`, params);
  },


  deleteMultiplePost(params: any) {
    return api.delete(`${path}/bulk-delete`, params);
  },

  updateSearchKeywordPost(params: any) {
    return api.post(`${path}/update-search-keywords-id`, params);
  },


};

export function uploadFiles(files: any) {
  const url = "/assets/direct";
  return api.post(url, files, {
    headers: { "Content-Type": "multipart/form-data" },
  });
}

export default PostApi;
