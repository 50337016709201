import { createSlice } from "@reduxjs/toolkit";
import { getUserNotiPermissions } from "./thunk";

export interface IState {
  userNotiPermissions: any;
  isUserNotiPermissionLoading: boolean;
  isUserNotiPermissionSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  userNotiPermissions: null,
  isUserNotiPermissionLoading: false,
  isUserNotiPermissionSuccess: false,

  error: {},
};

const UserNotiPermissionSlice = createSlice({
  name: "UserNotiPermission",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get UserNotiPermissions
    builder.addCase(
      getUserNotiPermissions.pending,
      (state: IState, action: any) => {
        state.isUserNotiPermissionLoading = true;
      }
    );
    builder.addCase(
      getUserNotiPermissions.fulfilled,
      (state: IState, action: any) => {
        state.userNotiPermissions = action.payload.data;
        state.isUserNotiPermissionSuccess = true;
        state.isUserNotiPermissionLoading = false;
      }
    );
    builder.addCase(
      getUserNotiPermissions.rejected,
      (state: IState, action: any) => {
        state.error = action.payload.error || null;
        state.isUserNotiPermissionSuccess = false;
        state.isUserNotiPermissionLoading = false;
      }
    );
  },
});

export const UserNotiPermissionReducer = UserNotiPermissionSlice.reducer;
