import { createSlice } from "@reduxjs/toolkit";
import { getUserAdmins } from "./thunk";

export interface IState {
  admins: any;
  isAdminLoading: boolean;
  isAdminSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  admins: null,
  isAdminLoading: false,
  isAdminSuccess: false,

  error: {},
};

const UserAdminSlice = createSlice({
  name: "Admin",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getUserAdmins.pending, (state: IState, action: any) => {
      state.isAdminLoading = true;
    });
    builder.addCase(getUserAdmins.fulfilled, (state: IState, action: any) => {
      state.admins = action.payload.data;
      state.isAdminSuccess = true;
      state.isAdminLoading = false;
    });
    builder.addCase(getUserAdmins.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isAdminSuccess = false;
      state.isAdminLoading = false;
    });
  },
});

export default UserAdminSlice.reducer;
