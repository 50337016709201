import { createSlice } from "@reduxjs/toolkit";
import { getCountryStatistics, getDailyUserStatistics, getHotStatistics, getPhotocardNewsStatistics, getPushStatistics, getSnapStatistics, getSummaryStatistics, getWallpaperStatistics } from "./thunk";


export interface IState {
  dailyUserStatistics: any;
  isDailyUserStatisticLoading: boolean;
  isDailyUserStatisticSuccess: boolean;

  summaryStatistics: any;
  isSummaryStatisticLoading: boolean;
  isSummaryStatisticSuccess: boolean;

  hotStatistics: any;
  isHotStatisticLoading: boolean;
  isHotStatisticSuccess: boolean;


  wallpaperStatistics: any;
  isWallpaperStatisticLoading: boolean;
  isWallpaperStatisticSuccess: boolean;


  photocardNewsStatistics: any;
  isPhotocardNewsStatisticLoading: boolean;
  isPhotocardNewsStatisticSuccess: boolean;

  snapStatistics: any;
  isSnapStatisticLoading: boolean;
  isSnapStatisticSuccess: boolean;

  pushStatistics: any;
  isPushStatisticLoading: boolean;
  isPushStatisticSuccess: boolean;

  countryStatistics: any;
  isCountryStatisticLoading: boolean;
  isCountryStatisticSuccess: boolean;

  error: any,
};

export const initialState: IState = {
  dailyUserStatistics: null,
  isDailyUserStatisticLoading: false,
  isDailyUserStatisticSuccess: false,

  summaryStatistics: null,
  isSummaryStatisticLoading: false,
  isSummaryStatisticSuccess: false,

  hotStatistics: null,
  isHotStatisticLoading: false,
  isHotStatisticSuccess: false,


  wallpaperStatistics: null,
  isWallpaperStatisticLoading: false,
  isWallpaperStatisticSuccess: false,

  photocardNewsStatistics: null,
  isPhotocardNewsStatisticLoading: false,
  isPhotocardNewsStatisticSuccess: false,

  snapStatistics: null,
  isSnapStatisticLoading: false,
  isSnapStatisticSuccess: false,

  pushStatistics: null,
  isPushStatisticLoading: false,
  isPushStatisticSuccess: false,

  countryStatistics: null,
  isCountryStatisticLoading: false,
  isCountryStatisticSuccess: false,

  error: {},
};

const StatisticSlice = createSlice({
  name: "Statistic",
  initialState,
  reducers: {
    changeStatusCampaignsAction(state: any, action: any) {
      state.campaignStatistics = action.payload;
    },
    changeStatusFlowedKeywordAnalysisAction(state: any, action: any) {
      state.flowedKeywordAnalysis = action.payload;
    },
  },
  extraReducers: (builder) => {
    //Daily User Statistics
    builder.addCase(getDailyUserStatistics.pending, (state: IState, action: any) => {
      state.isDailyUserStatisticLoading = true;
    });
    builder.addCase(getDailyUserStatistics.fulfilled, (state: IState, action: any) => {
      state.dailyUserStatistics = action.payload.data;
      state.isDailyUserStatisticSuccess = true;
      state.isDailyUserStatisticLoading = false;
    });
    builder.addCase(getDailyUserStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isDailyUserStatisticSuccess = false;
      state.isDailyUserStatisticLoading = false;
    });

    //Summary Statistics
    builder.addCase(getSummaryStatistics.pending, (state: IState, action: any) => {
      state.isSummaryStatisticLoading = true;
    });
    builder.addCase(getSummaryStatistics.fulfilled, (state: IState, action: any) => {
      state.summaryStatistics = action.payload.data;
      state.isSummaryStatisticSuccess = true;
      state.isSummaryStatisticLoading = false;
    });
    builder.addCase(getSummaryStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSummaryStatisticSuccess = false;
      state.isSummaryStatisticLoading = false;
    });

    //Hot Statistics
    builder.addCase(getHotStatistics.pending, (state: IState, action: any) => {
      state.isHotStatisticLoading = true;
    });
    builder.addCase(getHotStatistics.fulfilled, (state: IState, action: any) => {
      state.hotStatistics = action.payload.data;
      state.isHotStatisticSuccess = true;
      state.isHotStatisticLoading = false;
    });
    builder.addCase(getHotStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isHotStatisticSuccess = false;
      state.isHotStatisticLoading = false;
    });

    //Wallpaper Statistics
    builder.addCase(getWallpaperStatistics.pending, (state: IState, action: any) => {
      state.isWallpaperStatisticLoading = true;
    });
    builder.addCase(getWallpaperStatistics.fulfilled, (state: IState, action: any) => {
      state.wallpaperStatistics = action.payload.data;
      state.isWallpaperStatisticSuccess = true;
      state.isWallpaperStatisticLoading = false;
    });
    builder.addCase(getWallpaperStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isWallpaperStatisticSuccess = false;
      state.isWallpaperStatisticLoading = false;
    });


    //PhotocardNews Statistics
    builder.addCase(getPhotocardNewsStatistics.pending, (state: IState, action: any) => {
      state.isPhotocardNewsStatisticLoading = true;
    });
    builder.addCase(getPhotocardNewsStatistics.fulfilled, (state: IState, action: any) => {
      state.photocardNewsStatistics = action.payload.data;
      state.isPhotocardNewsStatisticSuccess = true;
      state.isPhotocardNewsStatisticLoading = false;
    });
    builder.addCase(getPhotocardNewsStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPhotocardNewsStatisticSuccess = false;
      state.isPhotocardNewsStatisticLoading = false;
    });


    //Snap statistics
    builder.addCase(getSnapStatistics.pending, (state: IState, action: any) => {
      state.isSnapStatisticLoading = true;
    });
    builder.addCase(getSnapStatistics.fulfilled, (state: IState, action: any) => {
      state.snapStatistics = action.payload.data;
      state.isSnapStatisticSuccess = true;
      state.isSnapStatisticLoading = false;
    });
    builder.addCase(getSnapStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSnapStatisticSuccess = false;
      state.isSnapStatisticLoading = false;
    });


    //Push statistics
    builder.addCase(getPushStatistics.pending, (state: IState, action: any) => {
      state.isPushStatisticLoading = true;
    });
    builder.addCase(getPushStatistics.fulfilled, (state: IState, action: any) => {
      state.pushStatistics = action.payload.data;
      state.isPushStatisticSuccess = true;
      state.isPushStatisticLoading = false;
    });
    builder.addCase(getPushStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPushStatisticSuccess = false;
      state.isPushStatisticLoading = false;
    });


    //Country statistics
    builder.addCase(getCountryStatistics.pending, (state: IState, action: any) => {
      state.isCountryStatisticLoading = true;
    });
    builder.addCase(getCountryStatistics.fulfilled, (state: IState, action: any) => {
      state.countryStatistics = action.payload.data;
      state.isCountryStatisticSuccess = true;
      state.isCountryStatisticLoading = false;
    });
    builder.addCase(getCountryStatistics.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCountryStatisticSuccess = false;
      state.isCountryStatisticLoading = false;
    });
  },
});

export const {
  changeStatusCampaignsAction, changeStatusFlowedKeywordAnalysisAction
} = StatisticSlice.actions;

export default StatisticSlice.reducer;