import React, { ReactNode } from "react";
import { Option } from "api/types/_public";

interface ColumnStatusCheckProps {
    children?: ReactNode;
    item?: any;
    page?: number;
    handleCheck?: any;
    optionsSelected?: any
}

const ColumnStatusCheck: React.FC<ColumnStatusCheckProps> = ({
    children,
    item = {},
    page = 30,
    handleCheck,
    optionsSelected = []
}) => {

    const isChecked = (valueCheck: Option) => {
        const index = String(page);
        return !!(optionsSelected[index]?.find((x: any) => x.value === valueCheck?.value)?.value);
    };

    return (
        <>
            <div className="form-check" >
                <input className="form-check-input" type="checkbox" name="table" 
                    checked={isChecked({ value: item?._id, label: item?.keyword })} value={item?._id ?? ''} 
                    onChange={(e) => handleCheck(({ value: item?._id, label: item?.keyword }), page, e)} 
                    id={`cell-check-${item?._id ?? ''}`} />
                <label className="form-check-label" htmlFor={`cell-check-${item?._id ?? ''}`}></label>
            </div>
        </>
    );
};

export default ColumnStatusCheck;
