import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "./thunk";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { ICategory } from "api/types/_category";

export interface IState {
  categories: ResponseData<ICategory[]> & PaginationResponse | null,
  isCategoriesLoading: boolean,
  isCategoriesSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  categories: null,
  isCategoriesLoading: false,
  isCategoriesSuccess: false,

  error: {},
};

const GroupsSlice = createSlice({
  name: "group",
  initialState,
  reducers: {},
  extraReducers: (builder) => {

    //get Categories
    builder.addCase(getCategory.pending, (state: IState, action: any) => {
      state.isCategoriesLoading = true
    });
    builder.addCase(getCategory.fulfilled, (state: IState, action: any) => {
      state.categories = action.payload.data;
      state.isCategoriesSuccess = true;
      state.isCategoriesLoading = false;
    });
    builder.addCase(getCategory.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isCategoriesSuccess = false;
      state.isCategoriesLoading = false;
    });
  },
});

export default GroupsSlice.reducer;