import FeatherIcon from "feather-icons-react";
import React, { useCallback, useEffect, useRef, useState } from 'react';
import ModalVideo from 'react-modal-video';
import 'react-modal-video/css/modal-video.css';
import HashtagText from './HashtagText';
interface IURL {
    url: string,
    type: 'video' | 'image'
}
interface ModalMultiPreviewProps {
    isOpen: boolean;
    urls?: IURL[];
    content?: {
        title?: string,
        content?: string
    };
    onClose?: () => void;
}

const ModalMultiPreview: React.FC<ModalMultiPreviewProps> = ({ isOpen, content = null, urls, onClose }) => {

    const handleClose = useCallback(() => {
        if (onClose) {
            onClose();
        }
    }, []);

    const divRef = useRef<HTMLDivElement>(null);
    const elementRef = useRef<HTMLVideoElement | HTMLImageElement | any>(null);

    const [isShowMore, setIsShowMore] = useState<boolean>(true);
    const [position, setPosition] = useState({ left: 0, width: 0 });

    const [previews, setPreviews] = useState<IURL[]>([]);
    const [preview, setPreview] = useState<IURL | null>(null);
    const [index, setIndex] = useState(0);

    const handleShowMore = () => {
        setIsShowMore((prev) => !prev);
    };

    const updatePosition = () => {
        if (elementRef.current && divRef.current) {
            const parent = divRef.current;
            const rect = elementRef.current;
            setPosition((_prev) => ({
                left: (parent?.clientWidth - rect?.clientWidth) / 2,
                width: rect?.clientWidth || 0
            }));
            setIsShowMore((prev) => true);
        }
    };

    const handleElementLoad = useCallback(() => {
        updatePosition();
    }, []);


    const handleNextPreview = (typeNext: string) => {
        if (typeNext === 'PREV') {
            setIndex((prev) => (prev > 0 ? (prev - 1) : (Number(previews?.length || 0) - 1)));
        } else if (typeNext === 'NEXT') {
            setIndex((prev) => (prev < (Number(previews?.length || 0) - 1) ? (prev + 1) : 0));
        }
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === 'Escape') {
            handleClose();
            return;
        }
        if (event.key === 'ArrowLeft') {
            handleNextPreview('PREV');
            return;
        }
        if (event.key === 'ArrowRight') {
            handleNextPreview('NEXT');
            return;
        }
    };

    useEffect(() => {
        if (isOpen && divRef.current) {
            divRef.current.focus();
        }
    }, [isOpen, isShowMore, position]);


    useEffect(() => {
        if (previews?.length) {
            setPreview((_prev: any) => (previews?.[index || 0]));
        }
    }, [JSON.stringify(previews), index]);

    useEffect(() => {
        setPreviews((_prev) => (urls || [])?.map((item) => ({ url: item?.url, type: item?.type })));
        setPreview((_prev: any) => (urls?.[0] || null));
    }, [JSON.stringify(urls)]);

    useEffect(() => {
        return () => {
            setIndex((_prev) => 0);
            setPreviews((_prev) => []);
            setPreview((_prev: any) => null);
        }
    }, [isOpen]);

    return (
        <div className="modal-preview">
            <ModalVideo
                channel="custom"
                isOpen={isOpen}
                onClose={handleClose}
                url={preview?.url || ''}
                allowFullScreen={false}
                animationSpeed={300}
                classNames={{
                    modalVideoEffect: 'modal-video-effect',
                    modalVideo: 'modal-video',
                    modalVideoClose: 'modal-video-close',
                    modalVideoBody: 'modal-video-body',
                    modalVideoInner: 'modal-video-inner modal-video-inner-img',
                    modalVideoIframeWrap: 'modal-video-movie-wrap m-auto text-center overflow-hidden',
                    modalVideoCloseBtn: 'modal-video-close-btn  custom-video-close-btn',
                }}
                ratio="4:3"
                {...({
                    children: <div
                        ref={divRef}
                        onKeyDown={handleKeyDown}
                        tabIndex={0}
                        style={{
                            outline: 'none',
                            maxWidth: '820px',
                        }}
                    >
                        {
                            preview?.type === 'video' ? <video
                                controls
                                autoPlay
                                style={{
                                    objectFit: "cover",
                                    width: "auto",
                                    height: "100%",
                                    margin: 'auto',
                                    position: 'absolute',
                                    left: '50%',
                                    transform: 'translateX(-50%)',
                                    // maxWidth: '820px'
                                }}
                                ref={elementRef}
                                onLoadedData={handleElementLoad}
                                tabIndex={-1}
                            >
                                <source src={preview?.url || ''} type="video/mp4" />
                            </video>
                                : <img
                                    className="object-fit"
                                    style={{
                                        objectFit: "cover",
                                        width: "auto",
                                        height: "100%",
                                        margin: 'auto',
                                        position: 'absolute',
                                        left: '50%',
                                        transform: 'translateX(-50%)',
                                    }}
                                    src={preview?.url || ''}
                                    alt="preview"
                                    ref={elementRef}
                                    onLoad={handleElementLoad}
                                    tabIndex={-1}
                                />
                        }
                        {(position.width && !!content) && <div
                            style={{
                                position: 'absolute',
                                zIndex: '3',
                                left: position.left > 0 ? position.left : 0,
                                bottom: 0,
                                width: position.width,
                                maxWidth: '100%',
                                padding: '2px 10px',
                                backgroundColor: 'rgba(51, 51, 51, 0.6)',
                                color: '#fff',
                                backdropFilter: 'blur(2px)',
                            }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleShowMore();
                            }}
                            tabIndex={-1}
                        >
                            <div>
                                {isShowMore ? <FeatherIcon icon="chevrons-down" className="icon-dual" /> : <FeatherIcon icon="chevrons-up" className="icon-dual" />}
                            </div>
                            <div className={`hidden-scrollbar-os collapsible-show-more ${isShowMore ? 'open' : ''}`}>
                                {!!content?.title && <div className="text-start mb-2 mt-1" style={{ fontWeight: 600, fontSize: '1rem' }}>
                                    {content?.title || ''}
                                </div>}
                                <HashtagText text={content?.content || ''} />
                            </div>
                        </div>}

                        <div
                            style={{
                                position: 'absolute',
                                zIndex: '3',
                                left: 0,
                                top: '50%',
                                padding: '8px',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(234, 234, 234, 0.2)',
                                color: '#fff',
                                cursor: 'pointer'
                            }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleNextPreview('PREV');
                            }}
                        >
                            <FeatherIcon icon="chevron-left" className="icon-dual" />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: '3',
                                right: 0,
                                top: '50%',
                                padding: '8px',
                                transform: 'translateY(-50%)',
                                backgroundColor: 'rgba(234, 234, 234, 0.2)',
                                color: '#fff',
                                cursor: 'pointer'
                            }}
                            onClick={(e: any) => {
                                e.stopPropagation();
                                e.preventDefault();
                                handleNextPreview('NEXT');
                            }}
                        >
                            <FeatherIcon icon="chevron-right" className="icon-dual" />
                        </div>
                        <div
                            style={{
                                position: 'absolute',
                                zIndex: '3',
                                left: '50%',
                                top: 5,
                                transform: 'translateX(-50%)',
                                color: '#fff',
                                cursor: 'pointer',
                                fontSize: '15px',
                                padding: '0px 5px',
                                backgroundColor: 'rgba(234, 234, 234, 0.3)',
                                backdropFilter: 'blur(5px)',
                            }}
                        >
                            {Number(index) + 1}
                            /
                            {previews?.length}
                        </div>
                    </div>
                })}
            />
        </div>
    );
};

export default React.memo(ModalMultiPreview);
