import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import CopyTruncate from "./CopyTruncate";
import { truncate } from "utils";
import { Input } from "reactstrap";

interface TypesProps {
  isResetDeviceTokenInput: boolean;
  handleChangeDeviceToken?: (items: any) => void;
}

const TagDeviceToken = ({
  isResetDeviceTokenInput = false,
  handleChangeDeviceToken,
}: TypesProps) => {
  const { t } = useTranslation();

  const [inputValue, setInputValue] = useState('');
  const [itemsSelected, setItemsSelected] = useState<string[]>([]);

  const handleInputChange = (event: any) => {
    setInputValue(event?.target?.value)
  }

  const handleInputKeyDown = (event: any) => {
    const value = event?.target?.value?.trim();

    const checkDuplicate = itemsSelected?.filter((item: any) => item === value);

    if(event?.keyCode === 13 || event?.keyCode === 188) {
      event.preventDefault();

      if(checkDuplicate?.length > 0 || !value) {
        return;
      }

      setItemsSelected([...itemsSelected, value])
      setInputValue('')
    }

    if(itemsSelected?.length && event?.keyCode === 8 && !inputValue?.length) {
      setItemsSelected(
        itemsSelected?.slice(0, itemsSelected?.length - 1)
      )
    }
  }

  const handleInputBlur = (event: any) => {
    const value = event?.target?.value?.trim();

    if(value) {
      setItemsSelected([...itemsSelected, value])
      setInputValue('')
    }
  }

  const handleRemoveItem = (index: any) => {
    setItemsSelected(
      itemsSelected?.filter((item: any, i: number) => i !== index)
    )
  }

  useEffect(() => {
    handleChangeDeviceToken && handleChangeDeviceToken(itemsSelected)
  }, [itemsSelected])

  useEffect(() => {
    if(isResetDeviceTokenInput === true) {
      setItemsSelected([])
    }
  }, [isResetDeviceTokenInput])
  
  return (
    <React.Fragment>
      <>
        <Input
          name="deviceToken"
          type="text"
          className="form-control"
          placeholder={t("Enter your device token")}
          value={inputValue}
          onChange={(event: any) => handleInputChange(event)}
          onKeyDown={(event: any) => handleInputKeyDown(event)}
          onBlur={(event: any) => handleInputBlur(event)}
        />

        <div style={{marginTop: "5px", display: "flex", gap: "5px", flexWrap: "wrap"}}>
          {
            itemsSelected?.map((item: any, i: number) => {
              return <span key={i} className="d-flex gap-1" style={{backgroundColor: "#009ef7", color: "white", padding: "0px 5px 1px 3px", width: "max-content", borderRadius: '4px'}}>
                    <CopyTruncate
                      contentCopy={item}
                      colorIcon="text-white"
                    >
                      {truncate(item, 30)}
                    </CopyTruncate>

                    <span onClick={() => handleRemoveItem(i)} style={{fontSize: "15px", paddingInline: "6px", cursor: "pointer"}}>×</span>
                </span>
            })
          }
        </div>
      </>
       
    </React.Fragment>
  );
};

export default TagDeviceToken;