const countries = [
    { code: "AD", name: "Andorra", name_korea: "안도라" },
    { code: "AE", name: "United Arab Emirates", name_korea: "아랍에미리트" },
    { code: "AF", name: "Afghanistan", name_korea: "아프가니스탄" },
    { code: "AG", name: "Antigua and Barbuda", name_korea: "앤티가 바부다" },
    { code: "AI", name: "Anguilla", name_korea: "앵귈라" },
    { code: "AL", name: "Albania", name_korea: "알바니아" },
    { code: "AM", name: "Armenia", name_korea: "아르메니아" },
    { code: "AO", name: "Angola", name_korea: "앙골라" },
    { code: "AR", name: "Argentina", name_korea: "아르헨티나" },
    { code: "AS", name: "American Samoa", name_korea: "미국령 사모아" },
    { code: "AT", name: "Austria", name_korea: "오스트리아" },
    { code: "AU", name: "Australia", name_korea: "호주" },
    { code: "AW", name: "Aruba", name_korea: "아루바" },
    { code: "AX", name: "Åland Islands", name_korea: "올란드 제도" },
    { code: "AZ", name: "Azerbaijan", name_korea: "아제르바이잔" },
    { code: "BA", name: "Bosnia and Herzegovina", name_korea: "보스니아 헤르체고비나" },
    { code: "BB", name: "Barbados", name_korea: "바베이도스" },
    { code: "BD", name: "Bangladesh", name_korea: "방글라데시" },
    { code: "BE", name: "Belgium", name_korea: "벨기에" },
    { code: "BF", name: "Burkina Faso", name_korea: "부르키나파소" },
    { code: "BG", name: "Bulgaria", name_korea: "불가리아" },
    { code: "BH", name: "Bahrain", name_korea: "바레인" },
    { code: "BI", name: "Burundi", name_korea: "부룬디" },
    { code: "BJ", name: "Benin", name_korea: "베냉" },
    { code: "BL", name: "Saint Barthélemy", name_korea: "생바르텔레미" },
    { code: "BM", name: "Bermuda", name_korea: "버뮤다" },
    { code: "BN", name: "Brunei Darussalam", name_korea: "브루나이" },
    { code: "BO", name: "Bolivia", name_korea: "볼리비아" },
    { code: "BQ", name: "Bonaire, Sint Eustatius and Saba", name_korea: "보네르" },
    { code: "BR", name: "Brazil", name_korea: "브라질" },
    { code: "BS", name: "Bahamas", name_korea: "바하마" },
    { code: "BT", name: "Bhutan", name_korea: "부탄" },
    { code: "BV", name: "Bouvet Island", name_korea: "부베 섬" },
    { code: "BW", name: "Botswana", name_korea: "보츠와나" },
    { code: "BY", name: "Belarus", name_korea: "벨라루스" },
    { code: "BZ", name: "Belize", name_korea: "벨리즈" },
    { code: "CA", name: "Canada", name_korea: "캐나다" },
    { code: "CC", name: "Cocos (Keeling) Islands", name_korea: "코코스 제도" },
    { code: "CD", name: "Congo, Democratic Republic of the", name_korea: "콩고 민주 공화국" },
    { code: "CF", name: "Central African Republic", name_korea: "중앙 아프리카 공화국" },
    { code: "CG", name: "Congo", name_korea: "콩고" },
    { code: "CH", name: "Switzerland", name_korea: "스위스" },
    { code: "CI", name: "Côte d'Ivoire", name_korea: "코트디부아르" },
    { code: "CK", name: "Cook Islands", name_korea: "쿡 제도" },
    { code: "CL", name: "Chile", name_korea: "칠레" },
    { code: "CM", name: "Cameroon", name_korea: "카메룬" },
    { code: "CN", name: "China", name_korea: "중국" },
    { code: "CO", name: "Colombia", name_korea: "콜롬비아" },
    { code: "CR", name: "Costa Rica", name_korea: "코스타리카" },
    { code: "CU", name: "Cuba", name_korea: "쿠바" },
    { code: "CV", name: "Cabo Verde", name_korea: "카보베르데" },
    { code: "CW", name: "Curaçao", name_korea: "큐라소" },
    { code: "CX", name: "Christmas Island", name_korea: "크리스마스 섬" },
    { code: "CY", name: "Cyprus", name_korea: "키프로스" },
    { code: "CZ", name: "Czechia", name_korea: "체코" },
    { code: "DE", name: "Germany", name_korea: "독일" },
    { code: "DJ", name: "Djibouti", name_korea: "지부티" },
    { code: "DK", name: "Denmark", name_korea: "덴마크" },
    { code: "DM", name: "Dominica", name_korea: "도미니카" },
    { code: "DO", name: "Dominican Republic", name_korea: "도미니카 공화국" },
    { code: "DZ", name: "Algeria", name_korea: "알제리" },
    { code: "EC", name: "Ecuador", name_korea: "에콰도르" },
    { code: "EE", name: "Estonia", name_korea: "에스토니아" },
    { code: "EG", name: "Egypt", name_korea: "이집트" },
    { code: "EH", name: "Western Sahara", name_korea: "서사하라" },
    { code: "ER", name: "Eritrea", name_korea: "에리트레아" },
    { code: "ES", name: "Spain", name_korea: "스페인" },
    { code: "ET", name: "Ethiopia", name_korea: "에티오피아" },
    { code: "FI", name: "Finland", name_korea: "핀란드" },
    { code: "FJ", name: "Fiji", name_korea: "피지" },
    { code: "FM", name: "Micronesia (Federated States of)", name_korea: "미크로네시아" },
    { code: "FO", name: "Faroe Islands", name_korea: "페로 제도" },
    { code: "FR", name: "France", name_korea: "프랑스" },
    { code: "GA", name: "Gabon", name_korea: "가봉" },
    { code: "GB", name: "United Kingdom", name_korea: "영국" },
    { code: "GD", name: "Grenada", name_korea: "그레나다" },
    { code: "GE", name: "Georgia", name_korea: "조지아" },
    { code: "GF", name: "French Guiana", name_korea: "프랑스 기아나" },
    { code: "GG", name: "Guernsey", name_korea: "건지" },
    { code: "GH", name: "Ghana", name_korea: "가나" },
    { code: "GI", name: "Gibraltar", name_korea: "지브롤터" },
    { code: "GL", name: "Greenland", name_korea: "그린란드" },
    { code: "GM", name: "Gambia", name_korea: "감비아" },
    { code: "GN", name: "Guinea", name_korea: "기니" },
    { code: "GP", name: "Guadeloupe", name_korea: "과들루프" },
    { code: "GQ", name: "Equatorial Guinea", name_korea: "적도 기니" },
    { code: "GR", name: "Greece", name_korea: "그리스" },
    { code: "GT", name: "Guatemala", name_korea: "과테말라" },
    { code: "GU", name: "Guam", name_korea: "괌" },
    { code: "GW", name: "Guinea-Bissau", name_korea: "기니비사우" },
    { code: "GY", name: "Guyana", name_korea: "가이아나" },
    { code: "HK", name: "Hong Kong", name_korea: "홍콩" },
    { code: "HM", name: "Heard Island and McDonald Islands", name_korea: "허드 섬과 맥도널드 제도" },
    { code: "HN", name: "Honduras", name_korea: "온두라스" },
    { code: "HR", name: "Croatia", name_korea: "크로아티아" },
    { code: "HT", name: "Haiti", name_korea: "아이티" },
    { code: "HU", name: "Hungary", name_korea: "헝가리" },
    { code: "ID", name: "Indonesia", name_korea: "인도네시아" },
    { code: "IE", name: "Ireland", name_korea: "아일랜드" },
    { code: "IL", name: "Israel", name_korea: "이스라엘" },
    { code: "IM", name: "Isle of Man", name_korea: "맨 섬" },
    { code: "IN", name: "India", name_korea: "인도" },
    { code: "IO", name: "British Indian Ocean Territory", name_korea: "영국 인도양 지역" },
    { code: "IQ", name: "Iraq", name_korea: "이라크" },
    { code: "IR", name: "Iran", name_korea: "이란" },
    { code: "IS", name: "Iceland", name_korea: "아이슬란드" },
    { code: "IT", name: "Italy", name_korea: "이탈리아" },
    { code: "JE", name: "Jersey", name_korea: "저지" },
    { code: "JM", name: "Jamaica", name_korea: "자메이카" },
    { code: "JO", name: "Jordan", name_korea: "요르단" },
    { code: "JP", name: "Japan", name_korea: "일본" },
    { code: "KE", name: "Kenya", name_korea: "케냐" },
    { code: "KG", name: "Kyrgyzstan", name_korea: "키르기스스탄" },
    { code: "KH", name: "Cambodia", name_korea: "캄보디아" },
    { code: "KI", name: "Kiribati", name_korea: "키리바시" },
    { code: "KM", name: "Comoros", name_korea: "코모로" },
    { code: "KN", name: "Saint Kitts and Nevis", name_korea: "세인트키츠 네비스" },
    { code: "KP", name: "Korea", name_korea: "북한" },
    { code: "KR", name: "Korea", name_korea: "대한민국" },
    { code: "KW", name: "Kuwait", name_korea: "쿠웨이트" },
    { code: "KY", name: "Cayman Islands", name_korea: "케이맨 제도" },
    { code: "KZ", name: "Kazakhstan", name_korea: "카자흐스탄" },
    { code: "LA", name: "Lao People's Democratic Republic", name_korea: "라오스" },
    { code: "LB", name: "Lebanon", name_korea: "레바논" },
    { code: "LC", name: "Saint Lucia", name_korea: "세인트루시아" },
    { code: "LI", name: "Liechtenstein", name_korea: "리히텐슈타인" },
    { code: "LK", name: "Sri Lanka", name_korea: "스리랑카" },
    { code: "LR", name: "Liberia", name_korea: "라이베리아" },
    { code: "LS", name: "Lesotho", name_korea: "레소토" },
    { code: "LT", name: "Lithuania", name_korea: "리투아니아" },
    { code: "LU", name: "Luxembourg", name_korea: "룩셈부르크" },
    { code: "LV", name: "Latvia", name_korea: "라트비아" },
    { code: "LY", name: "Libya", name_korea: "리비아" },
    { code: "MA", name: "Morocco", name_korea: "모로코" },
    { code: "MC", name: "Monaco", name_korea: "모나코" },
    { code: "MD", name: "Moldova", name_korea: "몰도바" },
    { code: "ME", name: "Montenegro", name_korea: "몬테네그로" },
    { code: "MF", name: "Saint Martin", name_korea: "생마르탱" },
    { code: "MG", name: "Madagascar", name_korea: "마다가스카르" },
    { code: "MH", name: "Marshall Islands", name_korea: "마샬 제도" },
    { code: "MK", name: "North Macedonia", name_korea: "북마케도니아" },
    { code: "ML", name: "Mali", name_korea: "말리" },
    { code: "MM", name: "Myanmar", name_korea: "미얀마" },
    { code: "MN", name: "Mongolia", name_korea: "몽골" },
    { code: "MO", name: "Macao", name_korea: "마카오" },
    { code: "MP", name: "Northern Mariana Islands", name_korea: "북마리아나 제도" },
    { code: "MQ", name: "Martinique", name_korea: "마르티니크" },
    { code: "MR", name: "Mauritania", name_korea: "모리타니" },
    { code: "MS", name: "Montserrat", name_korea: "몬세랫" },
    { code: "MT", name: "Malta", name_korea: "몰타" },
    { code: "MU", name: "Mauritius", name_korea: "모리셔스" },
    { code: "MV", name: "Maldives", name_korea: "몰디브" },
    { code: "MW", name: "Malawi", name_korea: "말라위" },
    { code: "MX", name: "Mexico", name_korea: "멕시코" },
    { code: "MY", name: "Malaysia", name_korea: "말레이시아" },
    { code: "MZ", name: "Mozambique", name_korea: "모잠비크" },
    { code: "NA", name: "Namibia", name_korea: "나미비아" },
    { code: "NC", name: "New Caledonia", name_korea: "뉴칼레도니아" },
    { code: "NE", name: "Niger", name_korea: "니제르" },
    { code: "NF", name: "Norfolk Island", name_korea: "노퍽섬" },
    { code: "NG", name: "Nigeria", name_korea: "나이지리아" },
    { code: "NI", name: "Nicaragua", name_korea: "니카라과" },
    { code: "NL", name: "Netherlands", name_korea: "네덜란드" },
    { code: "NO", name: "Norway", name_korea: "노르웨이" },
    { code: "NP", name: "Nepal", name_korea: "네팔" },
    { code: "NR", name: "Nauru", name_korea: "나우루" },
    { code: "NU", name: "Niue", name_korea: "니우에" },
    { code: "NZ", name: "New Zealand", name_korea: "뉴질랜드" },
    { code: "OM", name: "Oman", name_korea: "오만" },
    { code: "PA", name: "Panama", name_korea: "파나마" },
    { code: "PE", name: "Peru", name_korea: "페루" },
    { code: "PF", name: "French Polynesia", name_korea: "프랑스 폴리네시아" },
    { code: "PG", name: "Papua New Guinea", name_korea: "파푸아뉴기니" },
    { code: "PH", name: "Philippines", name_korea: "필리핀" },
    { code: "PK", name: "Pakistan", name_korea: "파키스탄" },
    { code: "PL", name: "Poland", name_korea: "폴란드" },
    { code: "PM", name: "Saint Pierre and Miquelon", name_korea: "생피에르 미클롱" },
    { code: "PN", name: "Pitcairn", name_korea: "핏케언" },
    { code: "PR", name: "Puerto Rico", name_korea: "푸에르토리코" },
    { code: "PT", name: "Portugal", name_korea: "포르투갈" },
    { code: "PW", name: "Palau", name_korea: "팔라우" },
    { code: "PY", name: "Paraguay", name_korea: "파라과이" },
    { code: "QA", name: "Qatar", name_korea: "카타르" },
    { code: "RE", name: "Réunion", name_korea: "레위니옹" },
    { code: "RO", name: "Romania", name_korea: "루마니아" },
    { code: "RS", name: "Serbia", name_korea: "세르비아" },
    { code: "RU", name: "Russia", name_korea: "러시아" },
    { code: "RW", name: "Rwanda", name_korea: "르완다" },
    { code: "SA", name: "Saudi Arabia", name_korea: "사우디아라비아" },
    { code: "SB", name: "Solomon Islands", name_korea: "솔로몬 제도" },
    { code: "SC", name: "Seychelles", name_korea: "세이셸" },
    { code: "SD", name: "Sudan", name_korea: "수단" },
    { code: "SE", name: "Sweden", name_korea: "스웨덴" },
    { code: "SG", name: "Singapore", name_korea: "싱가포르" },
    { code: "SH", name: "Saint Helena, Ascension and Tristan da Cunha", name_korea: "세인트헬레나" },
    { code: "SI", name: "Slovenia", name_korea: "슬로베니아" },
    { code: "SJ", name: "Svalbard and Jan Mayen", name_korea: "스발바르 제도" },
    { code: "SK", name: "Slovakia", name_korea: "슬로바키아" },
    { code: "SL", name: "Sierra Leone", name_korea: "시에라리온" },
    { code: "SM", name: "San Marino", name_korea: "산마리노" },
    { code: "SN", name: "Senegal", name_korea: "세네갈" },
    { code: "SO", name: "Somalia", name_korea: "소말리아" },
    { code: "SR", name: "Suriname", name_korea: "수리남" },
    { code: "SS", name: "South Sudan", name_korea: "남수단" },
    { code: "ST", name: "Sao Tome and Principe", name_korea: "상투메 프린시페" },
    { code: "SV", name: "El Salvador", name_korea: "엘살바도르" },
    { code: "SX", name: "Sint Maarten", name_korea: "신트마르턴" },
    { code: "SY", name: "Syrian Arab Republic", name_korea: "시리아" },
    { code: "SZ", name: "Eswatini", name_korea: "에스와티니" },
    { code: "TC", name: "Turks and Caicos Islands", name_korea: "터크스 케이코스 제도" },
    { code: "TD", name: "Chad", name_korea: "차드" },
    { code: "TF", name: "French Southern Territories", name_korea: "프랑스 남부 지역" },
    { code: "TG", name: "Togo", name_korea: "토고" },
    { code: "TH", name: "Thailand", name_korea: "태국" },
    { code: "TJ", name: "Tajikistan", name_korea: "타지키스탄" },
    { code: "TK", name: "Tokelau", name_korea: "토켈라우" },
    { code: "TL", name: "Timor-Leste", name_korea: "동티모르" },
    { code: "TM", name: "Turkmenistan", name_korea: "투르크메니스탄" },
    { code: "TN", name: "Tunisia", name_korea: "튀니지" },
    { code: "TO", name: "Tonga", name_korea: "통가" },
    { code: "TR", name: "Turkey", name_korea: "터키" },
    { code: "TT", name: "Trinidad and Tobago", name_korea: "트리니다드 토바고" },
    { code: "TV", name: "Tuvalu", name_korea: "투발루" },
    { code: "TZ", name: "Tanzania", name_korea: "탄자니아" },
    { code: "UA", name: "Ukraine", name_korea: "우크라이나" },
    { code: "UG", name: "Uganda", name_korea: "우간다" },
    { code: "UM", name: "United States Minor Outlying Islands", name_korea: "미국의 외부 소속 섬" },
    { code: "UN", name: "United Nations", name_korea: "유엔" },
    { code: "US", name: "United States of America", name_korea: "미국" },
    { code: "UY", name: "Uruguay", name_korea: "우루과이" },
    { code: "UZ", name: "Uzbekistan", name_korea: "우즈베키스탄" },
    { code: "VA", name: "Holy See", name_korea: "바티칸" },
    { code: "VC", name: "Saint Vincent and the Grenadines", name_korea: "세인트빈센트 그레나딘" },
    { code: "VE", name: "Venezuela", name_korea: "베네수엘라" },
    { code: "VG", name: "British Virgin Islands", name_korea: "영국령 버진 제도" },
    { code: "VI", name: "U.S. Virgin Islands", name_korea: "미국령 버진 제도" },
    { code: "VN", name: "Vietnam", name_korea: "베트남" },
    { code: "VU", name: "Vanuatu", name_korea: "바누아투" },
    { code: "WF", name: "Wallis and Futuna", name_korea: "왈리스 퓌튀나" },
    { code: "WS", name: "Samoa", name_korea: "사모아" },
    { code: "YE", name: "Yemen", name_korea: "예멘" },
    { code: "YT", name: "Mayotte", name_korea: "마요트" },
    { code: "ZA", name: "South Africa", name_korea: "남아프리카 공화국" },
    { code: "ZM", name: "Zambia", name_korea: "잠비아" },
    { code: "ZW", name: "Zimbabwe", name_korea: "짐바브웨" },
    { code: "TW", name: "Taiwan", name_korea: "타이완" },
]
  
const countriesFormat = countries.map(country => {
    return {
        ...country,
        flags: require(`../assets/images/flags/${country.code.toLowerCase()}.svg`)
    };
});

export default countriesFormat;