import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import ArtistTDIApi from "api/artistTDIApi";
import { formatQueryParams } from "helpers/format";

export const getArtistsTDI = createAsyncThunk("ArtistsTDI", async (params: any = {}) => {
  try {
    const response = await ArtistTDIApi.artistTDI(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getArtist =  async(params: any = {}) => {
  try{
    const response = await ArtistTDIApi.artistTDI(params)
    return response;
  }catch (error) {
    return error;
  }
};
