import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import NO_IMAGE from '../../assets/images/no-image-icon.png'
import artistApi from "api/artistApi";
import { getOptionAllCategories } from "store/thunks";
import i18n from "i18n";
interface Option {
  label: string | any;
  value: string;
}
interface DropdownOptionProps {
  name?: string,
  initialValue?: Option | undefined | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean,
  className?: string,
  classNamePrefix?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
  labelTop?: string,
  isClearable?: boolean,
}

const DropdownGroup = ({
  name = '',
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = '',
  classNamePrefix = '',
  isHasOptionAll = false,
  optionAll,
  labelTop = '',
  isClearable = false,
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };


  // useEffect(() => {
  //   const handleCallAllOption = async () => {
  //     try {
  //       const [resGroup]: any = await Promise.all([getOptionAllCategories({})]);
  //       setOptions((_prev: any) => resGroup?.data?.items || []);
  //     } catch (error: any) {

  //       return error;
  //     }
  //   };

  //   handleCallAllOption();
  // }, []);

  useEffect(() => {
    const resultOptions = dataList?.map((a: any) => ({
      value: String(a?._id),
      label: (
        <div className="d-flex align-items-center gap-2">
          <div style={{ height: "22px", width: "22px", overflow: "hidden", borderRadius: "15px", objectFit: "cover", backgroundColor: "#EAEAEA", minWidth: "22px", minHeight: "22px" }} >
            <img src={a?.thumbnail || a?.image || NO_IMAGE} alt={a?.title?.ko} height={"100%"} width={22} className="me-2" style={{ overflow: "hidden", borderRadius: "50%", objectFit: "cover", backgroundColor: "#EAEAEA" }} />
          </div>
          {`${a?.title?.ko} - ${a?.title?.en}`}
        </div>
      ),
      data: a,
    })) || [] as Option[];
    const result: any = isHasOptionAll ? [optionAll].concat(resultOptions) : resultOptions;
    setOptions((_prev: any) => result);

    if (initialValue && initialValue?.value) {
      const val = result?.filter((e: any) => e.value === initialValue?.value)[0];
      setOnChangeOption(val);
    } else {
      setOnChangeOption(isHasOptionAll ? result[0] : null);
    }
  }, [JSON.stringify(dataList), JSON.stringify(initialValue), i18n?.language]);

  const filterOption = (option: any, inputValue: any) => {
    const plainLabel = `${option.data?.data?.title?.ko} - ${option.data?.data?.title?.en}`
    return plainLabel?.toLowerCase()?.includes(inputValue.toLowerCase());
  };

  return (
    <div className="position-relative">
      <Select
        options={options}
        name="groupId"
        value={initialValue}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ''}</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={isClearable}
        filterOption={filterOption}

      />
    </div>
  );
};

export default DropdownGroup;
