import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import Select from "react-select";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Input, Label, Progress, Row, Spinner } from "reactstrap";
import {
  getCategoryById,
  postCategory,
  putCategory
} from "store/thunks";
import * as Yup from "yup";
import IconDelete from "../../../assets/images/icon-delete.png"

//import images
import { UploadOutlined } from "@ant-design/icons";
import { Button, Upload, UploadProps } from "antd";
import { uploadFiles } from "api/postApi";
import { useRole } from "components/Hooks/UserHooks";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";

export interface Tag {
  id: string;
  text: string;
}

export interface Props {
  isModal?: boolean;
  id?: string;
  isCopy?: boolean;
  triggerRefresh?: () => void;
  LIST_OPTION_TYPE: any;
  setLinkImage?: any;
}

registerLocale("en", en);
registerLocale("ko", ko);

const LIST_OPTION_STATUS = [
  {
    label: "Status_Activated",
    value: "activated",
  },
  {
    label: "Status_Disabled",
    value: "disabled",
  },
];

const CategoryForm = ({
  isModal = false,
  id = "",
  isCopy = false,
  triggerRefresh,
  LIST_OPTION_TYPE,
  setLinkImage
}: Props) => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isLoadingDetail, setIsLoadingDetail] = useState<boolean>(false);
  const [isLoadingUploadImage, setIsLoadingUploadImage] = useState<boolean>(false);

  const [initialValuesDefault, setInitialValuesDefault] = useState<any>(null);

  const LIST_OPTION_TYPE_LANG = LIST_OPTION_TYPE?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const LIST_OPTION_STATUS_LANG = LIST_OPTION_STATUS?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const props: UploadProps = {
    name: "files",
    beforeUpload: () => false,
    headers: {
      authorization: "authorization-text",
    },
  };

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        title: {
          ko: values?.title.ko,
          en: values?.title.en,
        },
        type: values?.type?.value,
        image: values?.image,
        status: values?.status?.value,
        subhead:
          values?.subhead?.ko & values?.subhead?.en ? {
            ko: values?.subhead?.ko,
            en: values?.subhead?.en,
          } : null,
        description:
          values?.description.ko & values?.description?.en ? {
            ko: values?.description?.ko,
            en: values?.description?.en,
          }
            : null,
      };

      const response: any = id
        ? await putCategory(id, data)
        : await postCategory(data);
      if (response?.data) {
        setIsLoading((_prev) => false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
        triggerRefresh && triggerRefresh();
      } else {
        setIsLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const validationSchema = Yup.object({
    title: Yup.object().shape({
      en: Yup.string().required(`${t("This field is required")}`),
      ko: Yup.string().required(`${t("This field is required")}`),
    }),
    type: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("Type is required")}`),
    image: Yup.string().nullable(),
    status: Yup.object()
      .shape({
        label: Yup.string(),
        value: Yup.string(),
      })
      .required(`${t("Status is required")}`),
    subhead: Yup.object({
      en: Yup.string().nullable(),
      ko: Yup.string().nullable(),
    }),
    description: Yup.object({
      en: Yup.string().nullable(),
      ko: Yup.string().nullable(),
    }),
  });

  const formik = useFormik({
    initialValues: {
      title: {
        ko: "",
        en: ""
      },
      type: LIST_OPTION_TYPE_LANG[0],
      image: "",
      status: LIST_OPTION_STATUS_LANG[0],
      subhead: {
        ko: "",
        en: ""
      },
      description: {
        ko: "",
        en: ""
      },
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  const handleSetValueForm = (valueDefault: any) => {
    const vTitleKo = valueDefault?.title?.ko || "";
    const vTitleEn = valueDefault?.title?.en || "";
    const vSubheadKo = valueDefault?.subhead?.ko || "";
    const vSubheadEn = valueDefault?.subhead?.en || "";
    const vDescriptionKo = valueDefault?.description?.ko || "";
    const vDescriptionEn = valueDefault?.description?.en || "";
    const vType =
      LIST_OPTION_TYPE_LANG?.filter(
        (item: any) => String(valueDefault?.type) == String(item?.value)
      )[0] || LIST_OPTION_TYPE_LANG[0];
    const vImage = valueDefault?.image || "";
    const vStatus =
      LIST_OPTION_STATUS_LANG?.filter(
        (item: any) => String(valueDefault?.status) == String(item?.value)
      )[0] || LIST_OPTION_STATUS_LANG[0];

    formik.setFieldValue("type", vType);
    formik.setFieldValue("image", vImage);
    formik.setFieldValue("status", vStatus);
    formik.setFieldValue("subhead.en", vSubheadEn);
    formik.setFieldValue("subhead.ko", vSubheadKo);
    formik.setFieldValue("description.en", vDescriptionEn);
    formik.setFieldValue("description.ko", vDescriptionKo);
    if (valueDefault?.title) {
      setTimeout(() => {
        formik.setFieldValue("title", { en: vTitleEn, ko: vTitleKo });
      }, 300);
    }
  };

  const handleCallAllOption = async (idItem: string) => {
    try {
      // if (!idItem) {
      //   return;
      // }
      setIsLoadingDetail((_prev) => true);
      const [resDetail]: any = await Promise.all([
        idItem ? getCategoryById(idItem) : {},
      ]);
      setIsLoadingDetail((_prev) => false);
      handleSetValueForm(resDetail?.data);

    } catch (error: any) {
      setIsLoadingDetail((_prev) => false);
      return error;
    }
  };

  useEffect(() => {
    handleCallAllOption(id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  useEffect(() => {
    document.title = `${t("User Create")} - ${t("User")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);



  return (
    <React.Fragment>
      {isLoadingDetail && (
        <div
          style={{
            position: "absolute",
            zIndex: 3,
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
            backgroundColor: "rgb(164 164 164 / 36%)",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Spinner size="sm" color="primary"></Spinner>
        </div>
      )}
      <div>
        <Container fluid className="px-0">
          <div className="mb-4">
            <Progress value={100 - (100 / 4) * Number(Object.keys(formik.errors).length)} color="primary" className="animated-progress progress-sm" />
          </div>
          <form onSubmit={formik.handleSubmit}>
            <Row>
              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="titleInput" className="form-label">
                    {t("Name")} <span className="text-danger">*</span>
                  </Label>
                  <Input
                    name="title.en"
                    type="text"
                    className="form-control"
                    id="titleInput"
                    placeholder={t("Enter your title")}
                    value={formik?.values?.title?.en}
                    onChange={(event: any) =>
                      formik.setFieldValue("title.en", event?.target?.value)
                    }
                  />
                  {formik?.touched?.title?.en && formik?.errors?.title?.en ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.title?.en}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="titleKoreaInput" className="form-label">
                    {t("Name Korea")} <span className="text-danger"> *</span>
                  </Label>
                  <Input
                    name="title.ko"
                    type="text"
                    className="form-control"
                    id="titleKoreaInput"
                    placeholder={t("Enter your title (korea)")}
                    value={formik?.values?.title?.ko}
                    onChange={(event: any) =>
                      formik.setFieldValue("title.ko", event?.target?.value)
                    }
                  />
                  {formik?.touched?.title?.ko && formik?.errors?.title?.ko ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.title?.ko}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="typeInput" className="form-label">
                    {t("Type")} <span className="text-danger"> *</span>
                  </Label>

                  <Select
                    name="type"
                    className="mb-0 dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    placeholder={t("Type")}
                    value={formik?.values?.type || LIST_OPTION_TYPE_LANG[0]}
                    onChange={(event: any) =>
                      formik.setFieldValue("type", event)
                    }
                    options={LIST_OPTION_TYPE_LANG}
                  ></Select>
                  {/* {formik?.touched?.type && formik?.errors?.type ? (
                    <div className="text-danger mt-2">{formik?.errors?.type}</div>
                  ) : null} */}
                </div>
              </Col>

              <Col lg={6}>
                <div className="mb-3">
                  <Label htmlFor="statusInput" className="form-label">
                    {t("Status")} <span className="text-danger"> *</span>
                  </Label>
                  <Select
                    name="type"
                    className="mb-0 dropdown-status-rounded"
                    classNamePrefix="name-prefix"
                    placeholder={t("Status")}
                    value={formik?.values?.status || LIST_OPTION_STATUS_LANG[0]}
                    onChange={(event: any) =>
                      formik.setFieldValue("status", event)
                    }
                    options={LIST_OPTION_STATUS_LANG}
                  ></Select>
                  {formik?.touched?.status && formik?.errors?.status ? (
                    <div className="text-danger mt-2">
                      {/* {formik?.errors?.status} */}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="imageInput" className="form-label">
                    {t("Image")}
                  </Label>

                  <div>
                    {formik.getFieldProps("image")?.value && (
                      <div
                        style={{
                          marginBottom: 10,
                          marginTop: 10,

                        }}
                      >
                        <div style={{ position: "relative", display: "inline-block" }}>
                          <img
                            alt="category"
                            src={formik.getFieldProps("image")?.value}
                            style={{ maxHeight: 120, maxWidth: 160 }}
                            onClick={() => setLinkImage(formik.getFieldProps("image")?.value)}
                          />

                          <span
                            onClick={() => formik.setFieldValue("image", '')}
                            style={{ color: "red", position: "absolute", top: "-14px", right: "-8px", cursor: "pointer" }}>
                            <img src={IconDelete} alt="delete" width={18} height={18} />
                          </span>
                        </div>
                      </div>
                    )}

                    <div>
                      {(isLoadingUploadImage) && (
                        <Spinner size="sm" className="me-2" style={{ color: "#4da3da" }}></Spinner>
                      )
                      }
                    </div>

                    <Upload
                      {...props}
                      multiple={false}
                      beforeUpload={() => false}
                      //   listType="picture-card"
                      // fileList={(formik.getFieldProps("files")?.value as any) || []}
                      fileList={[]}
                      onChange={async (info: any) => {
                        setIsLoadingUploadImage(true)
                        try {
                          const cloneInfo = info?.fileList?.length
                            ? [...info?.fileList]
                            : [];

                          const files = info.fileList;
                          const data = new FormData();
                          for (var i = 0; i < files?.length; i++) {
                            data.append("files", files[i]?.originFileObj);
                          }

                          const res = (await uploadFiles(data)) as any;
                          if (res?.success) {
                            setIsLoadingUploadImage(false)
                            const fortmatedData = res?.data?.map(
                              (i: any, index: number) => {
                                return {
                                  type: "image",
                                  url: i?.url,
                                  preview: i?.url,
                                  elements: [],
                                  caption: "Category Image",
                                  mimetype: "image/*",
                                  width: 1080,
                                  height: 1080,
                                  ratio: 1.1,
                                  seq: 0,
                                  ...i,
                                };
                              }
                            );
                            formik.setFieldValue("image", fortmatedData?.[0]?.url);
                          } else {
                            setIsLoadingUploadImage(false)
                          }
                        } catch (error: any) {
                          setIsLoadingUploadImage(false)
                          toast(`${t('Input buffer contains unsupported image format')}`, CONFIG_OPTION_TOAST_ERROR);
                        }

                      }}
                      onRemove={() => {
                        //return onGalleryFileRemove();
                      }}
                    >
                      <Button icon={<UploadOutlined />}>
                        {t("Click to Upload")}
                      </Button>
                    </Upload>
                  </div>
                  {formik?.touched?.image && formik?.errors?.image ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.image}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="subheadInput" className="form-label">
                    {t("Subhead")}
                  </Label>
                  <Input
                    name="subhead.en"
                    type="text"
                    className="form-control"
                    id="subheadInput"
                    placeholder={t("Enter your subhead")}
                    value={formik?.values?.subhead?.en}
                    onChange={(event: any) =>
                      formik.setFieldValue("subhead.en", event?.target?.value)
                    }
                  />
                  {formik?.touched?.subhead?.en && formik?.touched?.subhead?.en ? (
                    <div className="text-danger mt-2">
                      {formik?.touched?.subhead?.en}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3">
                  <Label htmlFor="subheadInputKorea" className="form-label">
                    {t("Subhead (Korea)")}
                  </Label>
                  <Input
                    name="subhead.ko"
                    type="text"
                    className="form-control"
                    id="subheadInputKorea"
                    placeholder={t("Enter your subhead (Korea)")}
                    value={formik?.values?.subhead?.ko}
                    onChange={(event: any) =>
                      formik.setFieldValue("subhead.ko", event?.target?.value)
                    }
                  />
                  {formik?.touched?.subhead?.ko && formik?.touched?.subhead?.ko ? (
                    <div className="text-danger mt-2">
                      {formik?.touched?.subhead?.ko}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3 pb-2">
                  <Label
                    htmlFor="descriptionTextarea"
                    className="form-label"
                  >
                    {t("Description")}
                  </Label>
                  <textarea
                    name="description.en"
                    className="form-control"
                    id="descriptionTextarea"
                    placeholder={t("Enter your description")}
                    rows={3}
                    value={formik?.values?.description?.en}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "description.en",
                        event?.target?.value
                      )
                    }
                  />
                  {formik?.touched?.description?.en &&
                    formik?.errors?.description?.en ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.description?.en}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="mb-3 pb-2">
                  <Label
                    htmlFor="descriptionKoreaTextarea"
                    className="form-label"
                  >
                    {t("Description (Korea)")}
                  </Label>
                  <textarea
                    name="description.ko"
                    className="form-control"
                    id="descriptionKoreaTextarea"
                    placeholder={t("Enter your description (Korea)")}
                    rows={3}
                    value={formik?.values?.description?.ko}
                    onChange={(event: any) =>
                      formik.setFieldValue(
                        "description.ko",
                        event?.target?.value
                      )
                    }
                  />
                  {formik?.touched?.description?.ko &&
                    formik?.errors?.description?.ko ? (
                    <div className="text-danger mt-2">
                      {formik?.errors?.description?.ko}
                    </div>
                  ) : null}
                </div>
              </Col>

              <Col lg={12}>
                <div className="hstack gap-2 justify-content-end">
                  {isHavePermissionRole(
                    ROLES_FOR_APP.USER_UPDATE,
                    userPermissions
                  ) && (
                      // <button
                      //   type="submit"
                      //   className="btn btn-primary fs-14"
                      //   disabled={
                      //     formik.errors && Object.keys(formik.errors).length > 0
                      //       ? true
                      //       : false
                      //   }
                      // >
                      //   {isLoading ? (
                      //     <Spinner size="sm me-2"></Spinner>
                      //   ) : !!id ? (
                      //     <i className="ri-login-circle-line align-bottom me-2 "></i>
                      //   ) : (
                      //     <i className="ri-add-fill align-bottom me-2"></i>
                      //   )}
                      //   {id ? t("Button Update Group") : t("Button Create Group")}
                      // </button>

                      <button
                        className="btn btn-primary fs-14"
                        color="success"
                        type="submit"
                        disabled={isLoading}
                        style={{ width: '140px' }}
                      >
                        {isLoading ? (
                          <Spinner size="sm" className="me-2"></Spinner>
                        ) :
                          <>{id ? t("Button Update Group") : t("Button Create Group")}</>
                        }
                      </button>
                    )}
                  {/* <button className="btn btn-soft-secondary fs-14" type="button" color="light" onClick={onCloseClick} disabled={isLoading}>
                        <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                        {t('Button Close')}
                    </button> */}
                </div>
              </Col>
            </Row>
          </form>
        </Container>
      </div>
      <ToastContainer closeButton={false} limit={1} />
    </React.Fragment>
  );
};

export default CategoryForm;
