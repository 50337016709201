import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";

interface Option {
  label: string | any;
  value: string;
}
interface DropdownOptionProps {
  name?: string,
  initialValue?: string | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean,
  className?: string,
  classNamePrefix?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
  labelTop?: string,
  isClearable?: boolean,
}

const DropdownGroupNew = ({
  name = '',
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = '',
  classNamePrefix = '',
  isHasOptionAll = false,
  optionAll,
  labelTop = '',
  isClearable = false,
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  const filterOption = (option: any, inputValue:any) => {
    const plainLabel = `${option.data?.data?.title?.ko} - ${option.data?.data?.title?.en}`
    return plainLabel?.toLowerCase()?.includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    const result: any = isHasOptionAll ? [optionAll].concat(dataList) : dataList;
    setOptions((_prev: any) => result);
  }, [dataList]);

  return (
    <div className="position-relative" style={{width: "100%"}}>
      <Select
        options={options}
        name="groupId"
        value={options.find(item => item.value === initialValue) || (isHasOptionAll ? options[0] : null)}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ''}</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={isClearable}
        filterOption={filterOption}
      />
    </div>
  );
};

export default DropdownGroupNew;
