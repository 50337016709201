import { TYPE_ARTIST_OPTION } from "helpers/constans";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import NO_IMAGE from '../../assets/images/no-image-icon.png'
import countries from "helpers/countries";
import i18n from "i18next";

interface Option {
  label: string | any;
  value: string;
}
interface DropdownOptionProps {
  name?: string,
  initialValue?: Option | undefined | null;
  dataList?: any[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean,
  className?: string,
  classNamePrefix?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
  labelTop?: string,
  isClearable?: boolean,
}

const DropdownCountries = ({
  name = '',
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = '',
  classNamePrefix = '',
  isHasOptionAll = false,
  optionAll,
  labelTop = '',
  isClearable = false,
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  useEffect(() => {
    const countriesFilter = dataList.map((el) => {
      var dataNew = {...el};
      countries.filter((f:any) =>  {
        if(f.code === el.code) {
          dataNew = {...el, flags: f?.flags, name_korea: f?.name_korea, name: f?.name}
        }
        return f.code === el.code
      });
      return dataNew
    });

    const resultOptions = countriesFilter?.map((a: any) => {
      return ({
        value: String(a?._id),
        label: (
          <div className="d-flex align-items-center gap-2">
            <div style={{ height: '22px', width: '22px', overflow: 'hidden', borderRadius: '15px', objectFit: 'cover', backgroundColor: '#eaeaea', zIndex: 99 }} >
              <img src={a?.flags || NO_IMAGE} alt={a?.name} height={22} width={22}  style={{minWidth: '22px', overflow: 'hidden', borderRadius: '50%', objectFit: 'cover', backgroundColor: '#eaeaea' }} />
            </div>
            {i18n?.language === 'ko' ? a?.name_korea : a?.name}
          </div>
        ),
        data: a
      })}
    ) || [] as Option[];
    const result: any = isHasOptionAll ? [optionAll].concat(resultOptions) : resultOptions;
    setOptions((_prev: any) => result);

    if (initialValue && initialValue?.value) {
      const val = result?.filter((e: any) => String(e.value) === String(initialValue?.value))[0];
      setOnChangeOption(val);
    } else {
      setOnChangeOption(isHasOptionAll ? result[0] : null);
    }
  }, [JSON.stringify(dataList), JSON.stringify(initialValue), i18n?.language]);

  const filterOption = (option: any, inputValue:any) => {
    const plainLabel = `${option.data?.data?.name_korea} ${option.data?.data?.name}`
    return plainLabel?.toLowerCase()?.includes(inputValue.toLowerCase());
  };

  return (
    <div className="position-relative">
      <Select
        options={options}
        name="artistId"
        value={initialValue}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ''}</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={isClearable}
        filterOption={filterOption}
      />
    </div>
  );
};

export default DropdownCountries;
