import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/tdi-artist-groups";
const groupTDIApi = {
  groupTDI(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
};


export default groupTDIApi;
