import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/tdi-artists";
const ArtistTDI = {
  artistTDI(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },
};


export default ArtistTDI;
