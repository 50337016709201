import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import { default as AdvertisementApi, default as advertisementApi } from "api/advertisementApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import { useRole } from "components/Hooks/UserHooks";
import { COLOR_POSITION_ADVERTISEMENT, COLOR_STATUS_ADVERTISEMENT, COLOR_TYPE_ADVERTISEMENT, ENUM_STATUS_ADVERTISEMENT, POSITION_ADVERTISEMENT_OPTION, POSITION_DESCRIPTION_ADVERTISEMENT_OPTION, STATUS_ADVERTISEMENT_OPTION, TYPE_ADVERTISEMENT_OPTION } from "helpers/constans";
import CountUp from "react-countup";
import { getAdvertisements } from "store/advertisement/thunk";
import { formatDateStringToKorea, formatNumberWithCommas } from "helpers/format";
import TooltipCustom from "components/Common/TooltipCustom";
import { Link } from "react-router-dom";
import AdvertisementView from "./AdvertisementView";
import CollapseFilter from "components/Common/CollapseFilter";
import ModalPreview from "components/Common/ModalPreview";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const AdvertisementList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const POSITION_ADVERTISEMENT_OPTION_LANG = POSITION_DESCRIPTION_ADVERTISEMENT_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const TYPE_ADVERTISEMENT_OPTION_LANG = TYPE_ADVERTISEMENT_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];


  const STATUS_ADVERTISEMENT_LANG = STATUS_ADVERTISEMENT_OPTION?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];


  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    keyword: withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[position]": withDefault(StringParam, ""),
    type: withDefault(StringParam, ""),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [linkImage, setLinkImage] = useState<any>(null);

  const [itemToEdit, setItemToEdit] = useState<any>(false);

  const [keywordSearch, setKeywordSearch] = useState<string>(query['condition[keyword]'] || "");
  const [statusSearch, setStatusSearch] = useState<Option | null>(STATUS_ADVERTISEMENT_LANG?.find((item) => String(item?.value) === String(query['condition[status]'])) || null);
  const [positionSearch, setPositionSearch] = useState<Option | null>(POSITION_ADVERTISEMENT_OPTION_LANG?.find((item) => String(item?.value) === String(query['condition[position]'])) || null);


  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.Advertisement;

  const AdvertisementProperties = createSelector(
    selectLayoutState,
    (state) => ({
      advertisements: state.advertisements,
      isAdvertisementLoading: state.isAdvertisementLoading,
      isAdvertisementSuccess: state.isAdvertisementSuccess,
      error: state.error,
    })
  );

  // Inside your component
  const { advertisements, isAdvertisementLoading } = useSelector(
    AdvertisementProperties
  );

  function loadTableData() {
    dispatch(getAdvertisements(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || '',
      'condition[status]': statusSearch?.value || '',
      'condition[position]': positionSearch?.value || '',
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        keyword: '',
        'condition[status]': '',
        'condition[position]': '',
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setPositionSearch((_prev) => null);
    setStatusSearch((_prev) => null);
  };


  // Begin::Change status
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);

  const handleOnConfirmUpdate = async (item: { id: number | string, status: string | boolean }) => {
    try {
      if (!item) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await advertisementApi.updateAdvertisement(item?.id, { status: item?.status });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        dispatch(getAdvertisements(query));
        toast(`${t('The process has been completed.')}`, CONFIG_OPTION_TOAST_NORMAL);
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ''}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  }
  // End::Change status

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 90,
        Cell: ({ ...props }) => {
          return (
            <div style={{ minWidth: '60px' }}>
              {advertisements?.total - ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Active"),
        accessor: "status",
        thWidth: 140,
        thClass: 'text-center  whitespace-nowrap',
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div className="form-check form-switch form-switch-sm text-center" dir="ltr">
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                id="customSwitchsizelg"
                checked={!!item?.status}
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleOnConfirmUpdate({ id: item?._id, status: !!value });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t('Status'),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 120,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center" style={{ minWidth: '100px' }}>
              <span className={`rounded-pill badge bg-${COLOR_STATUS_ADVERTISEMENT[status] || 'secondary'}`}>{STATUS_ADVERTISEMENT_LANG?.find((item: any) => String(item?.value) === String(status))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t('Position'),
        accessor: "position_description",
        filterable: false,
        sortable: false,
        thWidth: 140,
        thClass: '',
        Cell: (cell: any) => {
          const position = cell?.row?.original?.position_desciption;
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: '180px' }}>{i18n?.language == 'ko' ? cell?.value?.ko : cell?.value?.en}</div>
            </>
          )
        },
      },
      {
        Header: t('Type'),
        accessor: "type",
        filterable: false,
        sortable: false,
        thWidth: 140,
        thClass: 'text-center',
        Cell: (cell: any) => {
          const type = cell?.value;
          return (
            <div className="text-center" style={{ minWidth: '120px' }}>
              <span className={`rounded-pill badge bg-${COLOR_TYPE_ADVERTISEMENT[type] || 'secondary'}`}>{TYPE_ADVERTISEMENT_OPTION_LANG?.find((item: any) => String(item?.value) === String(type))?.label}</span>
            </div>
          )
        },
      },
      {
        Header: t("Description"),
        accessor: "description",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        thWidth: 180,
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: '180px' }}>{i18n?.language == 'ko' ? cell?.value?.ko : cell?.value?.en}</div>
            </>
          );
        },
      },
      // {
      //   Header: t('Demo'),
      //   accessor: "demo",
      //   filterable: false,
      //   sortable: false,
      //   thWidth: 140,
      //   thClass: 'text-center',
      //   Cell: (cell: any) => {
      //     const url = cell?.value;
      //     return url && (
      //       <>
      //         <div className="text-center cursor-pointer link-primary text-decoration-underline" style={{ minWidth: '120px' }}
      //           onClick={() => setLinkImage({ media_url: url, media_type: "video" })}
      //         >
      //           {t('View Demo')}
      //         </div>
      //       </>
      //     )
      //   },
      // },
      {
        Header: t("Number Show"),
        accessor: "show",
        filterable: true,
        sortable: false,
        thWidth: 140,
        thClass: 'text-center',
        description: t('Ads are displayed after the content has been used a specified number of times.'),
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer text-center" style={{ minWidth: '120px' }}>{formatNumberWithCommas(cell?.value)}</div>
            </>
          );
        },
      },
      {
        Header: t("Android Ad"),
        accessor: "adsId_android",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer">{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("IOS Ad"),
        accessor: "adsId_ios",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer">{cell?.value}</div>
            </>
          );
        },
      },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              {cell?.value ? <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span> : '-'}
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value)
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: '120px' }}>
              {cell?.value ? <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span> : '-'}
            </div>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 120,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
              style={{ width: "100px", justifyContent: "center" }}
            >
              <TooltipCustom
                title={t("View Detail")}
                id={`view-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`view-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-primary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(true);
                      setItemToEdit(item);
                    }}
                  >
                    <i className="ri-eye-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, advertisements?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  // Begin::Delete

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await AdvertisementApi?.deleteAdvertisement(id);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // End::Delete

  // Form

  // useEffect(() => {
  //   handleCallAllOption();
  // }, []);

  const onCloseImageClick = () => {
    setLinkImage(null);
  };
  
  useEffect(() => {
    document.title = `${t("POSTS")} - ${t("POSTS")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb
            title={t("Advertisement Management")}
            pageTitle={t("Home")}
          />
          <Row>
            <Col lg={12}>
              <Card id="customerList" style={{ boxShadow: '0px 3px 4px 0px rgba(0, 0, 0, 0.03)' }}>
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t('Total')}:</span>
                          <CountUp
                            start={0}
                            end={advertisements?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-0 mt-md-2">
                      <Col sm={12} md={3} xl={3} xxl={3} className="mt-3 mt-md-2">
                        <LabelWrapper
                          label={t("Ads Id")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Ads Id")}...`}
                            value={keywordSearch}
                            onChange={(e) =>
                              setKeywordSearch(e.target.value)
                            }
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Position')} isShow={true}>
                          <DropdownStatus
                            name="position"
                            dataList={POSITION_ADVERTISEMENT_OPTION_LANG || []}
                            placeholder={`${t("Position")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={positionSearch || null}
                            onChangeSelect={(e: any) => setPositionSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Filter'), value: '' }}
                            colors={COLOR_POSITION_ADVERTISEMENT}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col sm={12} md={3} xl={3} xxl={3} className='mt-3 mt-md-2'>
                        <LabelWrapper label={t('Status')} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_ADVERTISEMENT_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t('All Filter'), value: '' }}
                            colors={COLOR_STATUS_ADVERTISEMENT}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12} md={3} xl={3} xxl={3}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-sm-4 mt-md-2"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={
                        advertisements?.items?.length
                          ? advertisements?.items
                          : []
                      }
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={advertisements?.total}
                      customPageCount={Math.ceil(
                        Number(advertisements?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isAdvertisementLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isOpen && (
          <AdvertisementView
            setIsOpen={setIsOpen}
            id={itemToEdit?._id}
            setItemToEdit={setItemToEdit}
            loadTableData={loadTableData}
          />
        )}

        {itemToDelete && (
          <ModalConfirm
            header={t("Delete Advertisement")}
            isOpen={itemToDelete}
            isLoading={isConfirmLoading}
            onClose={() => setItemToDelete(false)}
            onConfirm={() => handleActionDelete(itemToDelete?._id)}
          />
        )}

        <ModalPreview
          type={String(linkImage?.media_type) === "video" ? "VIDEO" : "IMAGE"}
          isOpen={!!linkImage}
          url={String(linkImage?.media_type) === "video" ? linkImage?.media_url : linkImage?.thumbnail_url}
          onClose={() => onCloseImageClick()}
        />
      </div>
    </React.Fragment>
  );
};

export default AdvertisementList;
