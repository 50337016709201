import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import PushNotiApi from "api/pushNotiApi";
import { formatQueryParams } from "helpers/format";

export const getPushNotiApi = async (params: any = {}) => {
  try {
    const response = await PushNotiApi.pushNotis(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
};

export const getPushNotis = createAsyncThunk("PushNotis", getPushNotiApi);
