import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import LabelWrapper from "components/Common/LabelWrapper";
import CountUp from "react-countup";
import { getFeedbacks } from "store/feedback/thunk";
import DropdownOption from "components/Common/DropdownOption";
import {
  COLOR_STATUS_USER,
  LIST_OPTION_GENDER,
  LIST_OPTION_OS,
  LIST_OPTION_PLATFORM,
  STATUS_USER_FEEDBACK_OPTION,
  STATUS_USER_OPTION,
  TYPE_USER_FEEDBACK_OPTION_CONSTANT,
  getPlatFormImage,
} from "helpers/constans";
import DropdownStatus from "components/Common/DropdownStatus";
import avatar1 from "../../../assets/images/users/ic_defaul_avatar2.png";
import DropdownCountries from "components/Common/DropdownCountries";
import DatePickerCustom from "components/Common/DatePickerCustom";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter } from "utils";
import DropdownPlatform from "components/Common/DropdownPlatform";
import CollapseFilter from "components/Common/CollapseFilter";
import { formatDateStringToKorea } from "helpers/format";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const FeedbackList = () => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const { state } = useLocation();

  const TYPE_FEEDBACK_OPTION_LANG =
    TYPE_USER_FEEDBACK_OPTION_CONSTANT?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const STATUS_FEEDBACK_OPTION_LANG =
    STATUS_USER_FEEDBACK_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    // order_by: withDefault(StringParam, `DESC`),
    // date_version: withDefault(StringParam, ''),
    content: withDefault(StringParam, ""),
    email: withDefault(StringParam, state?.email),
    nickname: withDefault(StringParam, ""),
    "condition[type]": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    platform: withDefault(StringParam, state?.platform),
    flatform: withDefault(StringParam, state?.platform),
    type: withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const [nicknameSearch, setNicknameSearch] = useState<string>(
    query?.nickname || ""
  );
  const [emailSearch, setEmailSearch] = useState<string>(query?.email || "");
  const [feedbackTypeSearch, setFeedbackTypeSearch] = useState<Option | null>(
    TYPE_FEEDBACK_OPTION_LANG?.filter(
      (item) => String(item?.value) === String(query?.feedbackType)
    )[0]
  );
  const [linkImage, setLinkImage] = useState<string>("");

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.ipAddress || ""
  );

  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_FEEDBACK_OPTION_LANG?.find(
      (item) => String(item?.value) === String(query["condition[status]"])
    ) || null
  );

  const LIST_OPTION_PLATFORM_LANG =
    LIST_OPTION_PLATFORM?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [typesSearch, setTypesSearch] = useState<Option[]>([]);
  const [platFormSearch, setPlatFormSearch] = useState<Option | null>(
    LIST_OPTION_PLATFORM_LANG?.filter(
      (item) =>
        String(item?.value) === String(query?.platform && state?.platform)
    )[0]
  );

  const selectLayoutState = (state: any) => state.Feedback;

  const FeedbackProperties = createSelector(selectLayoutState, (state) => ({
    feedbacks: state.feedbacks,
    isFeedbackLoading: state.isFeedbackLoading,
    isFeedbackSuccess: state.isFeedbackSuccess,

    error: state.error,
  }));

  // Inside your component
  const { feedbacks, isFeedbackLoading } = useSelector(FeedbackProperties);
  console.log(feedbacks);

  function loadTableData() {
    dispatch(getFeedbacks(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      content: keywordSearch || "",
      nickname: nicknameSearch || "",
      email: emailSearch || "",
      "condition[type]": feedbackTypeSearch?.value || "",
      "condition[status]": statusSearch?.value || "",
      platform: platFormSearch?.value || "",
      flatform: platFormSearch?.value || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const resetData = () => {
    const queryNew = {
      content: "",
      nickname: "",
      email: "",
      "condition[type]": "",
      "condition[status]": "",
      platform: "",
      startDate: "",
      endDate: "",
      sort_by: "",
      time_request: +new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setNicknameSearch((_prev) => "");
    setTypesSearch((_prev) => []);
    setStatusSearch((_prev) => null);
    setFeedbackTypeSearch((_prev) => null);
    setEmailSearch((_prev) => "");
    setKeywordSearch((_prev) => "");
    setPlatFormSearch((_prev) => null);
    setDateSearch(["", ""]);
  };

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        thClass: "whitespace-nowrap",
        Cell: ({ ...props }) => {
          if (!Number(feedbacks?.total)) return <></>;

          return (
            <div>
              {Number(feedbacks?.total) -
                ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Avatar"),
        accessor: "avatar",
        thClass: "text-center whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const user = cell?.row?.original?.user;
          return (
            <>
              <div className="w-100 text-center " style={{ minWidth: "60px" }}>
                <div
                  style={{ width: "45px" }}
                  className="m-auto cursor-pointer"
                  onClick={() =>
                    setLinkImage((prev) => user?.avatar ?? avatar1)
                  }
                >
                  <img
                    src={user?.avatar ?? avatar1}
                    className="rounded-circle avatar-sm"
                    alt="thumbnail"
                    onError={({ currentTarget }) => {
                      currentTarget.onerror = null; // prevents looping
                      currentTarget.src = avatar1;
                    }}
                  />
                </div>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Nickname"),
        accessor: "nickname",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const user = cell?.row?.original?.user;

          return (
            <>
              <div className="cursor-pointer">{user?.nickname}</div>
            </>
          );
        },
      },
      {
        Header: t("Email"),
        accessor: "email",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const user = cell?.row?.original?.user;
          return (
            <>
              <div className="cursor-pointer">{user?.email}</div>
            </>
          );
        },
      },

      {
        Header: t("Platform"),
        accessor: "providers_platform",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          let items = cell?.row?.original?.user?.providers || [];

          if (items.length > 1) {
            items = items.filter((item: any) => item?.type !== "default");
          }

          return (
            items?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {items?.map((item: any, index: number) => {
                  const type = item?.type;
                  let imgPlatform = getPlatFormImage(type);
                  return (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 align-items-center whitespace-nowrap"
                    >
                      <div style={{ width: "25px" }}>
                        {imgPlatform && (
                          <img
                            src={getPlatFormImage(type)}
                            width={25}
                            height={25}
                            alt="platform"
                          />
                        )}
                      </div>
                      <div className="cursor-pointer">
                        {t(
                          capitalizeFirstLetter(
                            type == "default" ? "Email" : type
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          );
        },
      },

      {
        Header: t("Type"),
        accessor: "type",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const item = TYPE_FEEDBACK_OPTION_LANG.find(
            (i: any) => i?.value == cell?.value
          );

          console.log(cell?.value, TYPE_FEEDBACK_OPTION_LANG);
          if (item)
            return (
              <>
                <div className="cursor-pointer">{item.label}</div>
              </>
            );
        },
      },

      {
        Header: t("Content"),
        accessor: "content",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer">{cell.value}</div>
            </>
          );
        },
      },

      {
        Header: t("Status"),
        accessor: "status",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        Cell: (cell: any) => {
          const item = STATUS_FEEDBACK_OPTION_LANG.find(
            (i: any) => i?.value == cell?.value
          );
          if (item)
            return (
              <>
                <div className="cursor-pointer">{item.label}</div>
              </>
            );
        },
      },

      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ width: "100px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end whitespace-nowrap",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, feedbacks?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("Feedback")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Feedback History")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={feedbacks?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-0 mt-md-2">
                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Keyword")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Keyword")}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Nickname")}
                          isShow={!!nicknameSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Nickname")}...`}
                            value={nicknameSearch}
                            onChange={(e) => setNicknameSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Email")} isShow={!!emailSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Email")}...`}
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <div>
                          <LabelWrapper label={t("Type")} isShow={true}>
                            <DropdownOption
                              name="type"
                              dataList={TYPE_FEEDBACK_OPTION_LANG || []}
                              placeholder={`${t("Type")}...`}
                              className="dropdown-status-rounded"
                              classNamePrefix="name-prefix"
                              initialValue={feedbackTypeSearch || null}
                              onChangeSelect={(e: any) =>
                                setFeedbackTypeSearch(e)
                              }
                              isHasOptionAll={true}
                              optionAll={{
                                label: t("All"),
                                value: "",
                              }}
                            />
                          </LabelWrapper>
                        </div>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("Status")} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_FEEDBACK_OPTION_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={{
                              ["received"]: "warning",
                              ["pending"]: "danger",
                              ["in_progress"]: "primary",
                              ["completed"]: "success",
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3"
                      >
                        <LabelWrapper label={t("Platform")} isShow={true}>
                          <DropdownPlatform
                            name="platform"
                            placeholder={`${t("Platform")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={platFormSearch || null}
                            onChangeSelect={(e: any) => setPlatFormSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-3 date-picker-wrapper-custom"
                      >
                        <LabelWrapper
                          label={t("Date")}
                          isShow={!!startDate || !!endDate}
                        >
                          <DatePickerCustom
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-sm-4 mt-md-3"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={feedbacks?.items?.length ? feedbacks?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={feedbacks?.total}
                      customPageCount={Math.ceil(
                        Number(feedbacks?.total || 0) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isFeedbackLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default FeedbackList;
