import { createAsyncThunk } from "@reduxjs/toolkit";
import logApi from 'api/logApi';
import { formatQueryParams } from "helpers/format";

export const getUserLogs= createAsyncThunk("Log" , async (params: any = {}) => {
  try{
    const response = await logApi.userLogs(formatQueryParams(params));
    return response;
  }catch (error) {
    return error;
  }
});


export const getUserLogsByNormal =  async (params: any = {}) => {
  try{
    const response = await logApi.userLogs(formatQueryParams(params));
    return response;
  }catch (error) {
    return error;
  }
};