import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import i18n from "i18n";
interface Option {
  label: string | any;
  value: string;
}
interface DropdownOptionProps {
  name?: string,
  initialValue?: string | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean,
  className?: string,
  classNamePrefix?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
  labelTop?: string,
  isClearable?: boolean,
  inputRefToFocus?: any
}

const DropdownArtistNew = ({
  name = '',
  initialValue,
  onChangeSelect,
  dataList = [],
  placeholder,
  disabled = false,
  className = '',
  classNamePrefix = '',
  isHasOptionAll = false,
  optionAll,
  isClearable = false,
  inputRefToFocus
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [options, setOptions] = useState<Option[]>([]);

  const filterOption = (option: any, inputValue:any) => {
    const plainLabel = `${option.data?.data?.name?.ko} - ${option.data?.data?.name?.en}`
    return plainLabel?.toLowerCase()?.includes(inputValue.toLowerCase());
  };

  useEffect(() => {
    const result: any = isHasOptionAll ? [optionAll].concat(dataList) : dataList;
    setOptions((_prev: any) => result);
  }, [dataList, initialValue, i18n?.language]);

  return (
    <div className="position-relative" style={{width: "100%"}}>
      <Select
        inputRefToFocus={inputRefToFocus}
        options={options}
        name="artistId"
        value={options.find(item => item.value === initialValue) || (isHasOptionAll ? options[0] : null)}
        isDisabled={disabled}
        onChange={(e: any) => onChangeSelect && onChangeSelect(e)}
        placeholder={<div>{placeholder || ''}</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        className={className}
        classNamePrefix={classNamePrefix}
        isClearable={isClearable}
        filterOption={filterOption}
      />
    </div>
  );
};

export default DropdownArtistNew;
