import { createSlice } from "@reduxjs/toolkit";
import { getArtists } from "./thunk";

export interface IState {
  artists: any;
  isArtistLoading: boolean;
  isArtistSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  artists: null,
  isArtistLoading: false,
  isArtistSuccess: false,

  error: {},
};

const ArtistSlice = createSlice({
  name: "Artist",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getArtists.pending, (state: IState, action: any) => {
      state.isArtistLoading = true;
    });
    builder.addCase(getArtists.fulfilled, (state: IState, action: any) => {
      state.artists = action.payload.data;
      state.isArtistSuccess = true;
      state.isArtistLoading = false;
    });
    builder.addCase(getArtists.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isArtistSuccess = false;
      state.isArtistLoading = false;
    });
  },
});

export default ArtistSlice.reducer;
