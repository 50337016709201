import { createSlice } from "@reduxjs/toolkit";
import { getAdvertisements } from "./thunk";

export interface IState {
  advertisements: any;
  isAdvertisementLoading: boolean;
  isAdvertisementSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  advertisements: null,
  isAdvertisementLoading: false,
  isAdvertisementSuccess: false,

  error: {},
};

const AdvertisementSlice = createSlice({
  name: "Advertisement",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Advertisements
    builder.addCase(getAdvertisements.pending, (state: IState, action: any) => {
      state.isAdvertisementLoading = true;
    });
    builder.addCase(
      getAdvertisements.fulfilled,
      (state: IState, action: any) => {
        state.advertisements = action.payload.data;
        state.isAdvertisementSuccess = true;
        state.isAdvertisementLoading = false;
      }
    );
    builder.addCase(
      getAdvertisements.rejected,
      (state: IState, action: any) => {
        state.error = action.payload.error || null;
        state.isAdvertisementSuccess = false;
        state.isAdvertisementLoading = false;
      }
    );
  },
});

export default AdvertisementSlice.reducer;
