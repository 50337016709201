import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import PushNotiApi from "api/pushNotiApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import CollapseFilter from "components/Common/CollapseFilter";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import ModalPreview from "components/Common/ModalPreview";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import {
  COLOR_STATUS_FEED,
  ENUM_NOTI_SETTING_STATUS,
  NOTI_SETTING_STATUS_OPTION,
  NOTI_SETTING_TYPE_COLOR,
  NOTI_SETTING_TYPE_OPTION,
} from "helpers/constans";
import { formatNumberWithCommas } from "helpers/format";
import CountUp from "react-countup";
import { getPushNotis } from "store/pushNoti/thunk";
import CreateEditPushNoti from "./CreateEditPushNoti";
import ModalMultiPreview from "components/Common/ModalMultiPreview";

interface Option {
  label: string;
  value: string;
}
export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const MediaList = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();

  const STATUS_OPTION_LANG =
    NOTI_SETTING_STATUS_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const TYPE_OPTION_LANG =
    NOTI_SETTING_TYPE_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    content: withDefault(StringParam, ""),
    "condition[description]": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[type]": withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),

    sort_by: withDefault(StringParam, "createdAt"),
    order_by: withDefault(StringParam, "DESC"),
  });

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);

  const [optionsSelected, setOptionsSelected] = useState<any>([]);
  const [isOpenConfirmStatusNews, setIsOpenConfirmStatusNews] =
    useState<boolean>(false);
  const [typeStatusNews, setTypeStatusNews] = useState<any>(null);

  const [itemToEdit, setItemToEdit] = useState<any>(false);

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query["condition[title]"] || ""
  );
  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_OPTION_LANG?.find(
      (item) => String(item?.value) === String(query["condition[status]"])
    ) || null
  );

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const [typesSearch, setTypesSearch] = useState<Option | null>();

  const dispatch: any = useDispatch();

  const selectLayoutState = (state: any) => state.PushNoti;

  const PushNotiProperties = createSelector(selectLayoutState, (state) => ({
    pushNotis: state.pushNotis,
    isPushNotiLoading: state.isPushNotiLoading,
    isPushNotiSuccess: state.isPushNotiSuccess,

    error: state.error,
  }));

  // Inside your component
  const { pushNotis, isPushNotiLoading } = useSelector(PushNotiProperties);

  function loadTableData() {
    dispatch(getPushNotis(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const [linkImage, setLinkImage] = useState<any>(null);
  const onCloseImageClick = () => {
    setLinkImage(null);
  };

  const searchData = () => {
    setQuery({
      ...query,
      "condition[description]": keywordSearch || "",
      "condition[status]": statusSearch?.value || "",
      "condition[type]": typesSearch?.value || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: "createdAt",
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[description]": "",
        "condition[status]": "",
        "condition[type]": "",
        startDate: "",
        endDate: "",
        sort_by: "createdAt",
        time_request: +new Date(),
        page: 1,
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setStatusSearch((_prev) => null);
    setTypesSearch((_prev) => null);
    setDateSearch([null, null]);
  };

  // Begin::Change status
  // const [itemUpdate, setItemUpdate] = useState<{ id: number | string, status: string | boolean } | null>(null);
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);
  // const handleShowConfirmUpdate = (item: { id: number | string, status: string | boolean }) => {
  //   setItemUpdate(item);
  // };
  const handleOnConfirmUpdate = async (item: {
    id: number | string;
    status: string | boolean;
  }) => {
    try {
      if (!item) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await PushNotiApi?.updatePushNoti(item?.id, {
        status: item?.status,
      });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        // setItemUpdate((_prev) => null);
        loadTableData();
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End::Change status

  // Begin:: handle select table

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {pushNotis?.total -
                ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "status",
        thWidth: 80,
        filterable: false,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (
            <div
              className="form-check form-switch form-switch-sm text-center"
              dir="ltr"
            >
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                id="customSwitchsizelg"
                checked={
                  String(item?.status) === ENUM_NOTI_SETTING_STATUS.ACTIVATED
                }
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleOnConfirmUpdate({
                    id: item?._id,
                    status: !!value
                      ? ENUM_NOTI_SETTING_STATUS.ACTIVATED
                      : ENUM_NOTI_SETTING_STATUS.DISABLED,
                  });
                }}
              />
            </div>
          );
        },
      },

      {
        Header: t("Type"),
        accessor: "type",
        filterable: true,
        sortable: false,
        thWidth: 140,
        thClass: "text-center",
        Cell: (cell: any) => {
          const type = cell?.value;
          return (
            <div className="text-center" style={{ minWidth: "120px" }}>
              <span
                className={`rounded-pill badge bg-${
                  NOTI_SETTING_TYPE_COLOR[type] || "secondary"
                }`}
              >
                {
                  TYPE_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(type)
                  )?.label
                }
              </span>
            </div>
          );
        },
      },

      {
        Header: t("Description"),
        accessor: "description",
        filterable: true,
        sortable: false,
        thWidth: 250,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return i18n?.language == "en"
            ? item?.description?.en
            : item?.description?.ko;
        },
      },

      {
        Header: t("Title"),
        accessor: "title",
        filterable: true,
        sortable: false,
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original?.title;
          return item?.en;
        },
      },

      {
        Header: t("Title (Korean)"),
        accessor: "title_korean",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        thWidth: 150,
        Cell: (cell: any) => {
          const item = cell?.row?.original?.title;
          return item?.ko;
        },
      },
      
      {
        Header: t("Content"),
        accessor: "content",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original?.content;
          return item?.en;
        },
      },

      {
        Header: t("Content (Korean)"),
        accessor: "content_korean",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        thWidth: 200,
        Cell: (cell: any) => {
          const item = cell?.row?.original?.content;
          return item?.ko;
        },
      },

      {
        Header: t("Standard User Push Count"),
        accessor: "numberOfPushForNormalUser",
        filterable: true,
        sortable: true,
        thWidth: 300,
        thClass: "text-center whitespace-nowrap",
        description: t("Description for Standard User Push Count"),
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center pe-3">
                {cell?.value == -1
                  ? t("Unlimited")
                  : formatNumberWithCommas(cell?.value)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("VIP User Push Count"),
        accessor: "numberOfPushForVIPUser",
        filterable: true,
        sortable: true,
        thWidth: 300,
        thClass: "text-center whitespace-nowrap",
        description: t("Description for VIP User Push Count"),
        Cell: (cell: any) => {
          return (
            <>
              <div className="text-center pe-3">
                {cell?.value == -1
                  ? t("Unlimited")
                  : formatNumberWithCommas(cell?.value)}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Button Action"),
        thClass: "text-center",
        thWidth: 150,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <ul
              className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
              style={{ justifyContent: "center" }}
            >
              <TooltipCustom
                title={t("Button Update")}
                id={`update-md-${item?.id}`}
              >
                <li className="list-inline-item" id={`update-md-${item?.id}`}>
                  <Link
                    className="btn btn-sm btn-soft-primary edit-item-btn"
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      setIsOpen(true);
                      setItemToEdit(item);
                    }}
                  >
                    <i className="ri-pencil-fill align-bottom "></i>
                  </Link>
                </li>
              </TooltipCustom>
            </ul>
          );
        },
      },
    ],
    [i18n?.language, userPermissions, pushNotis?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
  }, []);

  useEffect(() => {
    document.title = `${t("Push Notification")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Push Notification")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={pushNotis?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-md-2 mt-0">
                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Keyword")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Description")}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Type")} isShow={true}>
                          <DropdownStatus
                            name="type"
                            dataList={TYPE_OPTION_LANG || []}
                            placeholder={`${t("Type")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={typesSearch || null}
                            onChangeSelect={(e: any) => setTypesSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={NOTI_SETTING_TYPE_COLOR}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Status")} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_OPTION_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={COLOR_STATUS_FEED}
                          />
                        </LabelWrapper>
                      </Col>

                      {/*  <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2 date-picker-wrapper-custom"
                      >
                        <LabelWrapper
                          label={t("Date")}
                          isShow={!!startDate || !!endDate}
                        >
                          <DatePickerCustom
                            startDate={startDate || null}
                            endDate={endDate || null}
                            onChangePicker={handleChangePicker}
                          />
                        </LabelWrapper>
                      </Col> */}

                      <Col
                        sm={3}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-3 mt-md-2"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                            disabled={isPushNotiLoading}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={pushNotis?.items?.length ? pushNotis?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={pushNotis?.total}
                      customPageCount={Math.ceil(
                        Number(pushNotis?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isPushNotiLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isOpen && (
          <CreateEditPushNoti
            setIsOpen={setIsOpen}
            item={itemToEdit}
            setItemToEdit={setItemToEdit}
            loadTableData={loadTableData}
            setLinkImage={setLinkImage}
            onCloseImageClick={onCloseImageClick}
          />
        )}

        <ModalPreview
          type={linkImage?.isVideo ? "VIDEO" : "IMAGE"}
          isOpen={!!linkImage?.url}
          url={linkImage?.url}
          content={linkImage?.content}
          onClose={() => onCloseImageClick()}
        />

        <ModalMultiPreview
          isOpen={!!linkImage?.urls}
          urls={linkImage?.urls || []}
          content={linkImage?.content}
          onClose={() => onCloseImageClick()}
        />
      </div>
    </React.Fragment>
  );
};

export default MediaList;
