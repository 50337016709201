import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { truncateWithExpand } from "utils";

interface CollapseTagProps {
  dataOriginal?: string;
  isRank?: boolean;
  isInlineBlock?: boolean;
  isShowModal?: boolean;
  titleModal?: string;
  style?: any;
  className?: string;
}

const CollapseContent = ({
  dataOriginal = "",
  isRank = false,
  isInlineBlock = false,
  isShowModal = false,
  titleModal = "",
  style = {},
  className = "",
}: CollapseTagProps) => {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);

  let resContent = truncateWithExpand(dataOriginal, 70);

  function onCloseClick() {
    setIsOpenModal((_prev) => !_prev);
  }

  return (
    <React.Fragment>
      {resContent?.data ? (
        <div className={`d-contents  ${className}`} style={style}>
          {isOpenModal ? dataOriginal : resContent?.data}

          {resContent?.isMoreText && (
            <span
              onClick={(e) => {
                e.preventDefault();
                isShowModal ? onCloseClick() : setIsOpen((prev) => !prev);
              }}
              style={{
                cursor: "pointer",
                marginLeft: "10px",
                whiteSpace: "nowrap",
              }}
              className="link-primary fs-12 d-inline"
            >
              <span>{isOpenModal ? t("Hide") : t("More")}</span>
              <i
                className={`${
                  isOpenModal ? "ri-arrow-up-s-line" : "ri-arrow-down-s-line"
                } fs-16 position-relative`}
                style={{ top: "4px" }}
              ></i>
            </span>
          )}
        </div>
      ) : (
        <></>
      )}
    </React.Fragment>
  );
};

export default CollapseContent;
