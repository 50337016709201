import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/articles";
const EmotionApi = {
  getWallpaperDownload(params: any): Promise<any> {
    const url = `${path}/wallpaper-download`;
    return api.get(url, params);
  },

};

export default EmotionApi;
