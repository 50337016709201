import { get } from 'lodash';
import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import SnippetApi from "api/snippetApi";
import { formatQueryParams } from "helpers/format";

const getSnippetApi = async (params: any = {}) => {
  try {
    const response = await SnippetApi.snippets(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
}
export const getSnippets = createAsyncThunk(
  "Snippet",
  getSnippetApi
);

export const getPhotoNews = createAsyncThunk(
  "PhotoNew",
  getSnippetApi
);
