import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/sessions";
const SessionApi = {
  sessions(params: any, paramsSerializer?: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params, paramsSerializer);
  },
};

export default SessionApi;
