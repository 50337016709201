import { createSlice } from "@reduxjs/toolkit";
import { getPushNotis } from "./thunk";

export interface IState {
  pushNotis: any;
  isPushNotiLoading: boolean;
  isPushNotiSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  pushNotis: null,
  isPushNotiLoading: false,
  isPushNotiSuccess: false,

  error: {},
};

const PushNotiSlice = createSlice({
  name: "PushNoti",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get PushNotis
    builder.addCase(getPushNotis.pending, (state: IState, action: any) => {
      state.isPushNotiLoading = true;
    });
    builder.addCase(getPushNotis.fulfilled, (state: IState, action: any) => {
      state.pushNotis = action.payload.data;
      state.isPushNotiSuccess = true;
      state.isPushNotiLoading = false;
    });
    builder.addCase(getPushNotis.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isPushNotiSuccess = false;
      state.isPushNotiLoading = false;
    });
  },
});

export const PushNotiReducer = PushNotiSlice.reducer;
