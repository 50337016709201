import { APIClient } from "./axiosClient";
import { ResponseData } from "./types/_public";
import { AxiosResponse } from "axios";
import { IUser } from "./types/_user";
const api = new APIClient();

const path = "/admins";
const adminApi = {
  userAdmins(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

  createUserAdmin(params: any) {
    return api.post(path, params);
  },

  updateUserAdmin(id: any, params: any) {
    return api.put(`${path}/${id}`, params);
  },

  deleteUserAdmin(id: any) {
    return api.delete(`${path}/${id}`);
  },
  
  postUserAdmin(dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}`;
    return api.post(url, dataForm)
  },

  putUserAdmin(id: string | number, dataForm: any) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/${id}`;
    return api.put(url, dataForm)
  },

  getUserAdmin(id: string | number, params: any = {}) : Promise<AxiosResponse<ResponseData<IUser>, any>>  {
    const url = `${path}/${id}`;
    return api.get(url, params)
  },
};

export default adminApi;
