import { createSlice } from "@reduxjs/toolkit";
import { getSessions } from "./thunk";

export interface IState {
  sessions: any;
  isSessionLoading: boolean;
  isSessionSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  sessions: null,
  isSessionLoading: false,
  isSessionSuccess: false,

  error: {},
};

const SessionSlice = createSlice({
  name: "Session",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Sessions
    builder.addCase(getSessions.pending, (state: IState, action: any) => {
      state.isSessionLoading = true;
    });
    builder.addCase(getSessions.fulfilled, (state: IState, action: any) => {
      state.sessions = action.payload.data;
      state.isSessionSuccess = true;
      state.isSessionLoading = false;
    });
    builder.addCase(getSessions.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSessionSuccess = false;
      state.isSessionLoading = false;
    });
  },
});

export const SessionReducer = SessionSlice.reducer;
