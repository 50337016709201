import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import UserNotiPermissionApi from "api/userNotiPermissionApi";
import { formatQueryParams } from "helpers/format";

export const getUserNotiPermissionApi = async (params: any = {}) => {
  try {
    const response = await UserNotiPermissionApi.userNotiPermissions(
      formatQueryParams(params)
    );
    return response;
  } catch (error) {
    return error;
  }
};

export const getUserNotiPermissions = createAsyncThunk(
  "UserNotiPermissions",
  getUserNotiPermissionApi
);
