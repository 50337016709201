import {
  CONFIG_OPTION_TOAST_ERROR
} from "common/toast"; import i18n from "i18n";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { AsyncPaginate, wrapMenuList } from 'react-select-async-paginate';
import Select, { components } from "react-select";
import userApi from "api/userApi";
import { getPlatFormImage } from "helpers/constans";
import { toast } from "react-toastify";
import { getOptionAllCategories } from "store/thunks";
import NO_IMAGE from "../../assets/images/no-image-icon.png";


interface Option {
  label: string | any;
  value: string;
}
interface DropdownOptionProps {
  name?: string;
  listCheckedGroup?: Option[] | Option | null | undefined | string;
  debounceTimeout?: number;
  onChangeSelect?: (params: Option) => void;
  isMulti?: boolean;
  placeholder?: string;
  isShowIconCheck?: boolean;
  hideSelectedOptions?: boolean;
  inputRefToFocus?: any;
  isHasOptionAll?: boolean;
  width?: string | number
}
const LIMIT = 30

const DropdownAsyncGroup = ({
  name = "",
  listCheckedGroup,
  debounceTimeout = 700,
  onChangeSelect,
  isMulti = false,
  placeholder,
  isShowIconCheck = false,
  hideSelectedOptions = true,
  inputRefToFocus,
  isHasOptionAll = false,
  width
}: DropdownOptionProps) => {
  const { t } = useTranslation();
  const [isAllCheckedUsers, setIsAllCheckedUsers] = useState(false)

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  }

  const loadListUsers = async ({
    page,
    limit,
    search
  }: {
    page: number
    limit: number
    search?: string
  }) => {
    // const { data: { items, lastPage }, success } = await userApi.users({ page, limit, keyword: search });
    const res: any = await getOptionAllCategories({
      page: page,
      limit: limit,
      "condition[title]": search
    });

    if (!res?.success) {
      return { success: false };
    }
    const dataUsersOptions = res?.data?.items?.map((a: any) => {
      return ({
        value: String(a?._id),
        label: (
          <div className="d-flex align-items-center gap-2">
            <div
              style={{
                height: "22px",
                width: "22px",
                overflow: "hidden",
                borderRadius: "15px",
                objectFit: "cover",
                backgroundColor: "#EAEAEA",
                minWidth: "22px",
                minHeight: "22px",
              }}
            >
              <img
                src={a?.thumbnail || a?.image || NO_IMAGE}
                alt={a?.title?.ko}
                height={"100%"}
                width={22}
                className="me-2"
                style={{
                  overflow: "hidden",
                  borderRadius: "50%",
                  objectFit: "cover",
                  backgroundColor: "#EAEAEA",
                }}
              />
            </div>
            {`${a?.title?.ko} - ${a?.title?.en}`}
          </div>
        ),
        data: a,
      })
    })
    return {
      success: res?.success,
      dataUsersOptions,
      lastPage: res?.data?.lastPage
    };
  }

  async function loadOptions(search: string, loadedOptions: any[], { page }: { page: number }) {
    const {
      success,
      dataUsersOptions,
      lastPage
    } = await loadListUsers({ page, limit: LIMIT, search });
    if (!success) {
      toast(`${t("Failed to load data")}`, CONFIG_OPTION_TOAST_ERROR);
      return {
        options: [],
        hasMore: false,
      };
    }

    const options = isHasOptionAll ? [...(page === 1 ? [{ label: t("All Filter"), value: "all" }] : []), ...dataUsersOptions] : dataUsersOptions;

    return {
      options,
      hasMore: lastPage > page,
      additional: {
        page: page + 1,
      },
    };
  }

  const MenuList = (props: any) => {
    let childComponent = props?.children;
    if (props?.children?.length > 0) {
      const allOption = props?.children?.filter((item: any) => item?.props?.value == 'all');
      let fixedIndex = -1;
      if (allOption) {
        fixedIndex = props?.children.indexOf(allOption?.[0]);
      }
      const fixedElement = props?.children?.[fixedIndex];

      const childComponentFormat = (props?.children || [])?.sort((a: any, b: any) => {
        let pointA = a.props.isSelected === true ? 1 : 0;
        let pointB = b.props.isSelected === true ? 1 : 0;
        return pointB - pointA;
      })

      if (fixedElement) {
        childComponent = [fixedElement, ...childComponentFormat]
      } else {
        childComponent = childComponentFormat;
      }
    }

    return (
      <components.MenuList {...props}>{childComponent}</components.MenuList>
    );
  }

  const Option = (props: any) => {
    if (props?.isSelected && props?.value == "all") {
      setIsAllCheckedUsers(true);
    } else if (!props?.isSelected && props?.value == "all") {
      setIsAllCheckedUsers(false);
    }

    return (
      <components.Option {...props} style={{ zIndex: 999 }}>
        <div className="d-flex align-items-center gap-2">
          {((props?.isSelected || isAllCheckedUsers) && isShowIconCheck) ? (
            <i className="ri-check-line align-middle fs-14" />
          ) : (
            ""
          )}
          {props.data?.label}
        </div>
      </components.Option>
    );
  }

  const MenuListAsync = wrapMenuList(MenuList);

  return (
    <AsyncPaginate
      inputRefToFocus={inputRefToFocus}
      isMulti={isMulti}
      isClearable
      defaultOptions
      debounceTimeout={debounceTimeout}
      value={listCheckedGroup}
      loadOptions={loadOptions}
      placeholder={placeholder}
      className="dropdown-status-rounded"
      hideSelectedOptions={false}
      loadingMessage={() => (<div>{t('Loading')}...</div>)}
      onChange={(e: any) => setOnChangeOption(e)}
      additional={{
        page: 1,
      }}
      components={{
        Option: Option,
        ...(isMulti ? { MenuList: MenuListAsync } : null)
      }}
      closeMenuOnSelect={hideSelectedOptions}
      menuPortalTarget={document.body}
      styles={{
        option: (styles: any) => {
          let styleNew = isMulti ? {
            ...styles,
            backgroundColor: "white",
            zIndex: 99,
            color: "black",
          } : styles;

          return styleNew;
        },
        control: (styles: any, { isFocused }: any) => {
          return {
            ...styles,
            borderColor: "#e9ebec",
            borderRadius: "0.6rem",
            color: "white",
            "&:hover": { borderColor: "#009ef7" },
            ...(width ? { width: width } : {})
          };
        },
        multiValue: (styles: any) => {
          return {
            ...styles,
            backgroundColor: "#009ef7",
          };
        },
        multiValueLabel: (styles: any) => ({
          ...styles,
          color: "white !important",
        }),
        menuPortal: (base: any) => ({
          ...base,
          zIndex: 1501,
        }),
      }}
    />
  );
};

export default DropdownAsyncGroup;
