import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";

import CategoryApi from "api/categoryApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import DropdownStatus from "components/Common/DropdownStatus";
import LabelWrapper from "components/Common/LabelWrapper";
import ModalConfirm from "components/Common/ModalConfirm";
import TooltipCustom from "components/Common/TooltipCustom";
import { useRole } from "components/Hooks/UserHooks";
import {
  COLOR_STATUS_ARTIST,
  COLOR_TYPE_ARTIST,
  COLOR_TYPE_ARTIST_TDI,
  ENUM_STATUS_ARTIST,
  STATUS_ARTIST_OPTION,
  TYPE_ARTIST_OPTION,
  TYPE_ARTIST_TDI_OPTION,
  TYPE_GROUP_TDI_OPTION,
} from "helpers/constans";
import CountUp from "react-countup";
import { getCategory, putCategory } from "store/thunks";
import GroupForm from "./GroupForm";
import DatePickerCustom from "components/Common/DatePickerCustom";
import {
  formatDateStringToKorea,
  formatNumberWithCommas,
} from "helpers/format";
import Gallery from "./Gallery";
import ModalPreview from "components/Common/ModalPreview";
import CollapseFilter from "components/Common/CollapseFilter";
import axios from "axios";
import groupTDIApi from "api/groupTDIApi";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const LIST_OPTION_TYPE = [
  {
    label: "Role_Default",
    value: "default",
  },
  {
    label: "Role_Idol",
    value: "idol",
  },
  {
    label: "Role_Actor",
    value: "actor",
  },
  {
    label: "Role_Entertainment",
    value: "entertainment",
  },
];

const CategoryList = () => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  const { userPermissions } = useRole();

  const STATUS_ARTIST_OPTION_LANG =
    STATUS_ARTIST_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const TYPE_ARTIST_OPTION_LANG =
    TYPE_GROUP_TDI_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 30),
    "sort[createdAt]": withDefault(StringParam, `desc`),
    "populate[artists]": withDefault(StringParam, "*"),
    "condition[name]": withDefault(StringParam, ""),
    "condition[status]": withDefault(StringParam, ""),
    "condition[type]": withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);
  const [startDate, endDate] = dateSearch;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [itemToDelete, setItemToDelete] = useState<any>(false);
  const [isConfirmLoading, setIsConfirmLoading] = useState<boolean>(false);
  const [itemToEdit, setItemToEdit] = useState<any>(false);
  const [linkImage, setLinkImage] = useState<string>("");
  // const [isModalArtist, setIsModalArtist] = useState<boolean>(false);

  const [keywordSearch, setKeywordSearch] = useState<string>("");
  const [statusSearch, setStatusSearch] = useState<Option | null>(
    STATUS_ARTIST_OPTION_LANG?.find(
      (item) => String(item?.value) === String(query["condition[status]"])
    ) || null
  );
  const [typeSearch, setTypeSearch] = useState<Option | null>(
    TYPE_ARTIST_OPTION_LANG?.find(
      (item) => String(item?.value) === String(query["condition[type]"])
    ) || null
  );

  const [itemToGallery, setItemToGallery] = useState<any | null>(null);

  const selectLayoutState = (state: any) => state.Group;

  const UserProperties = createSelector(selectLayoutState, (state) => ({
    users: state.categories,
    isUserLoading: state.isCategoriesLoading,
    isUserSuccess: state.isCategoriesSuccess,

    error: state.error,
  }));

  // Inside your component
  // const { users, isUserLoading } = useSelector(UserProperties);
  // const { isUserLoading } = useSelector(UserProperties);
  const defaultData = {
    total: 0,
    items: [],
  };
  const [users, setUsers] = useState(defaultData);

  const [isGroupTDILoading, setIsGroupTDILoading] = useState(false);

  const loadTableData = async () => {
    // dispatch(getArtistsTDI(query));
    setIsGroupTDILoading(true);
    const res: any = await groupTDIApi.groupTDI(query);

    if (res?.data) {
      setIsGroupTDILoading(false);
      setUsers(res?.data);
    }
  };

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  // function loadTableData() {
  //   dispatch(getCategory(query));
  // }

  // useEffect(() => {
  //   loadTableData();
  // }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      "populate[artists]": "*",
      "condition[name]": keywordSearch || "",
      "condition[status]": statusSearch?.value || "",
      "condition[type]": typeSearch?.value || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
        "condition[name]": "",
        "condition[status]": "",
        "condition[type]": "",
        startDate: "",
        endDate: "",
        sort_by: TYPE_SELECT_DEFAULT,
        time_request: +new Date(),
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setTypeSearch((_prev) => null);
    setStatusSearch((_prev) => null);
  };

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  // Begin::Update User
  const onCloseClick = () => {
    setItemToEdit((_prev: any) => null);
    setIsOpen((_prev) => false);
  };

  const triggerRefresh = () => {
    onCloseClick();
    dispatch(getCategory(query));
  };
  //End::Update User

  // Begin::Change status
  const [isLoadingUpdate, setIsLoadingUpdate] = useState<boolean>(false);

  const handleOnConfirmUpdate = async (item: {
    id: number | string;
    status: string | boolean;
  }) => {
    try {
      if (!item) {
        return;
      }
      setIsLoadingUpdate((_prev) => true);
      const response: any = await putCategory(item?.id, {
        status: item?.status,
      });
      setIsLoadingUpdate((_prev) => false);
      if (response?.success) {
        dispatch(getCategory(query));
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoadingUpdate((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };
  // End::Change status
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {users?.total -
                ((query?.page - 1) * query?.limit + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("Active"),
        accessor: "status",
        thWidth: 140,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          return (
            <div
              className="form-check form-switch form-switch-sm text-center"
              dir="ltr"
              style={{ minWidth: "120px" }}
            >
              <input
                type="checkbox"
                className="form-check-input"
                name="status"
                id="customSwitchsizelg"
                checked={String(item?.status) === ENUM_STATUS_ARTIST.ACTIVATED}
                onChange={(e) => {
                  const value = e?.target?.checked;
                  handleOnConfirmUpdate({
                    id: item?._id,
                    status: !!value
                      ? ENUM_STATUS_ARTIST.ACTIVATED
                      : ENUM_STATUS_ARTIST.DISABLED,
                  });
                }}
              />
            </div>
          );
        },
      },
      {
        Header: t("Status"),
        accessor: "_status",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const status = cell?.row?.original?.status;
          return (
            <div className="text-center" style={{ minWidth: "80px" }}>
              <span
                className={`rounded-pill badge bg-${COLOR_STATUS_ARTIST[status] || "secondary"
                  }`}
              >
                {
                  STATUS_ARTIST_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(status)
                  )?.label
                }
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Type"),
        accessor: "type",
        filterable: false,
        sortable: false,
        thWidth: 100,
        thClass: "text-center",
        Cell: (cell: any) => {
          const type = cell?.row?.original?.type;
          return (
            <div className="text-center" style={{ minWidth: "80px" }}>
              <span
                className={`rounded-pill badge bg-${COLOR_TYPE_ARTIST_TDI[type] || "secondary"
                  }`}
              >
                {
                  TYPE_ARTIST_OPTION_LANG?.find(
                    (item: any) => String(item?.value) === String(type)
                  )?.label
                }
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Image"),
        accessor: "image_url",
        thClass: "text-center",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          const item = cell?.row?.original;
          return (cell?.value ? (
            <>
              <div className="w-100 text-center " style={{ minWidth: "150px" }}>
                <div
                  style={{ width: "90px" }}
                  className="m-auto cursor-pointer"
                  onClick={() => setLinkImage((prev) => cell?.value)}
                >
                  <img
                    src={item?.thumbnail || cell?.value}
                    className="flex-shrink-0 rounded w-100"
                    alt="thumbnail"
                  />
                </div>
              </div>
            </>
          ) : (
            ""
          ))
        }
      },
      {
        Header: t("Name"),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: "120px" }}>
                {cell?.value?.en}
              </div>
            </>
          );
        },
      },
      {
        Header: t("Name Korea"),
        accessor: "name_korea",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <div className="cursor-pointer" style={{ minWidth: "120px" }}>
                {cell?.row?.original?.name?.ko}
              </div>
            </>
          );
        },
      },
      // {
      //   Header: t("Type"),
      //   accessor: "type",
      //   filterable: true,
      //   sortable: false,
      //   Cell: (cell: any) => {
      //     const type = LIST_OPTION_TYPE.filter((item) => item.value == cell?.value)[0] || '';
      //     return (
      //       <>
      //         <div className="cursor-pointer">{`${t(type?.label)}`}</div>
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: t("Total Artist"),
      //   accessor: "keyword_count",
      //   filterable: false,
      //   sortable: false,
      //   thClass: "text-start",
      //   // thWidth: 130,
      //   Cell: (cell: any) => {
      //     const value: any = cell?.row?.original;
      //     const names = value?.artists?.map((item: any) => item.name);
      //     const commaSeparatedString = names ? names.join(" > ") : "";
      //     let name = value.name;
      //     if (commaSeparatedString !== "") {
      //       name = commaSeparatedString + ` > ` + value.name;
      //     }
      //     const option = {
      //       value: cell?.row?.original?.id,
      //       label: `${name} (${cell?.value ?? 0})`,
      //       keyword_count: cell?.value,
      //     };
      //     return (
      //       <Link
      //         className="btn btn-sm btn-soft-primary"
      //         to="#"
      //         onClick={(e) => {
      //           e.preventDefault();
      //           handleShowDetailCategory(option);
      //         }}
      //         style={{ minWidth: '55px' }}
      //       >
      //         <i className="ri-share-box-line align-bottom"></i>
      //         {"  "}
      //         <span>{value?.artists?.length ?? 0}</span>
      //       </Link>
      //     );
      //   },
      // },
      // {
      //   Header: t("Related Photos"),
      //   accessor: "photos",
      //   filterable: true,
      //   sortable: false,
      //   thClass: "text-center",
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (
      //       <>
      //         <div className="text-center cursor-pointer link-primary text-decoration-underline" style={{ minWidth: '120px' }}
      //           onClick={() => {
      //             setItemToGallery((_prev: any) => ({ title: t("Related Photos"), artistId: item?.artists?.[0], type: 'PHOTO', categoryId: item?._id }))
      //           }}
      //         >
      //           {t('View Detail')}
      //         </div>
      //       </>
      //     );
      //   },
      // },
      // {
      //   Header: t("Related Videos"),
      //   accessor: "videos",
      //   filterable: true,
      //   sortable: false,
      //   thClass: "text-center",
      //   Cell: (cell: any) => {
      //     const item = cell?.row?.original;
      //     return (
      //       <>
      //         <div className="text-center cursor-pointer link-primary text-decoration-underline" style={{ minWidth: '100px' }}
      //           onClick={() => {
      //             setItemToGallery((_prev: any) => ({ title: t("Related Videos"), artistId: item?.artists?.[0], type: 'VIDEO', categoryId: item?._id }))
      //           }}
      //         >
      //           {t('View Detail')}
      //         </div>
      //       </>
      //     );
      //   },
      // },
      {
        Header: t("Created at"),
        accessor: "createdAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: "120px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      {
        Header: t("Updated at"),
        accessor: "updatedAt",
        filterable: true,
        sortable: false,
        thWidth: 130,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end" style={{ minWidth: "120px" }}>
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
      // {
      //   Header: t("Button Action"),
      //   thClass: "text-center",
      //   thWidth: 150,
      //   Cell: (cell: any) => {
      //     const item: any = cell?.row?.original;
      //     return (
      //       <ul
      //         className="list-inline d-flex flex-wrap hstack gap-2 mb-0"
      //         style={{ width: "120px", justifyContent: "center" }}
      //       >
      //         <TooltipCustom
      //           title={t("Button Update")}
      //           id={`update-md-${item?.id}`}
      //         >
      //           <li className="list-inline-item" id={`update-md-${item?.id}`}>
      //             <Link
      //               className="btn btn-sm btn-soft-primary edit-item-btn"
      //               to="#"
      //               onClick={(e) => {
      //                 e.preventDefault();
      //                 setIsOpen(true);
      //                 setItemToEdit(item);
      //               }}
      //             >
      //               <i className="ri-pencil-fill align-bottom "></i>
      //             </Link>
      //           </li>
      //         </TooltipCustom>

      //         <TooltipCustom
      //           title={t("Button Delete")}
      //           id={`delete-md-${item?.id}`}
      //         >
      //           <li className="list-inline-item" id={`delete-md-${item?.id}`}>
      //             <Link
      //               className="btn btn-sm btn-soft-danger edit-item-btn"
      //               to="#"
      //               onClick={(e) => {
      //                 e.preventDefault();
      //                 setItemToDelete(item);
      //               }}
      //             >
      //               <i className="ri-delete-bin-3-fill align-bottom"></i>
      //             </Link>
      //           </li>
      //         </TooltipCustom>
      //       </ul>
      //     );
      //   },
      // },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, userPermissions, users?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseImageClick = () => {
    setLinkImage((_prev) => "");
  };

  // Begin::Delete

  const handleActionDelete = async (id: any) => {
    if (!id) {
      return;
    }
    try {
      setIsConfirmLoading((_prev) => true);
      const response: any = await CategoryApi?.deleteCategory(id);
      if (response?.success) {
        loadTableData();
        setIsConfirmLoading((_prev) => false);
        setItemToDelete(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsConfirmLoading((_prev) => false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsConfirmLoading((_prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  // End::Delete

  useEffect(() => {
    document.title = `${t("Group TDI")} - ${t("Artist")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Group Management")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={users?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                    {/* <div className="col-sm-auto">
                      <button
                        type="button"
                        className="btn btn-success me-1"
                        onClick={() => setIsOpen((_prev) => true)}
                      >
                        <i className="ri-add-fill align-bottom me-1"></i>{" "}
                        {t("Button Create Group")}
                      </button>
                    </div> */}
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-0 mt-md-2">
                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Name")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Name")}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Type")} isShow={true}>
                          <DropdownStatus
                            name="type"
                            dataList={TYPE_ARTIST_OPTION_LANG || []}
                            placeholder={`${t("Type")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={typeSearch || null}
                            onChangeSelect={(e: any) => setTypeSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={COLOR_TYPE_ARTIST_TDI}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Status")} isShow={true}>
                          <DropdownStatus
                            name="status"
                            dataList={STATUS_ARTIST_OPTION_LANG || []}
                            placeholder={`${t("Status")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={statusSearch || null}
                            onChangeSelect={(e: any) => setStatusSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            colors={COLOR_STATUS_ARTIST}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-4 mt-md-2"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed"
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={users?.items?.length ? users?.items : []}
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={users?.total}
                      customPageCount={Math.ceil(
                        Number(users?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isGroupTDILoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        <Modal
          isOpen={!!itemToEdit?._id || isOpen}
          centered={true}
          size="xl"
          scrollable={false}
        >
          <ModalHeader toggle={onCloseClick}>
            <>
              {!!itemToEdit?._id
                ? t("Button Update Group")
                : t("Button Create Group")}
            </>{" "}
            <>
              {itemToEdit?.title &&
                (" - " +
                  (itemToEdit?.title?.en || "") +
                  " - " +
                  (itemToEdit?.title?.ko || "") ||
                  "")}
            </>
          </ModalHeader>
          <ModalBody>
            <GroupForm
              isModal={true}
              id={String(itemToEdit?._id || "")}
              isCopy={!!itemToEdit?.isCopy}
              triggerRefresh={triggerRefresh}
              LIST_OPTION_TYPE={LIST_OPTION_TYPE}
              setLinkImage={setLinkImage}
            />
          </ModalBody>
        </Modal>

        <Modal
          isOpen={!!itemToGallery}
          centered={true}
          size="xl"
          scrollable={false}
        >
          <ModalHeader toggle={() => setItemToGallery((_prev: any) => null)}>
            {itemToGallery?.title || ""}
          </ModalHeader>
          <ModalBody>
            <Gallery
              type={itemToGallery?.type}
              artistId={String(itemToGallery?.artistId || "")}
              categoryId={String(itemToGallery?.categoryId || "")}
            />
          </ModalBody>
        </Modal>

        {itemToDelete && (
          <ModalConfirm
            header={t("Button Delete Category")}
            isOpen={itemToDelete}
            isLoading={isConfirmLoading}
            onClose={() => setItemToDelete(false)}
            onConfirm={() => handleActionDelete(itemToDelete?._id)}
          />
        )}

        <ModalPreview
          type={"IMAGE"}
          isOpen={!!linkImage}
          url={linkImage}
          onClose={() => onCloseImageClick()}
        />

        {/* <Modal
          isOpen={isModalArtist}
          id="artist-modal"
          centered
          size="xl"
          scrollable={true}
          // toggle={onCloseDetailCategoryClick}
          keyboard={true}
        >
          <ModalHeader toggle={()=> setIsModalArtist(false)}>
            {t("Keywords")}
          </ModalHeader>
          <ModalBody className="text-center">
            <div
              className="row g-4"
              style={{ minHeight: "calc(100dvh - 200px)" }}
            >
              <TrendIndexKeyword
                detailCategory={detailCategoryLoading || undefined}
                handleRefreshListCategory={handleRefetchCategoriesTrendIndex}
              />
            </div>
          </ModalBody>
        </Modal> */}
      </div>
    </React.Fragment>
  );
};

export default CategoryList;
