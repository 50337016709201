// HashtagText.tsx
import React from 'react';

interface HashtagTextProps {
    text: string;
}

const HashtagText: React.FC<HashtagTextProps> = ({ text }) => {

    const regex = /#([A-Za-z0-9_\uAC00-\uD7AF]+)/g;
    const parts = [];
    let lastIndex = 0;
    let match;

    while ((match = regex.exec(text)) !== null) {

        if (match.index > lastIndex) {
            parts.push(text.substring(lastIndex, match.index));
        }
        if (lastIndex === 0) {
            parts.push(
                <>
                    <br />
                    <br />
                </>
            );
        }

        parts.push(
            <span key={match.index} className="text-hashtag">
                {match[0]}
            </span>
        );
        lastIndex = regex.lastIndex;
    }

    if (lastIndex < text.length) {
        parts.push(text.substring(lastIndex));
    }

    return <div className="text-start">{parts}</div>;
};

export default HashtagText;
