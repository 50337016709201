import { APIClient } from "./axiosClient";
const api = new APIClient();

const path = "/countries";
const artistApi = {
  countries(params: any): Promise<any> {
    const url = `${path}`;
    return api.get(url, params);
  },

};

export default artistApi;
