import { createSlice } from "@reduxjs/toolkit";
import { getSnippets, getPhotoNews } from "./thunk";

export interface IState {
  snippets: any;
  isSnippetLoading: boolean;
  isSnippetSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  snippets: null,
  isSnippetLoading: false,
  isSnippetSuccess: false,

  error: {},
};

const SnippetSlice = createSlice({
  name: "Snippet",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Snippets
    builder.addCase(getSnippets.pending, (state: IState, action: any) => {
      state.isSnippetLoading = true;
    });
    builder.addCase(getSnippets.fulfilled, (state: IState, action: any) => {
      state.snippets = action.payload.data;
      state.isSnippetSuccess = true;
      state.isSnippetLoading = false;
    });
    builder.addCase(getSnippets.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSnippetSuccess = false;
      state.isSnippetLoading = false;
    });
  },
});


const PhotoNewSlice = createSlice({
  name: "PhotoNew",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get Snippets
    builder.addCase(getPhotoNews.pending, (state: IState, action: any) => {
      state.isSnippetLoading = true;
    });
    builder.addCase(getPhotoNews.fulfilled, (state: IState, action: any) => {
      state.snippets = action.payload.data;
      state.isSnippetSuccess = true;
      state.isSnippetLoading = false;
    });
    builder.addCase(getPhotoNews.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isSnippetSuccess = false;
      state.isSnippetLoading = false;
    });
  },
});



export const SnippetReducer =  SnippetSlice.reducer;
export const PhotoNewReducer =  PhotoNewSlice.reducer;
