import { IUserActivityLog } from "api/types/_logging";
import { CONFIG_OPTION_TOAST_ERROR } from "common/toast";
import CopyWrapper from "components/Common/CopyWrapper";
import DatePickerCustom from "components/Common/DatePickerCustom";
import DropdownMethod from "components/Common/DropdownMethod";
import LabelWrapper from "components/Common/LabelWrapper";
import { useRole } from "components/Hooks/UserHooks";
import { METHOD_OPTIONS } from "helpers/constans";
import { isHavePermissionRole, ROLES_FOR_APP } from "helpers/role";
import moment from "moment";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import CountUp from "react-countup";
import { useTranslation } from "react-i18next";
import InfiniteScroll from "react-infinite-scroll-component";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import BreadCrumb from "../../../components/Common/BreadCrumb";
import TableContainer from "../../../components/Common/TableContainer";
import { getUserLogsByNormal } from "../../../store/thunks";
import { formatDateStringToKorea } from "helpers/format";
import CollapseFilter from "components/Common/CollapseFilter";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "";
const COLORS_METHOD: any = {
  GET: "success",
  POST: "primary",
  PUT: "warning",
  DELETE: "danger",
};

const UserLog = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const navigate = useNavigate();
  // const { setDurationResponses } = useDurationResponses();

  const isFirstLoadingPageRef = useRef<any>(true);

  const METHOD_OPTIONS_LANG =
    METHOD_OPTIONS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    page: withDefault(NumberParam, 1),
    limit: withDefault(NumberParam, 50),
    sort_by: withDefault(StringParam, TYPE_SELECT_DEFAULT),
    "condition[req][method]": withDefault(StringParam, ""),
    "condition[req][headers][x-forwarded-for]": withDefault(StringParam, ""),
    "condition[req][url]": withDefault(StringParam, ""),
    startDate: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
    endDate: withDefault(StringParam, moment(new Date()).format("Y-MM-DD")),
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    moment(query?.startDate || "", "Y-MM-DD").toDate(),
    moment(query?.endDate || "", "Y-MM-DD").toDate(),
  ]);
  const [startDate, endDate] = dateSearch;

  const [ipSearch, setIpSearch] = useState<string>(query?.ip || "");

  const [urlSearch, setUrlSearch] = useState<string>(query?.url || "");

  const [methodSearch, setMethodSearch] = useState<Option | null>(
    METHOD_OPTIONS_LANG?.filter(
      (item) => String(item?.value) === String(query?.method)
    )[0]
  );

  const [userSearch, setUserSearch] = useState<Option | null>(null);

  const [bodyData, setBodyData] = useState<string>("");

  // Inside your component

  const [userActivityLogs, setWebsiteLogs] = useState<{
    list: IUserActivityLog[];
    total: number;
  }>({ list: [], total: 0 });

  const [isUserActivityLogLoading, setIsUserActivityLogLoading] =
    useState<boolean>(false);

  const handleQueryData = async () => {
    try {
      setIsUserActivityLogLoading((prev) => true);
      if (isFirstLoadingPageRef?.current === true && query?.page !== 1) {
        setQuery({
          ...query,
          page: 1,
        });
        return;
      }
      isFirstLoadingPageRef.current = false;
      const res: any = await getUserLogsByNormal(query);

      setWebsiteLogs((prev: any) => ({
        list: (prev?.list || []).concat(res?.data?.items || []),
        total: res?.data?.total || 0,
      }));
      setIsUserActivityLogLoading((prev) => false);
      // setDurationResponses([{
      //   name: 'Admin Activity Logs',
      //   time: res?.data?.duration || 0
      // }]);
    } catch (error: any) {
      setIsUserActivityLogLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const handleLoadMore = () => {
    setQuery({
      ...query,
      page: query?.page + 1,
    });
  };

  useEffect(() => {
    handleQueryData();
  }, [JSON.stringify(query)]);

  const searchData = () => {
    const queryNew = {
      ...query,
      "condition[req][headers][x-forwarded-for]": ipSearch || "",
      "condition[req][url]": urlSearch || "",
      "condition[req][method]": methodSearch?.value || "",
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : "",
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : "",
      sort_by: TYPE_SELECT_DEFAULT,
      page: 1,
      time_request: +new Date(),
    };

    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setWebsiteLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew);
  };

  const resetData = () => {
    const queryNew = {
      ...query,
      "condition[req][headers][x-forwarded-for]": "",
      "condition[req][url]": "",
      "condition[req][method]": "",
      sort_by: TYPE_SELECT_DEFAULT,
      startDate: moment(new Date()).format("Y-MM-DD"),
      endDate: moment(new Date()).format("Y-MM-DD"),
      page: 1,
      time_request: +new Date(),
    };
    if (JSON.stringify(query) !== JSON.stringify(queryNew)) {
      setWebsiteLogs((prev: any) => ({
        list: [],
        total: 0,
      }));
    }
    setQuery(queryNew, "push");
    setIpSearch((_prev) => "");
    setUrlSearch((_prev) => "");
    setUserSearch(null);
    setDateSearch([
      moment(queryNew?.startDate || "", "Y-MM-DD").toDate(),
      moment(queryNew?.endDate || "", "Y-MM-DD").toDate(),
    ]);
    setMethodSearch(null);
  };

  const formatDate = (strDate: string = "") => {
    return `${String(strDate).split("T")[0]} ${
      String(String(strDate).split("T")[1]).split(".")[0]
    }`;
  };
  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        Cell: ({ ...props }) => {
          return (
            <div>
              {(query?.page - 1) * query?.limit + props?.row?.index + 1}
            </div>
          );
        },
      },
      {
        Header: t("Name"),
        accessor: "name",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          return (
            <>
              <CopyWrapper
                contentCopy={cell?.value || ""}
                style={{ minWidth: "70px" }}
              >
                {cell?.value || ""}
              </CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("IP"),
        accessor: "req",
        filterable: true,
        sortable: false,
        Cell: (cell: any) => {
          const item: any = cell?.value;
          const ip = item?.headers?.[`x-forwarded-for`];

          return (
            <>{ip ? <CopyWrapper contentCopy={ip}>{ip}</CopyWrapper> : <></>}</>
          );
        },
      },
      {
        Header: t("Method"),
        accessor: "req_method",
        filterable: true,
        sortable: false,
        thClass: "text-center",
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const method = item?.req?.method;

          return (
            <>
              <div className="text-center">
                <span
                  className={`text-center badge bg-${
                    COLORS_METHOD[method] || "secondary"
                  } `}
                >
                  {method}
                </span>
              </div>
            </>
          );
        },
      },
      {
        Header: t("Function"),
        accessor: "function",
        filterable: true,
        sortable: false,
        thWidth: 600,
        Cell: (cell: any) => {
          const url = cell?.value;
          return <>{url}</>;
        },
      },

      {
        Header: t("Url"),
        accessor: "req_url",
        filterable: true,
        sortable: false,
        thWidth: 600,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const url = item?.req?.url;

          return (
            <>
              <CopyWrapper contentCopy={url} style={{ maxWidth: "550px" }}>
                {url}
              </CopyWrapper>
            </>
          );
        },
      },
      {
        Header: t("Body"),
        accessor: "req_body",
        filterable: true,
        sortable: false,
        thWidth: 200,
        Cell: (cell: any) => {
          const item: any = cell?.row?.original;
          const body = item?.req?.body;
          return (
            <>
              {body && Object.keys(body).length > 0 ? (
                <div
                  className="list-inline-item text-primary cursor-pointer"
                  id={`detail-md-${item?._id}`}
                  onClick={() => setBodyData((_prev) => body)}
                >
                  {t("Show Detail JSON Body")}
                </div>
              ) : (
                ""
              )}
            </>
          );
        },
      },
      {
        Header: t("Time"),
        accessor: "time",
        filterable: true,
        sortable: false,
        thWidth: 120,
        thClass: "text-end",
        Cell: (cell: any) => {
          const date = formatDateStringToKorea(cell?.value);
          const arrDate = String(date || "").split(" ");

          return (
            <div className="text-end">
              <span>
                <span>{arrDate?.[0] || ""}</span> <br />{" "}
                <span className="text-secondary">{arrDate?.[1] || ""}</span>{" "}
              </span>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, COLORS_METHOD]
  );

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  };

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (methodSearch) {
      setMethodSearch(
        (_prev: any) =>
          METHOD_OPTIONS_LANG?.filter(
            (e: any) => e.value === methodSearch?.value
          )[0]
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    document.title = `${t("Admin Activity Logs")} - ${t("User")} | NewsHub`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("User Log Management")} pageTitle={t("Home")} />
          <Row>
            {isHavePermissionRole(
              ROLES_FOR_APP.USER_UPDATE,
              userPermissions
            ) && (
              <Col lg={12}>
                <Card id="leadsList">
                  <CardHeader className="border-0">
                    <Row className="g-4 align-items-center">
                      <div className="col-sm">
                        <div>
                          <h5 className="card-title mb-0">
                            <span className="me-2">{t("Total")}:</span>
                            <CountUp
                              start={0}
                              end={userActivityLogs?.total || 0}
                              duration={1}
                              className="text-primary"
                            />
                          </h5>
                        </div>
                      </div>
                    </Row>
                  </CardHeader>
                  <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                    <CollapseFilter>
                      <Row className="g-4 align-items-center mt-0 mt-md-2">
                        <Col sm={12} lg={3} className="mt-2">
                          <LabelWrapper label={t("IP")} isShow={!!ipSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("IP")}...`}
                              value={ipSearch}
                              onChange={(e) => setIpSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={12} lg={3} className="mt-3 mt-md-2">
                          <LabelWrapper label={t("Url")} isShow={!!urlSearch}>
                            <Input
                              type="text"
                              className="form-control search"
                              placeholder={`${t("Url")}...`}
                              value={urlSearch}
                              onChange={(e) => setUrlSearch(e.target.value)}
                              onKeyDown={(e) => {
                                if (e.key === "Enter") {
                                  searchData();
                                }
                              }}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col sm={12} lg={3} className="mt-3 mt-md-2">
                          <LabelWrapper label={t("Method")} isShow={true}>
                            <DropdownMethod
                              name="method"
                              dataList={METHOD_OPTIONS_LANG || []}
                              placeholder={`${t("Method")}...`}
                              className="search-filter-category-type"
                              classNamePrefix="name-prefix"
                              initialValue={methodSearch || null}
                              onChangeSelect={(e: any) => setMethodSearch(e)}
                              isHasOptionAll={true}
                              optionAll={{ label: t("All Filter"), value: "" }}
                              colors={COLORS_METHOD}
                            />
                          </LabelWrapper>
                        </Col>

                        <Col
                          sm={12}
                          lg={3}
                          className="mt-3 mt-md-2 date-picker-wrapper-custom"
                        >
                          <LabelWrapper
                            label={t("Date")}
                            isShow={!!startDate || !!endDate}
                          >
                            <DatePickerCustom
                              startDate={startDate || null}
                              endDate={endDate || null}
                              onChangePicker={handleChangePicker}
                            />
                          </LabelWrapper>
                        </Col>
                        
                        <Col
                          sm={12}
                          lg={12}
                          className="hstack gap-1 justify-content-center justify-content-md-end mt-4 mt-md-3"
                        >
                          <div>
                            <button
                              type="button"
                              className="btn btn-primary me-1"
                              onClick={searchData}
                              disabled={isUserActivityLogLoading}
                            >
                              <i className="ri-search-line align-bottom me-1"></i>{" "}
                              {t("Button Search")}
                            </button>
                            <button
                              type="button"
                              className="btn btn-secondary fs-14"
                              onClick={resetData}
                            >
                              <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                              {t("Button Reset")}
                            </button>
                          </div>
                        </Col>
                      </Row>
                    </CollapseFilter>
                  </div>

                  <CardBody className="pt-0 px-0">
                    <div>
                      <InfiniteScroll
                        dataLength={userActivityLogs?.list?.length || 0}
                        next={handleLoadMore}
                        scrollableTarget="scrollableDiv"
                        hasMore={
                          userActivityLogs &&
                          userActivityLogs?.list?.length <
                            userActivityLogs?.total
                            ? true
                            : false
                        }
                        loader={""} // loader={<LoadingListNotify />}
                        scrollThreshold={"50%"}
                      >
                        <div className="mx-3 my-4">
                          <TableContainer
                            className="custom-header-css"
                            divClass="table-card"
                            tableClass="align-middle table-bordered-dashed"
                            theadClass="table-light text-muted"
                            columns={columns}
                            data={
                              userActivityLogs?.list?.length
                                ? userActivityLogs?.list
                                : []
                            }
                            customPageSize={query.limit}
                            customPageIndex={query.page - 1}
                            totalRecords={userActivityLogs?.total}
                            customPageCount={1}
                            handleChangePage={handleChangePage}
                            manualSorting={true}
                            sorting={{
                              sort_by: query.sort_by,
                              order_by: query.order_by,
                            }}
                            handleChangeSorting={handleChangeSorting}
                            isLoading={isUserActivityLogLoading}
                            isShowPagination={false}
                            isShowLoadingBottom={query.page > 1}
                            isScrollToTop={false}
                          />
                        </div>
                      </InfiniteScroll>
                    </div>
                    <ToastContainer closeButton={false} limit={1} />
                  </CardBody>
                </Card>
              </Col>
            )}
          </Row>
        </Container>
        <Modal isOpen={!!bodyData} centered={true} size="xl" scrollable={false}>
          <ModalHeader toggle={() => setBodyData((_prev) => "")}>
            {t("Show Detail JSON Body")}
          </ModalHeader>
          <ModalBody>
            <div style={{ minHeight: "calc(100dvh - 200px)" }}>
              <code>
                <pre>{JSON.stringify(bodyData, null, 1)}</pre>
              </code>
            </div>
          </ModalBody>
        </Modal>
      </div>
    </React.Fragment>
  );
};

export default UserLog;
