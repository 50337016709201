import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import { formatQueryParams } from "helpers/format";
import PushNotificationApi from "api/pushNotificationApi";

export const getPushLogs = createAsyncThunk("PushLogs", async (params: any = {}) => {
  try {
    const response = await PushNotificationApi.getPushLogs(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});

export const getmanualPush = createAsyncThunk("ManualPush", async (params: any = {}) => {
  try {
    const response = await PushNotificationApi.getManualPush(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
});
