import { createSlice } from "@reduxjs/toolkit";
import { getWallpapersDownload } from "./thunk";

export interface IState {
  wallpaperDownloads: any;
  isWallpaperDownloadLoading: boolean;
  isWallpaperDownloadSuccess: boolean;

  error: any;
}

export const initialState: IState = {
  wallpaperDownloads: null,
  isWallpaperDownloadLoading: false,
  isWallpaperDownloadSuccess: false,

  error: {},
};

const WallpaperDownloadSlice = createSlice({
  name: "WallpaperDownload",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get wallpaperDownloads
    builder.addCase(getWallpapersDownload.pending, (state: IState, action: any) => {
      state.isWallpaperDownloadLoading = true;
    });
    builder.addCase(getWallpapersDownload.fulfilled, (state: IState, action: any) => {
      state.wallpaperDownloads = action.payload.data;
      state.isWallpaperDownloadSuccess = true;
      state.isWallpaperDownloadLoading = false;
    });
    builder.addCase(getWallpapersDownload.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isWallpaperDownloadSuccess = false;
      state.isWallpaperDownloadLoading = false;
    });
  },
});

export const WallpaperDownloadReducer = WallpaperDownloadSlice.reducer;