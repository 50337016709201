import classnames from "classnames";
import BreadCrumb from "components/Common/BreadCrumb";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import WallpaperDownloadList from "./Download";
import WallpaperList from "./Wallpaper";

export interface Tag {
  id: string;
  text: string;
}

registerLocale("en", en);
registerLocale("ko", ko);

const MediaList = () => {
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = `${t("Wallpaper")} - ${t("Wallpaper")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  const [activeWallpaperTab, setActiveWallpaperTab] = useState("wallpaper");
  const [searchParams, setSearchParams] = useSearchParams();

  const tabChange = (tab: any) => {
    setSearchParams({});

    if (activeWallpaperTab !== tab) setActiveWallpaperTab(tab);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <BreadCrumb title={t("Wallpaper Management")} pageTitle={t("Home")} />
          <Row>
            <Col lg={12}>
              <Card
                id="wallpaper"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <div
                  style={{
                    background: "#f3f6f9",
                    borderTopLeftRadius: "0.6rem",
                    borderTopRightRadius: "0.6rem",
                  }}
                >
                  <div
                    className="d-block"
                    style={{
                      background: "#f3f6f9",
                      borderRadius: "0.6rem",
                      width: "fit-content",
                    }}
                  >
                    <Nav tabs className="nav nav-tabs nav-justified">
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                            background: `${
                              activeWallpaperTab === "wallpaper"
                                ? "#009ef7"
                                : "unset"
                            }`,
                            color: `${
                              activeWallpaperTab === "wallpaper"
                                ? "white"
                                : "unset"
                            }`,
                          }}
                          className={classnames({
                            active_wallpaper:
                              activeWallpaperTab === "wallpaper",
                          })}
                          onClick={() => {
                            tabChange("wallpaper");
                          }}
                        >
                          {t("Wallpaper")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: "pointer",
                            background: `${
                              activeWallpaperTab === "download"
                                ? "#009ef7"
                                : "unset"
                            }`,
                            color: `${
                              activeWallpaperTab === "download"
                                ? "white"
                                : "unset"
                            }`,
                          }}
                          className={classnames({
                            active_wallpaper: activeWallpaperTab === "download",
                          })}
                          onClick={() => {
                            tabChange("download");
                          }}
                        >
                          {t("Download")}
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </div>
                </div>

                <TabContent activeTab={activeWallpaperTab}>
                  <TabPane tabId="wallpaper" className="position-relative">
                    {activeWallpaperTab == "wallpaper" && (
                      <WallpaperList activeWallpaperTab={activeWallpaperTab} />
                    )}
                  </TabPane>

                  <TabPane
                    tabId="download"
                    className="relative position-relative"
                  >
                    {activeWallpaperTab == "download" && (
                      <WallpaperDownloadList
                        activeWallpaperTab={activeWallpaperTab}
                      />
                    )}
                  </TabPane>
                </TabContent>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default MediaList;
