import { createSlice } from "@reduxjs/toolkit";
import { IUserActivityLog } from "api/types/_logging";
import { PaginationResponse, ResponseData } from "api/types/_public";
import { getUserLogs } from "./thunk";


export interface IState {
  userLogs: ResponseData<IUserActivityLog[]> & PaginationResponse | null,
  isUserLogLoading: boolean,
  isUserLogSuccess: boolean,

  error: any,
};

export const initialState: IState = {
  userLogs: null,
  isUserLogLoading: false,
  isUserLogSuccess: false,

  error: {},
};

const LogSlice = createSlice({
  name: "Log",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    //get User Activity Logs
    builder.addCase(getUserLogs.pending, (state: IState, action: any) => {
      state.isUserLogLoading = true
    });
    builder.addCase(getUserLogs.fulfilled, (state: IState, action: any) => {
      state.userLogs = action.payload.data;
      state.isUserLogSuccess = true;
      state.isUserLogLoading = false;
    });
    builder.addCase(getUserLogs.rejected, (state: IState, action: any) => {
      state.error = action.payload.error || null;
      state.isUserLogSuccess = false;
      state.isUserLogLoading = false;
    });

  },
});


export default LogSlice.reducer;