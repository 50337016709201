import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import AdvertisementApi from "api/advertisementApi";
import { formatQueryParams } from "helpers/format";

export const getAdvertisements = createAsyncThunk(
  "Advertisements",
  async (params: any = {}) => {
    try {
      const response = await AdvertisementApi.advertisements(
        formatQueryParams(params)
      );
      return response;
    } catch (error) {
      return error;
    }
  }
);
