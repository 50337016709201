import { createAsyncThunk } from "@reduxjs/toolkit";
import "react-toastify/dist/ReactToastify.css";

//Include Both Helper File with needed methods
import { formatQueryParams } from "helpers/format";
import EmotionApi from "api/emotionApi";


export const getWallpaperDownloadApi = async (params: any = {}) => {
  try {
    const response = await EmotionApi.getWallpaperDownload(formatQueryParams(params));
    return response;
  } catch (error) {
    return error;
  }
}

export const getWallpapersDownload = createAsyncThunk("WallpaperDownload", getWallpaperDownloadApi)