import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import "react-quill/dist/quill.snow.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
  Card,
  CardBody,
  Modal,
  ModalHeader,
  ModalBody,
} from "reactstrap";
import * as Yup from "yup";
//import images
import { useRole } from "components/Hooks/UserHooks";
import { ROLES_FOR_APP, isHavePermissionRole } from "helpers/role";
import { LIST_OPTION_OS, MANUAL_PUSH_REDIRECT_SCREEN_TARGET } from "helpers/constans";
import BreadCrumb from "components/Common/BreadCrumb";
import DropdownOptionNew from "components/Common/DropdownOptionNew";
import SessionApi from "api/sessionApi";
import PushNotificationApi from "api/pushNotificationApi";
import { components } from "react-select";
import DropdownAsyncUsers from "components/Common/DropdownAsyncUsers";
import Keywords from "components/Common/Keywords";
import TagDeviceToken from "components/Common/TagDeviceToken";
import ModalConfirm from "components/Common/ModalConfirm";

registerLocale("en", en);
registerLocale("ko", ko);
const DATA_REQUIRED_TARGETID = ['hot_feed', 'wallpaper', 'snap', 'photo_news'];

const ManualPush = () => {
  const { t, i18n } = useTranslation();
  const { userPermissions } = useRole();
  const [listToken, setListToken] = useState<any>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isAllCheckedUsers, setIsAllCheckedUsers] = useState(false)

  const MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG = MANUAL_PUSH_REDIRECT_SCREEN_TARGET?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  }));

  const LIST_OPTION_OS_LANG =
    LIST_OPTION_OS?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [listCheckedUsers, setListCheckedUsers] = useState<any>(null);
  const [isCheckboxDeviceToken, setIsCheckboxDeviceToken] = useState<boolean>(false);
  const [OSSelected, setOSSelected] = useState(null)
  const [deviceToken, setDeviceToken] = useState<any>(null)
  const [redirectScreenTarget, setRedirectScreenTarget] = useState(MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG?.[0]?.value)
  const [isRequiredTargetId, setIsRequiredTargetId] = useState<boolean>(false)
  const [oldUserAll, setOldUserAll] = useState('')
  const [isTargetDeepLink, setIsTargetDeepLink] = useState<boolean>(false)
  const [listDeviceTokenInput, setListDeviceTokenInput] = useState<any>([]);
  const [isResetDeviceTokenInput, setIsResetDeviceTokenInput] = useState<boolean>(false);
  const [isOpenSendNotify, setIsOpenSendNotify] = useState<boolean>(false)
  
  const getAllToken = async (listUsers?: any, OS?: any) => {
    let params : any = {
      limit: 1000,
    }
    let osFilter = OS ? OS : '';

    if(osFilter ) {
      params = {...params,
        os: osFilter,
      }
    }

    if(listUsers?.length > 0) {
      const userIds = listUsers
        .filter((item: any) => (item?.value != 'all'))
        .map((item: any) => item.value);
        
      params = {
        ...params,
        "userRefIds": userIds
      }
    }

    const response: any = await SessionApi.sessions(params, true);
    if (response?.data?.items) {
      const dataToken =  response?.data?.items?.map((a: any) => ({
        value: String(a?.fcmToken),
        label: (
          <div className="d-flex align-items-center gap-2">
            {`${a?.user?.nickname ? a?.user?.nickname : ''} - ${a?.tokenId}`}
          </div>
        ),
        data: a,
      }))
      setListToken(dataToken)
    }
  }

  const checkHasAllUser = (lstUsers: any) => {
    let allUser = null;
    if (lstUsers instanceof Array) {
      allUser = lstUsers?.find((e: any) => e?.value == "all")
    }

    return allUser;
  }

  const handleChangeSelectUsers = (event: any) => {
    const allUser = checkHasAllUser(event);
    setListDeviceTokenInput([])

    if(allUser) {
      setListCheckedUsers([{ label: t("All Filter"), value: "all" }])
      if(!oldUserAll) {
        getAllToken()
      }
      setIsCheckboxDeviceToken(true)
      setOldUserAll('all')
    } else if(event?.length == 0) {
      setIsCheckboxDeviceToken(false)
      setOldUserAll('')
      setDeviceToken(null)
      formik.setFieldValue("deviceToken", '')
    } else {
      getAllToken(event, OSSelected)
      setIsCheckboxDeviceToken(true)
      setOldUserAll('')
    }
  }

  const handleChangeSelectOS = (e: any) => {
    getAllToken(listCheckedUsers, e?.value)
    setOSSelected(e?.value)
    formik.setFieldValue("OS", e?.value)
  }

  const handleChangeScreenTarget = (e: any) => {
    setIsRequiredTargetId(false)
    setIsTargetDeepLink(false)
    setRedirectScreenTarget(e?.value)
    formik.setFieldValue("redirectScreenTarget", e?.value)

    if(DATA_REQUIRED_TARGETID.includes(e?.value)) {
      setIsRequiredTargetId(true)
    }else {
      formik.setFieldValue("targetId", '')
    }

    if(e.value === "open_link") {
      setIsTargetDeepLink(true)
    }
  }

  const handleSelectedDeviceToken = (e: any) => {
    setListDeviceTokenInput([])
    const allToken = checkHasAllUser(e);
    if(allToken) {
      formik.setFieldValue("deviceToken", '')
      setDeviceToken("all")
    }else {
      setDeviceToken(e)
      formik.setFieldValue("deviceToken", e)
    }
  }

  const handleSelectAsyncUsers = (event: any) => {
    setListCheckedUsers(event);
    handleChangeSelectUsers(event);
  }

  const handleChangeDeviceToken = (items: any) => {
    setListDeviceTokenInput(items)
  }

  const [isConfirmSubmit, setIsConfirmSubmit] = useState<boolean>(false)

  const handleSubmit = async (values: any) => {
    try {
      let deviceToken = values?.deviceToken;
      if(Array.isArray(deviceToken) && deviceToken?.length > 0) {
        deviceToken = deviceToken?.map((item: any) => {
          return item?.value;
        })
      }else {
        if(listDeviceTokenInput?.length > 0) {
          deviceToken = listDeviceTokenInput;
        }
      }

      if(deviceToken?.length === 0 && isConfirmSubmit === false)  {
        setIsOpenSendNotify(true)
        return;
      }

      setIsLoading((_prev) => true);
      let target = '';
      if(values?.redirectScreenTarget === "hot_feed" || values?.redirectScreenTarget === "wallpaper") {
        target = "Article";
      } else if (values?.redirectScreenTarget === "snap" || values?.redirectScreenTarget === "photo_news") {
        target = "Snippet"
      }

      let data:any = {
        title: values?.title,
        description: values?.content,
        type: values?.redirectScreenTarget,
      };

      if(values?.targetId) {
        data = {
          ...data,
          targetId: values?.targetId,
        }
      }

      if(target) {
        data = {
          ...data,
          target: target ?? target
        }
      }

      if(values?.targetDeepLink) {
        data = {
          ...data,
          targetDeepLink: values?.targetDeepLink
        }
      }

      const allUser = checkHasAllUser(listCheckedUsers);
      if(deviceToken?.length > 0) {
        data = {
          ...data,
          tokens: deviceToken,
        }
      }else if(!allUser && listCheckedUsers?.length > 0){
        data = {
          ...data,
          userIds: listCheckedUsers.map((item: any) => item?.value)
        }
      }

      const response: any = await PushNotificationApi.createNotifications(data);

       if (response?.data) {
        setIsLoading((_prev) => false);
        resetFormSubmit();
        setDeviceToken(null);
        setIsConfirmSubmit(false);
        setIsOpenSendNotify(false);
        
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading((_prev) => false);
        if(response.success == false) {
          toast(`${response?.message}`, CONFIG_OPTION_TOAST_ERROR);
        }
      }
    } catch (error: any) {
      setIsLoading((_prev) => false);
      toast(`${error}`, CONFIG_OPTION_TOAST_ERROR);
      return error;
    }
  };

  const resetFormSubmit = () => {
    setListToken(null)
    setDeviceToken(null)
    setIsCheckboxDeviceToken(false)
    formik.setFieldValue("deviceToken", '')
    setListCheckedUsers(null)
    formik.resetForm()
    setRedirectScreenTarget(MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG?.[0]?.value)
    setIsRequiredTargetId(false)
    setIsResetDeviceTokenInput(true)
    setListDeviceTokenInput([])
  }

  const validationSchema = Yup.object({
    title: Yup.object().shape({
      en: Yup.string().required(`${t("This field is required")}`),
      ko: Yup.string().required(`${t("This field is required")}`),
    }),
    content: Yup.object().shape({
      en: Yup.string().required(`${t("This field is required")}`),
      ko: Yup.string().required(`${t("This field is required")}`),
    }),
    redirectScreenTarget: Yup.string().required(`${t("This field is required")}`),
    // targetId: Yup.string().when('redirectScreenTarget', {
    //   is: (value: any) => DATA_REQUIRED_TARGETID.includes(value),
    //   then: (rule) => {
    //     return rule.test('redirectScreenTarget', `${t("This field is required")}`, (target) => !!(target?.trim()))
    //   },
    // }),
    targetDeepLink: Yup.string().when('redirectScreenTarget', {
      is: (value: any) => value === "open_link",
      then: (rule) => {
        return rule.test('redirectScreenTarget', `${t("This field is required")}`, (target) => !!(target?.trim()))
      },
    }),
  });

  const formik = useFormik({
    initialValues: {
      title: {
        ko: "",
        en: "",
      },
      content: {
        ko: "",
        en: "",
      },
      OS: LIST_OPTION_OS_LANG[0],
      target: '',
      deviceToken: [],
      redirectScreenTarget: MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG?.[0]?.value,
      userIds: [],
      targetId: '',
      targetDeepLink: ''
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  function MenuList(props: any) {
    let childComponent = props?.children;
    if(props?.children?.length > 0) {
      const allOption = props?.children?.filter((item: any) => item?.props?.value == 'all');
      let fixedIndex = -1;
      if(allOption) {
        fixedIndex = props?.children.indexOf(allOption?.[0]);
      }
      const fixedElement = props?.children?.[fixedIndex];

      const childComponentFormat = (props?.children.slice(1) || [])?.sort((a: any, b: any) => {
        let pointA = a.props.isSelected === true ? 1 : 0;
        let pointB = b.props.isSelected === true ? 1 : 0;
        return pointB - pointA;
      })

      if(fixedElement) {
        childComponent = [fixedElement, ...childComponentFormat]
      }else {
        childComponent = childComponentFormat;
      }
    }
    

    return (
      <components.MenuList {...props}>{childComponent}</components.MenuList>
    );
  }

  const handleCloseConfirmSendNotify = () => {
    setIsOpenSendNotify(false)
  }

  useEffect(() => {
    document.title = `${t("Manual Push")} - ${t("Push Notification")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  useEffect(() => {
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid className="px-0">
          <BreadCrumb
            title={t("Manual Push")}
            pageTitle={t("Home")}
          />

          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardBody className="pt-3">
                  <form onSubmit={formik.handleSubmit}>
                    <Row>
                      <Col lg={12}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("User")}
                          </Label>

                          <DropdownAsyncUsers
                            name="manual-push-users"
                            onChangeSelect={handleSelectAsyncUsers}
                            isMulti={true}
                            placeholder={`${t("Select User")}...`}
                            isShowIconCheck={true}
                            listCheckedUsers={listCheckedUsers}
                            hideSelectedOptions={false}
                          />
                        </div>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("OS")}
                          </Label>

                          <DropdownOptionNew
                            name="os"
                            dataList={LIST_OPTION_OS_LANG || []}
                            placeholder={`${t("OS")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={OSSelected || ''}
                            onChangeSelect={(e: any) => handleChangeSelectOS(e)}
                            hideSelectedOptions={false}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            isClearable={true}
                          />

                          {formik?.touched?.OS && formik?.errors?.OS ? (
                            <div className="text-danger mt-2">
                              {/* {formik?.errors?.OS} */}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Device Token")}
                          </Label>
                          {
                            isCheckboxDeviceToken ?
                              <DropdownOptionNew
                                name="deviceToken"
                                dataList={listToken || []}
                                placeholder={`${t("Device Token")}...`}
                                className="dropdown-status-rounded"
                                classNamePrefix="name-prefix"
                                initialValue={deviceToken || null}
                                onChangeSelect={(e: any) => handleSelectedDeviceToken(e)}
                                isHasOptionAll={true}
                                optionAll={{ label: t("All Filter"), value: "all" }}
                                isClearable={true}
                                isMulti={true}
                                hideSelectedOptions={false}
                                components={{
                                  Option: (props: any) => {
                                    if(props?.isSelected && props?.value == "all") {
                                      setIsAllCheckedUsers(true)
                                    } else if(!props?.isSelected && props?.value == "all") {
                                      setIsAllCheckedUsers(false)
                                    }

                                    return (
                                      <components.Option {...props}>
                                        <div className="d-flex align-items-center gap-2">
                                         {(props?.isSelected || isAllCheckedUsers) ? <i className="ri-check-line align-middle fs-14" />: ''}{props.data?.label}
                                        </div>
                                      </components.Option>
                                    );
                                  },
                                  MenuList: MenuList,
                                }}
                                closeMenuOnSelect={false}
                                styles={{
                                  option: (styles: any) => {
                                    return {
                                      ...styles,
                                      backgroundColor: 'white',
                                      color: 'black',
                                    };
                                  }
                                }}
                              /> :
                                <TagDeviceToken
                                  isResetDeviceTokenInput={isResetDeviceTokenInput}
                                  handleChangeDeviceToken={handleChangeDeviceToken}
                                />
                          }

                          {formik?.touched?.deviceToken && formik?.errors?.deviceToken ? (
                            <div className="text-danger mt-2">
                              {/* {formik?.errors?.deviceToken} */}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={6}>
                      </Col>

                      <Col lg={6}>
                        <div className="mb-3">
                          <Label className="form-label">
                            {t("Redirect Screen Target")} <span className="text-danger"> *</span>
                          </Label>

                          <DropdownOptionNew
                            name="redirectScreenTarget"
                            dataList={MANUAL_PUSH_REDIRECT_SCREEN_TARGET_LANG || []}
                            placeholder={`${t("Redirect Screen Target")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={redirectScreenTarget || null}
                            onChangeSelect={(e: any) => handleChangeScreenTarget(e)}
                            isHasOptionAll={false}
                            hideSelectedOptions={false}
                            optionAll={{ label: t("All Filter"), value: "" }}
                            isClearable={true}
                          />

                          {formik?.touched?.redirectScreenTarget && formik?.errors?.redirectScreenTarget ? (
                            <div className="text-danger mt-2">{formik?.errors?.redirectScreenTarget as any}</div>
                          ) : null}
                        </div>
                      </Col>
                      
                      {
                        isTargetDeepLink ? 
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="titleInput" className="form-label">
                                {t("Target Deep Link")} <span className="text-danger"> *</span>
                              </Label>
                              <Input
                                name="targetDeepLink"
                                type="text"
                                className="form-control"
                                id="targetDeepLink"
                                placeholder={t("Enter your Target Deep Link")}
                                value={formik?.values?.targetDeepLink}
                                onChange={(event: any) => {
                                  formik.setFieldValue("targetDeepLink", event?.target?.value)
                                  formik.setFieldValue("targetId", '')
                                }}
                              />
                              {formik?.touched?.targetDeepLink && formik?.errors?.targetDeepLink ? (
                                <div className="text-danger mt-2">
                                  {formik?.errors?.targetDeepLink}
                                </div>
                              ) : null}
                            </div>
                          </Col>:
                          <Col lg={6}>
                            <div className="mb-3">
                              <Label htmlFor="titleInput" className="form-label">
                                {t("Target ID")}
                              </Label>
                              <Input
                                name="targetId"
                                type="text"
                                className="form-control"
                                id="targetId"
                                placeholder={t("Enter your Target ID")}
                                value={formik?.values?.targetId}
                                onChange={(event: any) => {
                                  formik.setFieldValue("targetId", event?.target?.value)
                                  formik.setFieldValue("targetDeepLink", '')
                                }}
                                disabled={!isRequiredTargetId}
                              />
                              {formik?.touched?.targetId && formik?.errors?.targetId ? (
                                <div className="text-danger mt-2">
                                  {formik?.errors?.targetId}
                                </div>
                              ) : null}
                            </div>
                          </Col>
                      }

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="titleInput" className="form-label">
                            {t("Title")} <span className="text-danger">*</span>
                          </Label>
                          <Input
                            name="title.en"
                            type="text"
                            className="form-control"
                            id="titleInput"
                            placeholder={t("Enter your title")}
                            value={formik?.values?.title?.en}
                            onChange={(event: any) =>
                              formik.setFieldValue("title.en", event?.target?.value)
                            }
                          />
                          {formik?.touched?.title?.en && formik?.errors?.title?.en ? (
                            <div className="text-danger mt-2">
                              {formik?.errors?.title?.en}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="titleKoreaInput" className="form-label">
                            {t("Title (Korean)")} <span className="text-danger"> *</span>
                          </Label>
                          <Input
                            name="title.ko"
                            type="text"
                            className="form-control"
                            id="titleKoreaInput"
                            placeholder={t("Enter your title (korea)")}
                            value={formik?.values?.title?.ko}
                            onChange={(event: any) =>
                              formik.setFieldValue(`title.ko`, event?.target?.value)
                            }
                          />
                          {formik?.touched?.title?.ko && formik?.errors?.title?.ko ? (
                            <div className="text-danger mt-2">
                              {formik?.errors?.title?.ko}
                            </div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="titleKoreaInput" className="form-label">
                            {t("Content")} <span className="text-danger"> *</span>
                          </Label>
                          <textarea
                            className="form-control"
                            id="content"
                            name="content"
                            value={formik?.values?.content?.en}
                            placeholder={`${t("Enter your content")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue("content.en", event?.target?.value || "")
                            }
                            style={{ height: 80 }}
                          />
                          {formik.touched.content?.en && formik.errors.content?.en ? (
                            <div className="text-danger mt-2">{formik.errors.content?.en}</div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="mb-3">
                          <Label htmlFor="titleKoreaInput" className="form-label">
                            {t("Content (Korean)")} <span className="text-danger"> *</span>
                          </Label>
                          <textarea
                            className="form-control"
                            id="content-korea"
                            name="content"
                            value={formik?.values?.content?.ko}
                            placeholder={`${t("Enter your content (Korean)")}...`}
                            onChange={(event: any) =>
                              formik.setFieldValue("content.ko", event?.target?.value || "")
                            }
                            style={{ height: 80 }}
                          />
                          {formik.touched.content?.ko && formik.errors.content?.ko ? (
                            <div className="text-danger mt-2">{formik.errors.content?.ko}</div>
                          ) : null}
                        </div>
                      </Col>

                      <Col lg={12}>
                        <div className="hstack gap-2 justify-content-end mt-4">
                          {isHavePermissionRole(
                            ROLES_FOR_APP.USER_UPDATE,
                            userPermissions
                          ) && (
                              <button
                                className="btn btn-primary fs-14"
                                color="success"
                                type="submit"
                                disabled={isLoading}
                                style={{ width: "140px" }}
                              >
                                {isLoading ? (
                                  <Spinner size="sm" className="me-2"></Spinner>
                                ) : (
                                  <>
                                    {t("Send Push")}
                                  </>
                                )}
                              </button>
                            )}
                        </div>
                      </Col>
                    </Row>
                  </form>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>

        {isOpenSendNotify && (
          <Modal
            isOpen={isOpenSendNotify}
            centered={true}
            size="md"
            toggle={handleCloseConfirmSendNotify}
            keyboard={true}
          >
            <ModalHeader toggle={isLoading ? () => { } : handleCloseConfirmSendNotify}>
              {t("Push Notification")}
            </ModalHeader>
            
            <ModalBody className="">
              <div className="row g-4">
                <Col sm={12}>
                  <div className="row g-3">
                    <div className="mt-4 text-center">
                      <div>
                          {
                            t('Are you sure you want to send the notification to all users?')
                          }
                        </div>
                      <div className="hstack gap-2 justify-content-center mt-4">
                          <button
                            className={`btn btn-primary fs-14`}
                            type="button"
                            disabled={isLoading}
                            onClick={() => {
                              setIsConfirmSubmit(true);
                              formik.submitForm()
                            }}
                          >
                            {isLoading ? (
                              <Spinner size="sm" className="me-2"></Spinner>
                            ) : (
                              <i
                                className={`align-bottom me-1`}
                              ></i>
                            )}
                            {t("Push Notification")}
                          </button>
                        
                          <button
                            className="btn btn-soft-secondary fs-14"
                            type="button"
                            color="light"
                            onClick={handleCloseConfirmSendNotify}
                            disabled={isLoading}
                          >
                            <i className="ri-indeterminate-circle-line align-bottom me-1"></i>
                            {t("Button Close")}
                          </button>
                      </div>
                    </div>
                  </div>
                </Col>
              </div>
            </ModalBody>
          </Modal>
        )}

        <ToastContainer closeButton={false} limit={1} />
      </div>
    </React.Fragment>
  );
};

export default ManualPush;
