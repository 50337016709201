import { useFormik } from "formik";
import { useEffect, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Col,
  Input,
  Modal,
  ModalBody,
  ModalHeader,
  Progress,
  Spinner,
} from "reactstrap";
import * as Yup from "yup";
import PushNotiApi from "api/pushNotiApi";
import {
  CONFIG_OPTION_TOAST_ERROR,
  CONFIG_OPTION_TOAST_NORMAL,
} from "common/toast";
import { NOTI_SETTING_TYPE_OPTION } from "helpers/constans";
import { Radio } from "antd";

export default function CreateEditPushNoti({
  setIsOpen,
  item,
  setItemToEdit,
  loadTableData,
}: any) {
  const { t, i18n } = useTranslation();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  useState<boolean>(false);
  const [normalType, setNormalType] = useState<any>("limited");
  const [vipType, setVipType] = useState<any>("limited");

  const isEdit = item;

  const handleSubmit = async (values: any) => {
    try {
      setIsLoading((_prev) => true);
      const data = {
        type: item?.type,
        description: values?.description,
        title: values?.title,
        content: values?.content,
        numberOfPushForNormalUser:
          normalType == "unlimited"
            ? -1
            : Number(values?.numberOfPushForNormalUser),
        numberOfPushForVIPUser:
          vipType == "unlimited" ? -1 : Number(values?.numberOfPushForVIPUser),
        status: item?.status,
      };

      const response: any = await PushNotiApi?.updatePushNoti(item?._id, data);
      if (response?.data) {
        loadTableData();
        setIsLoading((_prev) => false);
        setIsOpen(false);
        toast(
          `${t("The process has been completed.")}`,
          CONFIG_OPTION_TOAST_NORMAL
        );
      } else {
        setIsLoading(false);
        toast(`${response}`, CONFIG_OPTION_TOAST_ERROR);
      }
    } catch (error: any) {
      setIsLoading(false);
      toast(`Please re-check your data`);
      return error;
    }
  };

  const validationSchema = Yup.object({});

  const formik = useFormik({
    initialValues: {
      description: { en: "", ko: "" },
      title: { en: "", ko: "" },
      content: { en: "", ko: "" },
      numberOfPushForNormalUser: "",
      numberOfPushForVIPUser: "",
    },
    validationSchema,
    onSubmit: handleSubmit,
  });

  useEffect(() => {
    if (item) {
      formik.setFieldValue("description", item?.description);
      formik.setFieldValue("content", item?.content);
      formik.setFieldValue("title", item?.title);
      formik.setFieldValue(
        "numberOfPushForNormalUser",
        item?.numberOfPushForNormalUser
      );
      formik.setFieldValue(
        "numberOfPushForVIPUser",
        item?.numberOfPushForVIPUser
      );
      if (item?.numberOfPushForNormalUser == -1) setNormalType("unlimited");
      if (item?.numberOfPushForVIPUser == -1) setVipType("unlimited");
    }
  }, [item]);

  const onCloseClick = () => {
    setIsOpen(false);
    setItemToEdit(false);
  };

  const TYPE_OPTION_LANG =
    NOTI_SETTING_TYPE_OPTION?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  return (
    <Modal isOpen={true} centered={true} scrollable={false} size="lg">
      <ModalHeader toggle={isLoading ? () => {} : onCloseClick}>
        {/* {isEdit ? t("Update") : t("Create")} */}
        {
          TYPE_OPTION_LANG?.find(
            (i: any) => String(i?.value) === String(item?.type)
          )?.label
        }
      </ModalHeader>
      <ModalBody className="py-3 px-4">
        <div className="mb-4">
          <Progress
            value={100 - (100 / 2) * Number(Object.keys(formik.errors).length)}
            color="primary"
            className="animated-progress progress-sm"
          />
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="row g-3">
            <Col xxl={12}>
              <label className="form-label">
                {t("English Description")}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <textarea
                className="form-control"
                id="description.en"
                name="description.en"
                value={formik?.values?.description?.en}
                placeholder={`${t("English Description")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "description.en",
                    event?.target?.value || ""
                  )
                }
                style={{ height: 55 }}
              />
            </Col>
            <Col xxl={12}>
              <label className="form-label">
                {t("Korean Description")}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <textarea
                className="form-control"
                id="description.ko"
                name="description.ko"
                value={formik?.values?.description?.ko}
                placeholder={`${t("Korean Description")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "description.ko",
                    event?.target?.value || ""
                  )
                }
                style={{ height: 55 }}
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("English Title")}
                {/* <span className="text-danger"> *</span> */}
              </label>

              <Input
                type="text"
                id="title.en"
                name="title.en"
                className="form-control"
                placeholder={`${t("English Title")}...`}
                value={formik?.values?.title?.en}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "title.en",
                    event?.target?.value || ""
                  )
                }
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Korean Title")}
                {/* <span className="text-danger"> *</span> */}
              </label>

              <Input
                type="text"
                id="title.ko"
                name="title.ko"
                className="form-control"
                placeholder={`${t("Korean Title")}...`}
                value={formik?.values?.title?.ko}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "title.ko",
                    event?.target?.value || ""
                  )
                }
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("English Content")}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <textarea
                className="form-control"
                id="content.en"
                name="content.en"
                value={formik?.values?.content?.en}
                placeholder={`${t("English Content")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "content.en",
                    event?.target?.value || ""
                  )
                }
                style={{ height: 55 }}
              />
            </Col>

            <Col xxl={12}>
              <label className="form-label">
                {t("Korean Content")}
                {/* <span className="text-danger"> *</span> */}
              </label>
              <textarea
                className="form-control"
                id="content.ko"
                name="content.ko"
                value={formik?.values?.content?.ko}
                placeholder={`${t("Korean Content")}...`}
                onChange={(event: any) =>
                  formik.setFieldValue(
                    "content.ko",
                    event?.target?.value || ""
                  )
                }
                style={{ height: 55 }}
              />
            </Col>
            
            <Col lg={6}>
              <label htmlFor="subheadInputKorea" className="form-label">
                {t("Standard User Push Count")}
              </label>

              <div className="d-block mb-2">
                <Radio.Group
                  onChange={(e) => {
                    setNormalType(e?.target?.value);
                    formik.setFieldValue("numberOfPushForNormalUser", 0);
                  }}
                  value={normalType}
                >
                  <Radio value={"unlimited"}>{t("Unlimited")}</Radio>
                  <Radio value={"limited"}>{t("Limited")}</Radio>
                </Radio.Group>
              </div>
              {normalType == "limited" && (
                <Input
                  name="numberOfPushForNormalUser"
                  type="text"
                  className="form-control"
                  id="numberOfPushForNormalUser"
                  placeholder={t("Standard User Push Count")}
                  value={formik?.values?.numberOfPushForNormalUser}
                  onChange={(event: any) =>
                    formik.setFieldValue(
                      "numberOfPushForNormalUser",
                      event?.target?.value
                    )
                  }
                />
              )}
            </Col>{" "}
            <Col lg={6}>
              <label htmlFor="numberOfPushForVIPUser" className="form-label">
                {t("VIP User Push Count")}
              </label>

              <div className="d-block mb-2">
                <Radio.Group
                  onChange={(e) => {
                    setVipType(e?.target?.value);
                    formik.setFieldValue("numberOfPushForVIPUser", 0);
                  }}
                  value={vipType}
                >
                  <Radio value={"unlimited"}>{t("Unlimited")}</Radio>
                  <Radio value={"limited"}>{t("Limited")}</Radio>
                </Radio.Group>
              </div>
              {vipType == "limited" && (
                <Input
                  name="subhead.ko"
                  type="text"
                  className="form-control"
                  id="numberOfPushForVIPUser"
                  placeholder={t("VIP User Push Count")}
                  value={formik?.values?.numberOfPushForVIPUser}
                  onChange={(event: any) =>
                    formik.setFieldValue(
                      "numberOfPushForVIPUser",
                      event?.target?.value
                    )
                  }
                />
              )}
            </Col>
            <div className="col-lg-12 mt-4">
              <div className="hstack gap-2 justify-content-end">
                <button
                  className="btn btn-primary fs-14"
                  color="success"
                  type="submit"
                  disabled={isLoading}
                  style={{ width: "120px" }}
                >
                  {isLoading ? (
                    <Spinner size="sm" className="me-2"></Spinner>
                  ) : (
                    <>{isEdit ? t("Button Update") : t("Button Create")}</>
                  )}
                </button>
                <button
                  className="btn btn-secondary fs-14"
                  color="light"
                  type="button"
                  onClick={onCloseClick}
                  disabled={isLoading}
                  style={{ width: "100px" }}
                >
                  {t("Button Close")}
                </button>
              </div>
            </div>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
}
