import { useFormik } from "formik";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Col, Container, Input, ModalBody, ModalHeader, Row, Spinner } from "reactstrap";
import * as Yup from "yup";

import {
  CONFIG_OPTION_TOAST_ERROR,
} from "common/toast";
import SnippetApi, { getDetailNews, getListNews, uploadFiles } from "api/snippetApi";
import LabelWrapper from "components/Common/LabelWrapper";
import { COLOR_STATUS_USED, STATUS_USED } from "helpers/constans";
import DatePickerCustom from "components/Common/DatePickerCustom";
import moment from "moment";
import { NumberParam, StringParam, useQueryParams, withDefault } from "use-query-params";

export default function ListNewsSection({
  handleCopyDataNew
}: any) {
  const { t, i18n } = useTranslation();

  const STATUS_USED_LANG = STATUS_USED?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const [isListNewsLoading, setIsListNewsLoading] = useState<boolean>(false);
  const [dataNews, setDataNews] = useState<{ list: any[], total: number }>({ list: [], total: 0 });

  const [query, setQuery]: any = useQueryParams({
    time_request: withDefault(NumberParam, 0),
    keyword: withDefault(StringParam, ""),
    startDate: withDefault(StringParam, ""),
    endDate: withDefault(StringParam, ""),
    status: withDefault(StringParam, "unused"),    
  });

  const [statusUsedSearch, setStatusUsedSearch] = useState<any>(query?.status || "unused");
  const [keywordSearch, setKeywordSearch] = useState<string>(query?.keyword || "");
  const [dateSearch, setDateSearch] = useState<any[]>([query?.startDate ? moment(query?.startDate || "", 'Y-MM-DD').toDate() : null, query?.endDate ? moment(query?.endDate || "", 'Y-MM-DD').toDate() : null]);
  const [startDate, endDate] = dateSearch;

  const handleQueryNewsData = async () => {
    setIsListNewsLoading((prev) => true);

    try {
      const res: any = await SnippetApi?.getNews(query);

      if (res?.data) {
        setDataNews({
          list: (res?.data?.items || []),
          total: res?.data?.meta?.total || 0,
        })
      }
      
      setIsListNewsLoading((prev) => false);
    } catch (error: any) {
      setIsListNewsLoading((prev) => false);
      toast(`${error?.message || ""}`, CONFIG_OPTION_TOAST_ERROR);
    }
  };

  const [tempIdDetail, setTempIdDetail] = useState<any>(null);

  const handleDetailData = async (id: any) => {
    if(tempIdDetail == id) return;

    setTempIdDetail(id)
    const res = (await getDetailNews(id)) as any;
    
    if(res?.data) {
      const data = res?.data;
      const des = data?.description.replace(/(<style[\w\W]+style>)/g, "");
      const content = des.replace(/<[^>]*>/g, "").replaceAll("&lt;", "").replaceAll("&gt;", "")

      handleCopyDataNew({
        sourceNewsId: data?.id,
        title: data?.title,
        media_url: data?.image_path,
        thumbnail_url: data?.image_path,
        media_type: "image",
        originalContent: content.trim(),
      })
    }
  }

  useEffect(() => {
    handleQueryNewsData()
  }, [JSON.stringify(query)])

  const handleChangePicker = (values: any[] = []) => {
    setDateSearch((_prev: any) => values);
  }
  
  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch,
      startDate: startDate ? moment(new Date(startDate)).format("Y-MM-DD") : '',
      endDate: endDate ? moment(new Date(endDate)).format("Y-MM-DD") : '',
      status: statusUsedSearch,
      time_request: +new Date(),
    });
  };

  const resetData = () => {
    setQuery(
      {
       ...query,
        startDate: '',
        endDate: '',
        keyword: '',
        status: '',
        time_request: +new Date(),
      },
      "push"
    );
    setKeywordSearch((_prev) => "");
    setDateSearch((_prev) => [null, null]);
    setStatusUsedSearch((_prev: any) => "");
  };

  const getMaxDate = (date: any) => {
    if (!date) {
      return date
    }
    const startTime = new Date(date).getTime()

    return new Date(startTime + 30 * 24 * 60 * 60 * 1000)
  }

  const maxDate = React.useMemo(() => getMaxDate(startDate), [dateSearch])

  return (
    <div className="w-100 h-100" style={{overflow: "hidden"}}>
      <Container fluid className="mb-3 pt-3">
        <Row>
          <Col xs={12} sm={6} md={6} lg={6} xl={6} style={{paddingRight: 0}}>
            <input
              className="form-control mb-3"
              id="title"
              name="title"
              placeholder={`${t("Title")}...`}
              value={keywordSearch}
              onChange={(e) => {
                setKeywordSearch(e?.target?.value)
              }}
            />
          </Col>
          
          <Col xs={12} sm={4} md={4} lg={4} xl={4}>
            <LabelWrapper label={t('Used')} isShow={true}>
              <Select
                  name="status-search"
                  className="mb-0 dropdown-status-rounded"
                  classNamePrefix="name-prefix"
                  placeholder={t("Used")}
                  value={STATUS_USED_LANG?.find((item: any) => item?.value === statusUsedSearch) || STATUS_USED_LANG?.[1]}
                  onChange={(event: any) =>
                    setStatusUsedSearch(event?.value)
                  }
                  options={STATUS_USED_LANG}
                  formatOptionLabel={(option: any) => (
                    <div className="d-flex align-items-center">
                      <span className={`bg-${option?.value !== '' ? COLOR_STATUS_USED?.[option?.value] : 'secondary'}`} style={{ width: "15px", height: '15px', borderRadius: '3px' }}></span>
                      <div className="ms-2">
                        {option?.label}
                      </div>
                    </div>
                  )}
                ></Select>
            </LabelWrapper>
          </Col>

          <Col sm={12} md={6} xl={6} xxl={6} className="date-picker-wrapper-custom mb-3" style={{paddingRight: 0}}>
            <LabelWrapper label={t('Date')} isShow={!!startDate || !!endDate}>
              <DatePickerCustom
                startDate={startDate || null}
                endDate={endDate || null}
                onChangePicker={handleChangePicker}
                maxDate={maxDate}
                showOptions={[
                  "today",
                  "yesterday",
                  "last_3_days",
                  "last_7_days",
                  "last_14_days",
                  "last_30_days"
                ]}
              />
            </LabelWrapper>
          </Col>

          <Col
            sm={6} md={6} xl={6} xxl={6}
            className="hstack gap-1 justify-content-sm-center justify-content-md-start d-block"
          >
            <div>
              <button
                type="button"
                className="btn btn-primary me-1"
                onClick={searchData}
                disabled={isListNewsLoading}
              >
                <i className="ri-search-line align-bottom me-1"></i>{" "}
                {t("Button Search")}
              </button>
              <button
                type="button"
                className="btn btn-secondary fs-14"
                onClick={resetData}
              >
                <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                {t("Button Reset")}
              </button>
            </div>
          </Col >
        </Row>
        
      </Container>
      <div style={{ overflow: "auto", height: "100%"}}>
        <div className="color-scrollbar-os" style={{height: "calc(-146px + 100dvh)", minHeight: "calc(-146px + 100dvh)", backgroundColor: "rgb(243, 246, 249)"}}>
          <ul className="list-group list-group-flush border-dashed px-3">
              {(dataNews.list || []).map((item, index) => (
                  <li className="list-group-item ps-0" 
                    key={item?.id} 
                    onClick={() => handleDetailData(item?.id)}
                    style={{cursor: "pointer"}}
                    >
                      <div className="d-flex align-items-start">
                        <div className="text-center" style={{minWidth: "100px"}}>
                          <img style={{maxWidth: "80px"}} src={item?.image_path} height={80} className="mx-auto" alt={`artist ${index}`} />
                        </div>
                        <div className="flex-grow-1">
                            <label className="form-check-label mb-0 ps-2" htmlFor={item.title}>{item?.title}</label>
                            <p className="text-muted fs-12 mb-0">{item?.published_at}</p>
                            {
                              STATUS_USED_LANG?.find((status: any) => status?.value === item?.status) && (
                                <div className="d-flex align-items-center">
                                  <span className={`bg-${item?.status !== '' ? COLOR_STATUS_USED?.[item?.status] : 'secondary'}`} style={{ width: "15px", height: '15px', borderRadius: '3px' }}></span>
                                  <div className="ms-2">
                                    {STATUS_USED_LANG?.find((status: any) => status?.value === item?.status)?.label}
                                  </div>
                                </div>
                              )
                            }
                        </div>
                      </div>
                  </li>
              ))}
          </ul>
        </div>
      </div>
     
    </div>
  );
}
