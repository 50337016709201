import BreadCrumb from "components/Common/BreadCrumb";
import TableContainer from "components/Common/TableContainer";
import en from "date-fns/locale/en-US";
import ko from "date-fns/locale/ko";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { registerLocale } from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Input,
  Row,
} from "reactstrap";
import { createSelector } from "reselect";
import {
  NumberParam,
  StringParam,
  useQueryParams,
  withDefault,
} from "use-query-params";
import LabelWrapper from "components/Common/LabelWrapper";
import CountUp from "react-countup";
import { getUserNotiPermissions } from "store/userNotiPermission/thunk";
import { LIST_OPTION_OS, LIST_OPTION_PLATFORM } from "helpers/constans";
import avatar1 from "../../../assets/images/users/ic_defaul_avatar2.png";
import { getPlatFormImage } from "helpers/constans";
import { useLocation } from "react-router-dom";
import { capitalizeFirstLetter, truncate } from "utils";
import DropdownPlatform from "components/Common/DropdownPlatform";
import CollapseFilter from "components/Common/CollapseFilter";
import CopyTruncate from "components/Common/CopyTruncate";

interface Option {
  label: string;
  value: string;
}

export interface Tag {
  id: string;
  text: string;
}

const TYPE_SELECT_DEFAULT: string = "createdAt";

registerLocale("en", en);
registerLocale("ko", ko);

const UserNotiPermissionList = ({ state }: any) => {
  const { t, i18n } = useTranslation();
  const dispatch: any = useDispatch();
  // const { state } = useLocation();

  // const [query, setQuery]: any = useQueryParams({
  //   time_request: withDefault(NumberParam, 0),
  //   page: withDefault(NumberParam, 1),
  //   limit: withDefault(NumberParam, 30),
  //   "condition[email]": withDefault(StringParam, state?.email),
  //   keyword: withDefault(StringParam, ""),
  //   "condition[providers][type]": withDefault(StringParam, state?.platform),
  //   startDate: withDefault(StringParam, ""),
  //   endDate: withDefault(StringParam, ""),
  // });

  const [query, setQuery]: any = useState({
    time_request: 0,
    page: 1,
    limit: 30,
    "condition[email]": state?.email,
    keyword: "",
    "condition[providers][type]": state?.platform,
    startDate: "",
    endDate: "",
  });

  const [dateSearch, setDateSearch] = useState<any[]>([
    query?.startDate
      ? moment(query?.startDate || "", "Y-MM-DD").toDate()
      : null,
    query?.endDate ? moment(query?.endDate || "", "Y-MM-DD").toDate() : null,
  ]);

  const LIST_OPTION_PLATFORM_LANG =
    LIST_OPTION_PLATFORM?.map((item: any) => ({
      value: item?.value,
      label: t(item?.label),
    })) || [];

  const [keywordSearch, setKeywordSearch] = useState<string>(
    query?.keyword || ""
  );
  const [emailSearch, setEmailSearch] = useState<string>(state?.email || "");

  const [platFormSearch, setPlatFormSearch] = useState<Option | null>(
    LIST_OPTION_PLATFORM_LANG?.filter(
      (item) => String(item?.value) === String(state?.platform)
    )[0]
  );
  const selectLayoutState = (state: any) => state.UserNotiPermission;

  const UserNotiPermissionProperties = createSelector(
    selectLayoutState,
    (state) => ({
      userNotiPermission: state.userNotiPermissions,
      isUserNotiPermissionLoading: state.isUserNotiPermissionLoading,
      isUserNotiPermissionSuccess: state.isUserNotiPermissionSuccess,

      error: state.error,
    })
  );

  // Inside your component
  const { userNotiPermission, isUserNotiPermissionLoading } = useSelector(
    UserNotiPermissionProperties
  );

  function loadTableData() {
    dispatch(getUserNotiPermissions(query));
  }

  useEffect(() => {
    loadTableData();
  }, [dispatch, JSON.stringify(query)]);

  const searchData = () => {
    setQuery({
      ...query,
      keyword: keywordSearch || "",
      "condition[email]": emailSearch || "",
      // platform
      "condition[providers][type]": platFormSearch?.value || "",
      page: 1,
      time_request: +new Date(),
    });
  };

  useEffect(() => {}, [state]);

  const resetData = () => {
    const queryNew = {
      keyword: "",
      "condition[email]": "",
      // platform
      "condition[providers][type]": "",
      time_request: +new Date(),
      page: 1,
    };
    setQuery(queryNew, "push");
    setKeywordSearch((_prev) => "");
    setEmailSearch((_prev) => "");
    setPlatFormSearch((_prev) => null);
    setDateSearch(["", ""]);
  };

  console.log(emailSearch, platFormSearch);

  // Column
  const columns = useMemo(
    () => [
      {
        Header: t("No."),
        thWidth: 80,
        thClass: "whitespace-nowrap",
        Cell: ({ ...props }) => {
          if (!Number(userNotiPermission?.total)) return <></>;
          return (
            <div>
              {Number(userNotiPermission?.total) -
                ((query?.page - 1) * Number(query?.limit) + props?.row?.index)}
            </div>
          );
        },
      },
      {
        Header: t("User"),
        accessor: "user",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 300,
        Cell: (cell: any) => {
          const user = cell?.row?.original;
          return (
            <>
              <div className="w-100" style={{ minWidth: "60px" }}>
                <div className="d-flex justify-content-start">
                  <div style={{ width: "45px" }} className="cursor-pointer">
                    <img
                      src={user?.avatar ?? avatar1}
                      className="rounded-circle avatar-sm"
                      alt="thumbnail"
                      onError={({ currentTarget }) => {
                        currentTarget.onerror = null; // prevents looping
                        currentTarget.src = avatar1;
                      }}
                    />
                  </div>

                  <div className="d-block text-start ms-3">
                    <strong>{user?.nickname}</strong>
                    <div>{user?.email}</div>
                  </div>
                </div>
              </div>
            </>
          );
        },
      },

      {
        Header: t("Platform"),
        accessor: "providers_platform",
        filterable: true,
        sortable: false,
        thClass: "whitespace-nowrap",
        thWidth: 120,

        Cell: (cell: any) => {
          let items = cell?.row?.original?.providers || [];

          if (items.length > 1) {
            items = items.filter((item: any) => item?.type !== "default");
          }

          return (
            items?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {items?.map((item: any, index: number) => {
                  const type = item?.type;
                  let imgPlatform = getPlatFormImage(type);
                  return (
                    <div
                      key={index}
                      className="cursor-pointer d-flex gap-2 align-items-center whitespace-nowrap"
                    >
                      <div style={{ width: "25px" }}>
                        {imgPlatform && (
                          <img
                            src={getPlatFormImage(type)}
                            width={25}
                            height={25}
                            alt="platform"
                          />
                        )}
                      </div>
                      <div className="cursor-pointer">
                        {t(
                          capitalizeFirstLetter(
                            type == "default" ? "Email" : type
                          )
                        )}
                      </div>
                    </div>
                  );
                })}
              </div>
            )
          );
        },
      },

      {
        Header: t("Push User Level"),
        accessor: "pushUser_level",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 170,
        Cell: (cell: any) => {
          return t("Normal");
        },
      },

      {
        Header: t("FCM Token"),
        accessor: "device_token",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 170,

        Cell: (cell: any) => {
          const allToken = cell?.row?.original?.deviceToken;
          let shortToken = [];

          if (allToken?.length > 2) {
            shortToken = [...allToken].splice(0, 2);
          } else {
            shortToken = allToken;
          }

          const [isShowMore, setIsShowMore] = useState(false);

          const showData = isShowMore ? allToken : shortToken;

          return (
            allToken?.length > 0 && (
              <div className="d-flex flex-column gap-1">
                {showData?.map((item: any, index: number) => {
                  return (
                    <div
                      className="cursor-pointer"
                      style={{ maxWidth: "320px" }}
                    >
                      <strong>{t(item?.deviceOs)}:</strong>
                      <CopyTruncate
                        contentCopy={item?.token}
                        style={{ minWidth: "70px" }}
                      >
                        {truncate(item?.token, 70)}
                      </CopyTruncate>
                    </div>
                  );
                })}

                {allToken?.length > 2 && (
                  <span
                    onClick={(e) => {
                      e.preventDefault();
                      setIsShowMore((prev) => !prev);
                    }}
                    style={{
                      cursor: "pointer",
                      whiteSpace: "nowrap",
                    }}
                    className="link-primary fs-12 d-inline"
                  >
                    <span>{isShowMore ? t("Hide") : t("More")}</span>
                    <i
                      className={`${
                        isShowMore
                          ? "ri-arrow-up-s-line"
                          : "ri-arrow-down-s-line"
                      } fs-16 position-relative`}
                      style={{ top: "4px" }}
                    ></i>
                  </span>
                )}
              </div>
            )
          );
        },
      },

      // {
      //   Header: t("App Open Frequency"),
      //   accessor: "appOpenFrequency",
      //   thClass: "whitespace-nowrap",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 170,

      //   Cell: (cell: any) => {
      //     return `${cell?.value}%`;
      //   },
      // },

      // {
      //   Header: t("Push Notification Open Rate"),
      //   accessor: "pushNotificationOpenRate",
      //   thClass: "whitespace-nowrap",
      //   filterable: true,
      //   sortable: false,
      //   thWidth: 220,

      //   Cell: (cell: any) => {
      //     return `${cell?.value}%`;
      //   },
      // },

      {
        Header: t("Allow Notification"),
        accessor: "allowNotification",
        thClass: "whitespace-nowrap",
        filterable: true,
        sortable: false,
        thWidth: 170,

        Cell: (cell: any) => {
          return cell?.value == 1 ? t("On") : t("Off");
        },
      },

      {
        Header: t("Feed"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 900,
        thComponent: () => (
          <table>
            <thead className="none-sticky th-background-danger">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Feed")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Allow Notification")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Total Push Count")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Total Push Count Today")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Last Push Time (User)")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Last Push Time")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original?.notifications?.[0];

          const dateLocal = item?.localTimeUser;
          const arrDateLocal = String(dateLocal || "").split(" ");

          const dateLatestPush = item?.latestPush;
          const arrDateLatestPush = String(dateLatestPush || "").split(" ");

          return (
            <div className="d-flex text-center td-background-danger">
              <div style={{ width: "200px" }}>
                {item?.allowNotification == 1 ? t("On") : t("Off")}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushCount}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushTodayCount}
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLocal?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLocal?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLatestPush?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLatestPush?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },

      //wallpaper
      {
        Header: t("Wallpaper"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 900,
        thComponent: () => (
          <table>
            <thead className="none-sticky th-background-primary">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Wallpaper")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Allow Notification")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Total Push Count")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Total Push Count Today")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Last Push Time (User)")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Last Push Time")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original?.notifications?.[1];

          const dateLocal = item?.localTimeUser;
          const arrDateLocal = String(dateLocal || "").split(" ");

          const dateLatestPush = item?.latestPush;
          const arrDateLatestPush = String(dateLatestPush || "").split(" ");

          return (
            <div className="d-flex text-center td-background-primary">
              <div style={{ width: "200px" }}>
                {item?.allowNotification == 1 ? t("On") : t("Off")}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushCount}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushTodayCount}
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLocal?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLocal?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLatestPush?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLatestPush?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },

      //snap
      {
        Header: t("Snap"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 900,
        thComponent: () => (
          <table>
            <thead className="none-sticky th-background-purple">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Snap")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Allow Notification")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Total Push Count")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Total Push Count Today")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Last Push Time (User)")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Last Push Time")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original?.notifications?.[2];

          const dateLocal = item?.localTimeUser;
          const arrDateLocal = String(dateLocal || "").split(" ");

          const dateLatestPush = item?.latestPush;
          const arrDateLatestPush = String(dateLatestPush || "").split(" ");

          return (
            <div className="d-flex text-center td-background-purple">
              <div style={{ width: "200px" }}>
                {item?.allowNotification == 1 ? t("On") : t("Off")}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushCount}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushTodayCount}
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLocal?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLocal?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLatestPush?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLatestPush?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },

      //photocard news
      {
        Header: t("Photocard News"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 900,
        thComponent: () => (
          <table>
            <thead className="none-sticky th-background-red">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Photocard News")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Allow Notification")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Total Push Count")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Total Push Count Today")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Last Push Time (User)")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Last Push Time")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original?.notifications?.[3];

          const dateLocal = item?.localTimeUser;
          const arrDateLocal = String(dateLocal || "").split(" ");

          const dateLatestPush = item?.latestPush;
          const arrDateLatestPush = String(dateLatestPush || "").split(" ");

          return (
            <div className="d-flex text-center td-background-red">
              <div style={{ width: "200px" }}>
                {item?.allowNotification == 1 ? t("On") : t("Off")}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushCount}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushTodayCount}
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLocal?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLocal?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLatestPush?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLatestPush?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },

      //Event & Promotion
      {
        Header: t("Event And Promotion"),
        thClass: "text-end align-top",
        filterable: false,
        sortable: false,
        thWidth: 900,
        thComponent: () => (
          <table>
            <thead className="none-sticky th-background-green">
              <tr>
                <th className="text-center pb-1 none-sticky" colSpan={5}>
                  {t("Event And Promotion")}
                </th>
              </tr>
              <tr>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Allow Notification")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Total Push Count")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Total Push Count Today")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "250px" }}
                >
                  {t("Last Push Time (User)")}
                </th>
                <th
                  className="text-center px-0 py-1 cursor-pointer none-sticky"
                  style={{ width: "200px" }}
                >
                  {t("Last Push Time")}
                </th>
              </tr>
            </thead>
          </table>
        ),
        Cell: (cell: any) => {
          const item: any = cell?.row?.original?.notifications?.[4];

          const dateLocal = item?.localTimeUser;
          const arrDateLocal = String(dateLocal || "").split(" ");

          const dateLatestPush = item?.latestPush;
          const arrDateLatestPush = String(dateLatestPush || "").split(" ");

          return (
            <div className="d-flex text-center td-background-green">
              <div style={{ width: "200px" }}>
                {item?.allowNotification == 1 ? t("On") : t("Off")}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushCount}
              </div>
              <div style={{ width: "200px", borderLeft: "1px solid #eaeaea" }}>
                {item?.numberOfPushTodayCount}
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLocal?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLocal?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
              <div
                style={{
                  width: "200px",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <div className="text-end">
                  <span>
                    <span>{arrDateLatestPush?.[0] || ""}</span> <br />{" "}
                    <span className="text-secondary">
                      {arrDateLatestPush?.[1] || ""}
                    </span>{" "}
                  </span>
                </div>
              </div>
            </div>
          );
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [i18n?.language, userNotiPermission?.total]
  );

  const handleChangePage = useCallback((page: any) => {
    setQuery({ page: page + 1 });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeSorting = useCallback((sortBy: any) => {
    setQuery((_prev: any) => {
      return { ..._prev, ...sortBy };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    document.title = `${t("UserNotiPermission")} | Dispatch`;
    document.body.classList.remove("vertical-sidebar-enable");
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18n?.language]);

  return (
    <React.Fragment>
      <div /*  className="page-content" */>
        <Container fluid>
          {/*  <BreadCrumb
            title={t("Notification Permission")}
            pageTitle={t("Home")}
          /> */}
          <Row>
            <Col lg={12}>
              <Card
                id="customerList"
                style={{ boxShadow: "0px 3px 4px 0px rgba(0, 0, 0, 0.03)" }}
              >
                <CardHeader className="border-0">
                  <Row className="g-4 align-items-center">
                    <div className="col-sm">
                      <div>
                        <h5 className="card-title mb-0">
                          <span className="me-2">{t("Total")}:</span>
                          <CountUp
                            start={0}
                            end={userNotiPermission?.total || 0}
                            duration={1}
                            className="text-primary"
                          />
                        </h5>
                      </div>
                    </div>
                  </Row>
                </CardHeader>
                <div className=" border border-dashed border-end-0 border-start-0 card-body mt-0 pb-4">
                  <CollapseFilter>
                    <Row className="g-4 align-items-center mt-0 mt-md-2">
                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper
                          label={t("Nickname")}
                          isShow={!!keywordSearch}
                        >
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Nickname")}...`}
                            value={keywordSearch}
                            onChange={(e) => setKeywordSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Email")} isShow={!!emailSearch}>
                          <Input
                            type="text"
                            className="form-control search"
                            placeholder={`${t("Email")}...`}
                            value={emailSearch}
                            onChange={(e) => setEmailSearch(e.target.value)}
                            onKeyDown={(e) => {
                              if (e.key === "Enter") {
                                searchData();
                              }
                            }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="mt-3 mt-md-2"
                      >
                        <LabelWrapper label={t("Platform")} isShow={true}>
                          <DropdownPlatform
                            name="platform"
                            placeholder={`${t("Platform")}...`}
                            className="dropdown-status-rounded"
                            classNamePrefix="name-prefix"
                            initialValue={platFormSearch || null}
                            onChangeSelect={(e: any) => setPlatFormSearch(e)}
                            isHasOptionAll={true}
                            optionAll={{ label: t("All Filter"), value: "" }}
                          />
                        </LabelWrapper>
                      </Col>

                      <Col
                        sm={12}
                        md={3}
                        xl={3}
                        xxl={3}
                        className="hstack gap-1 justify-content-center justify-content-md-end mt-3 mt-md-2"
                      >
                        <div>
                          <button
                            type="button"
                            className="btn btn-primary me-1"
                            onClick={searchData}
                          >
                            <i className="ri-search-line align-bottom me-1"></i>{" "}
                            {t("Button Search")}
                          </button>
                          <button
                            type="button"
                            className="btn btn-secondary fs-14"
                            onClick={resetData}
                          >
                            <i className="ri-refresh-line align-bottom me-1"></i>{" "}
                            {t("Button Reset")}
                          </button>
                        </div>
                      </Col>
                    </Row>
                  </CollapseFilter>
                </div>
                <CardBody className="pt-3">
                  <div>
                    <TableContainer
                      className="custom-header-css"
                      divClass="table-responsive table-card"
                      tableClass="align-middle table-bordered-dashed table-notification-permission "
                      theadClass="table-light text-muted"
                      columns={columns}
                      data={
                        userNotiPermission?.items?.length
                          ? userNotiPermission?.items
                          : []
                      }
                      customPageSize={query.limit}
                      customPageIndex={query.page - 1}
                      totalRecords={userNotiPermission?.total}
                      customPageCount={Math.ceil(
                        Number(userNotiPermission?.total) / Number(query.limit)
                      )}
                      handleChangePage={handleChangePage}
                      manualSorting={true}
                      sorting={{
                        sort_by: query.sort_by,
                        order_by: query.order_by,
                      }}
                      handleChangeSorting={handleChangeSorting}
                      isLoading={isUserNotiPermissionLoading}
                    />
                  </div>
                  <ToastContainer closeButton={false} limit={1} />
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default UserNotiPermissionList;
