import React from "react";
import { useTranslation } from "react-i18next";
import Select from "react-select";
import { getPlatFormImage, LIST_OPTION_PLATFORM } from "helpers/constans";

interface Option {
  label: string;
  value: string;
}
interface DropdownPlatformProps {
  name?: string,
  initialValue?: Option | undefined | null;
  dataList?: Option[];
  onChangeSelect?: (params: Option) => void;
  placeholder?: string;
  disabled?: boolean,
  className?: string,
  classNamePrefix?: string,
  isHasOptionAll?: boolean,
  optionAll?: undefined | Option,
  colors?: any
}

const DropdownPlatform = ({
  name = '',
  initialValue,
  onChangeSelect,
  placeholder,
  disabled = false,
  className = '',
  classNamePrefix = '',
  isHasOptionAll = false,
  optionAll,
}: DropdownPlatformProps) => {
  const { t } = useTranslation();

  const setOnChangeOption = (item: any) => {
    onChangeSelect && onChangeSelect(item);
  };

  const LIST_OPTION_PLATFORM_LANG = LIST_OPTION_PLATFORM?.map((item: any) => ({
    value: item?.value,
    label: t(item?.label),
  })) || [];

  const resultOption: any = isHasOptionAll ? [optionAll].concat(LIST_OPTION_PLATFORM_LANG) : LIST_OPTION_PLATFORM_LANG;
  
  return (
    <React.Fragment>
      <Select
        options={resultOption}
        value={initialValue ?? resultOption[0]}
        name={name}
        isDisabled={disabled}
        onChange={(e: any) => setOnChangeOption(e)}
        placeholder={<div>{placeholder || ''}</div>}
        loadingMessage={() => (<div>{t('Loading')}...</div>)}
        noOptionsMessage={() => (<div>{t('No Options')}</div>)}
        formatOptionLabel={(option: any) => { 
          let imgPlatform = getPlatFormImage(option?.value);
          
          return (
            <div className="d-flex align-items-center">
              {
                imgPlatform ? (
                  <div style={{ width: "25px" }} className="me-1">
                    {imgPlatform ? <img src={imgPlatform} width={20} height={20} alt="platform" /> : 
                      <span className={`bg-secondary`} style={{ width: "20px", height: '20px', borderRadius: '3px' }}></span>
                    }
                  </div>
                ): ''
              }
              <div>
                {t(option?.label)}
              </div>
            </div>
          )}
        }
        className={className}
        classNamePrefix={classNamePrefix}
      />
    </React.Fragment>
  );
};

export default DropdownPlatform;
